/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AddUserEvent = {
  endAt: Scalars["Date"]["input"];
  event: Scalars["String"]["input"];
  startAt: Scalars["Date"]["input"];
};

export type Address = {
  __typename?: "Address";
  city: Maybe<Scalars["String"]["output"]>;
  country: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  isDefault: Maybe<Scalars["Boolean"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
  street1: Maybe<Scalars["String"]["output"]>;
  street2: Maybe<Scalars["String"]["output"]>;
  zip: Maybe<Scalars["String"]["output"]>;
};

export type AddressInput = {
  city: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  state: Scalars["String"]["input"];
  street1: Scalars["String"]["input"];
  street2?: InputMaybe<Scalars["String"]["input"]>;
  zip: Scalars["String"]["input"];
};

export type AddressInputUpdate = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street1?: InputMaybe<Scalars["String"]["input"]>;
  street2?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type Addresses = {
  __typename?: "Addresses";
  defaultShipping: Maybe<Address>;
  home: Maybe<Address>;
};

export type AddressesInput = {
  defaultShipping?: InputMaybe<AddressInput>;
  home?: InputMaybe<AddressInput>;
};

export type AddressesInputUpdate = {
  defaultShipping?: InputMaybe<AddressInputUpdate>;
  home?: InputMaybe<AddressInputUpdate>;
};

export type AdjudicationRejectCodes = {
  __typename?: "AdjudicationRejectCodes";
  codes: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type AgencyOfServiceType = {
  __typename?: "AgencyOfServiceType";
  address: Maybe<Address>;
  agencyContactName: Maybe<Name>;
  businessName: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
};

export type Allergy = {
  __typename?: "Allergy";
  idField: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  patientSurveyInput: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type AllergyRestricted = {
  __typename?: "AllergyRestricted";
  adverseEvent: Maybe<SnomedAdverseEventCode>;
  drugProductCoded: Maybe<DrugProductCodeWithText>;
  effectiveDate: Maybe<Scalars["Date"]["output"]>;
  expirationDate: Maybe<Scalars["Date"]["output"]>;
  reactionCoded: Maybe<SnomedCode>;
  severityCoded: Maybe<SnomedCode>;
  sourceOfInformation: Maybe<Scalars["String"]["output"]>;
};

export type AllergyRestrictedChoice = {
  __typename?: "AllergyRestrictedChoice";
  allergies: Maybe<AllergyRestricted>;
  noKnownallergies: Maybe<Scalars["Boolean"]["output"]>;
};

export type AllowedSubstitutionsInput = {
  substitutions?: InputMaybe<Array<InputMaybe<SubstitutionInput>>>;
  substitutionsTotal?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AlternateBarCodes = {
  __typename?: "AlternateBarCodes";
  barCodes: Array<Maybe<Scalars["String"]["output"]>>;
  ndc: Scalars["String"]["output"];
};

export type AlternateContact = {
  __typename?: "AlternateContact";
  address: Maybe<Address>;
  alternateContactRelationship: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  formerName: Maybe<Name>;
  name: Maybe<Name>;
};

export type AmountArrayOverrideCob = {
  __typename?: "AmountArrayOverrideCob";
  applyTo: Maybe<Scalars["String"]["output"]>;
  send: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Array<Maybe<CobAmountObject>>>;
};

export type AmountArrayOverrideCobInput = {
  applyTo?: InputMaybe<Scalars["String"]["input"]>;
  send?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Array<InputMaybe<CobAmountObjectInput>>>;
};

export type ApiRequestParams = {
  __typename?: "ApiRequestParams";
  request_params: Maybe<Scalars["String"]["output"]>;
};

export type App = {
  __typename?: "App";
  branch: Maybe<Scalars["String"]["output"]>;
  commit: Maybe<Scalars["String"]["output"]>;
};

export type Autobot = {
  __typename?: "Autobot";
  _id: Scalars["ID"]["output"];
  backPressureLimit: Maybe<Scalars["Int"]["output"]>;
  brand: Scalars["String"]["output"];
  gcpAddress: Scalars["String"]["output"];
  ip: Maybe<Scalars["String"]["output"]>;
  isActive: Scalars["Boolean"]["output"];
  isOnline: Scalars["Boolean"]["output"];
  locationId: Scalars["ID"]["output"];
  logs: Maybe<Array<Maybe<Log>>>;
  modelName: Scalars["String"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  port: Maybe<Scalars["String"]["output"]>;
};

export type AutobotOptions = {
  backPressureLimit: Scalars["Int"]["input"];
  brand?: InputMaybe<Scalars["String"]["input"]>;
  gcpAddress: Scalars["String"]["input"];
  isActive: Scalars["Boolean"]["input"];
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  modelName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type Autofill = {
  __typename?: "Autofill";
  id: Maybe<Scalars["ID"]["output"]>;
  prescription_token: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type AutomationResponse = {
  __typename?: "AutomationResponse";
  res: Maybe<Scalars["String"]["output"]>;
};

export type BenefitsCoordinationRestricted = {
  __typename?: "BenefitsCoordinationRestricted";
  address: Maybe<Address>;
  cardHolderName: Maybe<Name>;
  cardholderID: Maybe<Scalars["String"]["output"]>;
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  consent: Maybe<Scalars["String"]["output"]>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  groupID: Maybe<Scalars["String"]["output"]>;
  groupName: Maybe<Scalars["String"]["output"]>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  patientRelationship: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  payerName: Maybe<Scalars["String"]["output"]>;
  payerResponsibilityCode: Maybe<Scalars["String"]["output"]>;
  payerType: Maybe<Scalars["String"]["output"]>;
  pbmMemberID: Maybe<Scalars["String"]["output"]>;
  personCode: Maybe<Scalars["String"]["output"]>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  responsibleParty: Maybe<Name>;
  serviceTypeCoded: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  standardUniqueHealthPlanIdentifier: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type BulkSyncAutomationResponse = {
  __typename?: "BulkSyncAutomationResponse";
  error: Maybe<Scalars["String"]["output"]>;
  result: Maybe<AutomationResponse>;
};

export type CobAmountObject = {
  __typename?: "COBAmountObject";
  amount: Maybe<Scalars["Float"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
};

export type CobAmountObjectInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type CalibratePrinterInput = {
  label?: InputMaybe<LabelSizeInput>;
  padding?: InputMaybe<PaddingInput>;
  zoom?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Calibration = {
  __typename?: "Calibration";
  label: Maybe<LabelSize>;
  padding: Maybe<Padding>;
  zoom: Maybe<Scalars["Float"]["output"]>;
};

export type CanConfirmPv2Response = {
  __typename?: "CanConfirmPV2Response";
  canConfirm: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancelAutofillResponse = {
  __typename?: "CancelAutofillResponse";
  status: Maybe<Scalars["String"]["output"]>;
};

export type CancelOrderResponse = {
  __typename?: "CancelOrderResponse";
  order: Maybe<Order>;
  reversalsFailed: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancellationDetails = {
  __typename?: "CancellationDetails";
  canceledAt: Scalars["Date"]["output"];
  code: Maybe<Scalars["String"]["output"]>;
  note: Maybe<Scalars["String"]["output"]>;
  reason: Scalars["String"]["output"];
};

export type ClaimRevarsal = {
  __typename?: "ClaimRevarsal";
  received: Maybe<ClaimsReceived>;
  reversedAt: Maybe<Scalars["Date"]["output"]>;
};

export type ClaimSummary = {
  __typename?: "ClaimSummary";
  awpPrice: Maybe<Scalars["Float"]["output"]>;
  copay: Maybe<Scalars["Float"]["output"]>;
  dateOfService: Maybe<Scalars["String"]["output"]>;
  dispensingFeePaid: Maybe<Scalars["Float"]["output"]>;
  flatSalesTaxAmountPaid: Maybe<Scalars["Float"]["output"]>;
  incentiveAmountPaid: Maybe<Scalars["Float"]["output"]>;
  ingredientCostPaid: Maybe<Scalars["Float"]["output"]>;
  otherAmountsPaid: Maybe<Scalars["Float"]["output"]>;
  percentageSalesTaxAmountPaid: Maybe<Scalars["Float"]["output"]>;
  planPay: Maybe<Array<Maybe<PlanPay>>>;
  runId: Maybe<Scalars["String"]["output"]>;
  runSuccessful: Maybe<Scalars["Boolean"]["output"]>;
  totalPayment: Maybe<Scalars["Float"]["output"]>;
};

export type Claims = {
  __typename?: "Claims";
  _id: Maybe<Scalars["ID"]["output"]>;
  billingOrder: Maybe<Scalars["Int"]["output"]>;
  bin: Maybe<Scalars["String"]["output"]>;
  cost: Maybe<Scalars["Float"]["output"]>;
  dateOfService: Maybe<Scalars["String"]["output"]>;
  externalId: Maybe<Scalars["String"]["output"]>;
  failedReversals: Maybe<Array<Maybe<ClaimsReverse>>>;
  payerName: Maybe<Scalars["String"]["output"]>;
  pcn: Maybe<Scalars["String"]["output"]>;
  received: Maybe<ClaimsReceived>;
  responseMessage: Maybe<Scalars["String"]["output"]>;
  reversal: Maybe<ClaimRevarsal>;
  runIds: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sent: Maybe<ClaimsSent>;
  status: Maybe<Scalars["String"]["output"]>;
  transactionCode: Maybe<Scalars["String"]["output"]>;
  transmissionDate: Maybe<Scalars["Date"]["output"]>;
};

export type ClaimsGroup = {
  __typename?: "ClaimsGroup";
  claim: Maybe<ClaimsGroupClaim>;
  prescriber: Maybe<ClaimsGroupPrescriber>;
  pricing: Maybe<ClaimsGroupPricing>;
};

export type ClaimsGroupClaim = {
  __typename?: "ClaimsGroupClaim";
  compoundCode: Maybe<Scalars["Int"]["output"]>;
  datePrescriptionWritten: Maybe<Scalars["Date"]["output"]>;
  dawCode: Maybe<Scalars["Int"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  fillNumber: Maybe<Scalars["Int"]["output"]>;
  numberOfRefillsAuthorized: Maybe<Scalars["Int"]["output"]>;
  otherCoverageCode: Maybe<Scalars["String"]["output"]>;
  pharmacyServiceType: Maybe<Scalars["String"]["output"]>;
  prescriptionOriginCode: Maybe<Scalars["String"]["output"]>;
  prescriptionRefNumber: Maybe<Scalars["String"]["output"]>;
  prescriptionRefNumberQualifier: Maybe<Scalars["String"]["output"]>;
  productId: Maybe<Scalars["String"]["output"]>;
  productIdQualifier: Maybe<Scalars["String"]["output"]>;
  quantityDispensed: Maybe<Scalars["Float"]["output"]>;
  requestedDaw: Maybe<Scalars["Int"]["output"]>;
  specialPackagingIndicator: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsGroupPrescriber = {
  __typename?: "ClaimsGroupPrescriber";
  lastName: Maybe<Scalars["String"]["output"]>;
  prescriberId: Maybe<Scalars["String"]["output"]>;
  prescriberIdQualifier: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsGroupPricing = {
  __typename?: "ClaimsGroupPricing";
  basisOfCostDetermination: Maybe<Scalars["String"]["output"]>;
  dispensingFeeSubmitted: Maybe<Scalars["Float"]["output"]>;
  flatSalesTaxSubmitted: Maybe<Scalars["Float"]["output"]>;
  grossAmountDue: Maybe<Scalars["Float"]["output"]>;
  incentiveAmountSubmitted: Maybe<Scalars["Float"]["output"]>;
  ingredientCostSubmitted: Maybe<Scalars["Float"]["output"]>;
  otherAmountClaims: Maybe<Array<Maybe<ClaimsGroupPricingOtherAmountsClaims>>>;
  patientPayAmountSubmitted: Maybe<Scalars["Float"]["output"]>;
  percentageSalesTaxAmountSubmitted: Maybe<Scalars["Float"]["output"]>;
  usualAndCustomaryCharge: Maybe<Scalars["Float"]["output"]>;
};

export type ClaimsGroupPricingOtherAmountsClaims = {
  __typename?: "ClaimsGroupPricingOtherAmountsClaims";
  otherAmountClaimSubmitted: Maybe<Scalars["Float"]["output"]>;
  otherAmountClaimSubmittedQualifier: Maybe<Scalars["String"]["output"]>;
  otherAmountClaimSubmittedQualifierTranslated: Maybe<
    Scalars["String"]["output"]
  >;
};

export type ClaimsHeader = {
  __typename?: "ClaimsHeader";
  bin: Maybe<Scalars["String"]["output"]>;
  dateOfService: Maybe<Scalars["Date"]["output"]>;
  processorControlNumber: Maybe<Scalars["String"]["output"]>;
  serviceProviderId: Maybe<Scalars["String"]["output"]>;
  serviceProviderIdQualifier: Maybe<Scalars["String"]["output"]>;
  softwareVendorId: Maybe<Scalars["String"]["output"]>;
  transactionCode: Maybe<Scalars["String"]["output"]>;
  transactionCount: Maybe<Scalars["Int"]["output"]>;
  version: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsMetaData = {
  __typename?: "ClaimsMetaData";
  insurance: Maybe<ClaimsMetaDataInsurance>;
  patient: Maybe<ClaimsMetaDataPatient>;
};

export type ClaimsMetaDataInsurance = {
  __typename?: "ClaimsMetaDataInsurance";
  cardholderId: Maybe<Scalars["String"]["output"]>;
  eligibilityClarificationCode: Maybe<Scalars["String"]["output"]>;
  groupId: Maybe<Scalars["String"]["output"]>;
  patientRelationshipCode: Maybe<Scalars["String"]["output"]>;
  personCode: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsMetaDataPatient = {
  __typename?: "ClaimsMetaDataPatient";
  city: Maybe<Scalars["String"]["output"]>;
  dateOfBirth: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  genderCode: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  placeOfService: Maybe<Scalars["String"]["output"]>;
  pregnancyIndicator: Maybe<Scalars["String"]["output"]>;
  residence: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
  streetAddress: Maybe<Scalars["String"]["output"]>;
  zip: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReceived = {
  __typename?: "ClaimsReceived";
  edi: Maybe<Scalars["String"]["output"]>;
  groups: Maybe<Array<Maybe<ClaimsReceivedGroup>>>;
  header: Maybe<ClaimsReceivedHeader>;
  metadata: Maybe<ClaimsReceivedMetadata>;
};

export type ClaimsReceivedGroup = {
  __typename?: "ClaimsReceivedGroup";
  claimResponse: Maybe<ClaimsReceivedGroupClaim>;
  pricingResponse: Maybe<ClaimsReceivedGroupPricing>;
  response: Maybe<ClaimsReceivedGroupResponse>;
};

export type ClaimsReceivedGroupClaim = {
  __typename?: "ClaimsReceivedGroupClaim";
  prescriptionRefNumber: Maybe<Scalars["String"]["output"]>;
  prescriptionRefNumberQualifier: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReceivedGroupPricing = {
  __typename?: "ClaimsReceivedGroupPricing";
  basisOfReimbursementDetermination: Maybe<Scalars["String"]["output"]>;
  dispensingFeePaid: Maybe<Scalars["Float"]["output"]>;
  flatSalesTaxAmountPaid: Maybe<Scalars["Float"]["output"]>;
  incentiveAmountPaid: Maybe<Scalars["Float"]["output"]>;
  ingredientCostPaid: Maybe<Scalars["Float"]["output"]>;
  otherAmountsPaid: Maybe<
    Array<Maybe<ClaimsReceivedGroupPricingOtherAmountPaid>>
  >;
  patientPayAmount: Maybe<Scalars["Float"]["output"]>;
  percentageSalesTaxAmountPaid: Maybe<Scalars["Float"]["output"]>;
  totalAmountPaid: Maybe<Scalars["Float"]["output"]>;
};

export type ClaimsReceivedGroupPricingOtherAmountPaid = {
  __typename?: "ClaimsReceivedGroupPricingOtherAmountPaid";
  otherAmountPaid: Maybe<Scalars["Float"]["output"]>;
  otherAmountPaidQualifier: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReceivedGroupResponse = {
  __typename?: "ClaimsReceivedGroupResponse";
  additionalMessageInformation: Maybe<Scalars["String"]["output"]>;
  authorizationNumber: Maybe<Scalars["String"]["output"]>;
  nextAvailableFillDate: Maybe<Scalars["String"]["output"]>;
  rejectCodes: Maybe<Array<Maybe<ClaimsReceivedGroupResponseRejectCode>>>;
  transactionStatus: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReceivedGroupResponseRejectCode = {
  __typename?: "ClaimsReceivedGroupResponseRejectCode";
  _id: Maybe<Scalars["ID"]["output"]>;
  rejectCode: Maybe<Scalars["String"]["output"]>;
  rejectCodeTranslated: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReceivedHeader = {
  __typename?: "ClaimsReceivedHeader";
  dateOfService: Maybe<Scalars["String"]["output"]>;
  responseStatus: Maybe<Scalars["String"]["output"]>;
  serviceProviderId: Maybe<Scalars["String"]["output"]>;
  serviceProviderIdQualifier: Maybe<Scalars["String"]["output"]>;
  transactionCode: Maybe<Scalars["String"]["output"]>;
  transactionCount: Maybe<Scalars["Int"]["output"]>;
  version: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReceivedMetadata = {
  __typename?: "ClaimsReceivedMetadata";
  insuranceResponse: Maybe<ClaimsReceivedMetadataInsurance>;
  patientResponse: Maybe<ClaimsReceivedMetadataPatient>;
};

export type ClaimsReceivedMetadataInsurance = {
  __typename?: "ClaimsReceivedMetadataInsurance";
  groupId: Maybe<Scalars["String"]["output"]>;
  networkReimburstmentId: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReceivedMetadataPatient = {
  __typename?: "ClaimsReceivedMetadataPatient";
  dateOfBirth: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
};

export type ClaimsReverse = {
  __typename?: "ClaimsReverse";
  received: Maybe<ClaimsReceived>;
  sent: Maybe<ClaimsSent>;
};

export type ClaimsSent = {
  __typename?: "ClaimsSent";
  edi: Maybe<Scalars["String"]["output"]>;
  groups: Maybe<Array<Maybe<ClaimsGroup>>>;
  header: Maybe<ClaimsHeader>;
  metadata: Maybe<ClaimsMetaData>;
};

export type ClassifiedSubstitutionsPartial = {
  __typename?: "ClassifiedSubstitutionsPartial";
  nonPreferred: Array<Maybe<NdcSubstitutionPartial>>;
  preferred: Array<Maybe<NdcSubstitutionPartial>>;
  rest: Array<Maybe<NdcSubstitutionPartial>>;
};

export type CoAgentCode = {
  __typename?: "CoAgentCode";
  code: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
};

export type CommunicationNumbers = {
  __typename?: "CommunicationNumbers";
  beeper: Maybe<Array<Maybe<PhoneType>>>;
  directAddress: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  fax: Maybe<Array<Maybe<PhoneType>>>;
  home: Maybe<Array<Maybe<PhoneType>>>;
  other: Maybe<Array<Maybe<PhoneType>>>;
  phone: Maybe<PhoneType>;
  work: Maybe<Array<Maybe<PhoneType>>>;
};

export type Condition = {
  __typename?: "Condition";
  idField: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  patientSurveyInput: Maybe<Scalars["String"]["output"]>;
  synonyms: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ConfirmFillPv1VerificationInput = {
  pharmacistAttestation?: InputMaybe<Scalars["Boolean"]["input"]>;
  prescriberDeaNumber?: InputMaybe<Scalars["String"]["input"]>;
  prescriberStateLicense?: InputMaybe<Scalars["String"]["input"]>;
};

export type Contact = {
  __typename?: "Contact";
  email: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  sms: Maybe<Scalars["String"]["output"]>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sms?: InputMaybe<Scalars["String"]["input"]>;
};

export type Contacts = {
  __typename?: "Contacts";
  email: Maybe<Scalars["String"]["output"]>;
  fax: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type ContactsInput = {
  fax?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type CopayCountByStatus = {
  __typename?: "CopayCountByStatus";
  complete: Maybe<Scalars["Int"]["output"]>;
  confirmed: Maybe<Scalars["Int"]["output"]>;
  pending: Maybe<Scalars["Int"]["output"]>;
  priorAuthorization: Maybe<Scalars["Int"]["output"]>;
  rejected: Maybe<Scalars["Int"]["output"]>;
  triage: Maybe<Scalars["Int"]["output"]>;
};

export type CopayPayment = {
  __typename?: "CopayPayment";
  cardHolderName: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  lastFourDigits: Maybe<Scalars["String"]["output"]>;
  paymentType: Maybe<Scalars["String"]["output"]>;
  transactionAmount: Maybe<Scalars["Float"]["output"]>;
  transactionDate: Maybe<Scalars["Date"]["output"]>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
};

export type CopayRequest = {
  __typename?: "CopayRequest";
  _id: Scalars["ID"]["output"];
  apiRequest: Maybe<Scalars["String"]["output"]>;
  copayRequestFills: Maybe<Array<Maybe<CopayRequestFill>>>;
  coreCopayApiRequestToken: Maybe<Scalars["String"]["output"]>;
  coreCopayId: Maybe<Scalars["Int"]["output"]>;
  coreCopayToken: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customer: Maybe<Customer>;
  customerId: Scalars["ID"]["output"];
  customerMetaData: Maybe<Scalars["String"]["output"]>;
  customerRef: Maybe<Scalars["String"]["output"]>;
  error: Maybe<Scalars["String"]["output"]>;
  fulfillableByLocationIds: Array<Maybe<Scalars["ID"]["output"]>>;
  initiatePriorAuthorization: Maybe<Scalars["Boolean"]["output"]>;
  insuranceOptions: Maybe<Array<Maybe<OrderInsuranceOption>>>;
  insurances: Maybe<Array<Maybe<OrderInsurance>>>;
  location: Maybe<Location>;
  locationId: Scalars["ID"]["output"];
  lock: Maybe<Lock>;
  notes: Maybe<Scalars["String"]["output"]>;
  patient: Maybe<Patient>;
  patientId: Scalars["ID"]["output"];
  rejectionDetails: Maybe<RejectionDetails>;
  shipToState: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  triages: Maybe<Array<Maybe<CopayTriage>>>;
};

export type CopayRequestFill = {
  __typename?: "CopayRequestFill";
  _id: Scalars["ID"]["output"];
  activePriorAuthorization: Maybe<PriorAuthorization>;
  adjudication: Maybe<RxFillRequestAdjudication>;
  claimRejectCodes: Maybe<Array<Maybe<RejectCode>>>;
  cobOverrides: Maybe<Array<Maybe<RxFillRequestCobOverride>>>;
  copayAmount: Maybe<Scalars["Float"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  dispensed: Maybe<DispensedMedication>;
  durs: Maybe<Array<Maybe<RxFillRequestDur>>>;
  ediOverrides: Maybe<Array<Maybe<EdiOverrides>>>;
  ediReversalOverrides: Maybe<Array<Maybe<EdiReversalOverrides>>>;
  error: Maybe<Scalars["String"]["output"]>;
  fill: Maybe<Fill>;
  fillId: Scalars["ID"]["output"];
  ndc: Maybe<Scalars["String"]["output"]>;
  overrideCodes: Maybe<Array<Maybe<RxFillRequestOverrideCodes>>>;
  prescription: Maybe<Prescription>;
  prescriptionId: Scalars["ID"]["output"];
  quantity: Maybe<Scalars["Float"]["output"]>;
  rejectionDetails: Maybe<RejectionDetails>;
  requestedMedicationName: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
};

export type CopayRequestWithPaging = {
  __typename?: "CopayRequestWithPaging";
  copayRequests: Maybe<Array<Maybe<CopayRequest>>>;
  currentPage: Maybe<Scalars["Int"]["output"]>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type CopayTriage = {
  __typename?: "CopayTriage";
  _id: Maybe<Scalars["ID"]["output"]>;
  code: Scalars["String"]["output"];
  copayRequestFillId: Maybe<Scalars["ID"]["output"]>;
  endDate: Maybe<Scalars["Date"]["output"]>;
  fillId: Maybe<Scalars["ID"]["output"]>;
  message: Maybe<Scalars["String"]["output"]>;
  reason: Scalars["String"]["output"];
  startDate: Scalars["Date"]["output"];
  userPlacedInTriage: Scalars["ID"]["output"];
  userRemovedFromTriage: Maybe<Scalars["ID"]["output"]>;
};

export type CreateInventoryGroupInput = {
  active: Scalars["Boolean"]["input"];
  customerId: Scalars["String"]["input"];
  pioneerAlias: Scalars["String"]["input"];
};

export type CreateSigCodeInput = {
  code: Scalars["String"]["input"];
  enTranslation: Scalars["String"]["input"];
  esTranslation?: InputMaybe<Scalars["String"]["input"]>;
  exceptionNDC?: InputMaybe<Array<InputMaybe<ExceptionNdcInput>>>;
  multiplier?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CriticalMedicalNotes = {
  __typename?: "CriticalMedicalNotes";
  _id: Scalars["ID"]["output"];
  createdAt: Scalars["Date"]["output"];
  createdBy: Scalars["ID"]["output"];
  history: Maybe<Array<Maybe<CriticalMedicalNotesHistory>>>;
  identifier: Scalars["String"]["output"];
  note: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  updatedBy: Scalars["ID"]["output"];
};

export type CriticalMedicalNotesHistory = {
  __typename?: "CriticalMedicalNotesHistory";
  note: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  updatedBy: Scalars["ID"]["output"];
};

export type CustomDurSubject = {
  __typename?: "CustomDURSubject";
  name: Scalars["String"]["output"];
};

export type CustomDurSubjectInput = {
  name: Scalars["String"]["input"];
};

export type CustomDurSubjects = {
  __typename?: "CustomDURSubjects";
  allergies: Maybe<Array<Maybe<CustomDurSubject>>>;
  conditions: Maybe<Array<Maybe<CustomDurSubject>>>;
  medications: Maybe<Array<Maybe<CustomDurSubject>>>;
};

export type CustomDurSubjectsInput = {
  allergies?: InputMaybe<Array<InputMaybe<CustomDurSubjectInput>>>;
  conditions?: InputMaybe<Array<InputMaybe<CustomDurSubjectInput>>>;
  medications?: InputMaybe<Array<InputMaybe<CustomDurSubjectInput>>>;
};

export type CustomOrder = {
  __typename?: "CustomOrder";
  _id: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customer: Maybe<Customer>;
  customerId: Maybe<Scalars["String"]["output"]>;
  paymentType: Maybe<Scalars["String"]["output"]>;
  pricing: Maybe<OrderPricing>;
  shipments: Maybe<Array<Maybe<Shipment>>>;
};

export type Customer = {
  __typename?: "Customer";
  _id: Maybe<Scalars["ID"]["output"]>;
  active: Maybe<Scalars["Boolean"]["output"]>;
  legacyId: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type CustomerId = {
  __typename?: "CustomerId";
  _id: Maybe<Scalars["ID"]["output"]>;
};

export type CustomerInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CustomerReplace = {
  __typename?: "CustomerReplace";
  action: Scalars["String"]["output"];
  fillRequestToken: Scalars["String"]["output"];
  orderId: Maybe<Scalars["ID"]["output"]>;
  otcProducts: Array<Maybe<CustomerReturnOtcProduct>>;
  reason: Scalars["String"]["output"];
  shippingMethod: Scalars["String"]["output"];
  snapId: Maybe<Scalars["ID"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type CustomerReplaceInput = {
  action: Scalars["String"]["input"];
  orderId: Scalars["ID"]["input"];
  otcProducts: Array<InputMaybe<CustomerReturnOtcProductInput>>;
  reason: Scalars["String"]["input"];
  shippingData: ShippingData;
};

export type CustomerReturn = {
  __typename?: "CustomerReturn";
  _id: Scalars["ID"]["output"];
  action: Scalars["String"]["output"];
  carrierTrackingNumber: Maybe<Scalars["String"]["output"]>;
  coreFillRequestToken: Maybe<Scalars["String"]["output"]>;
  orderId: Scalars["ID"]["output"];
  otcProducts: Maybe<Array<Maybe<CustomerReturnProduct>>>;
  reason: Scalars["String"]["output"];
  returnedOrderId: Maybe<Scalars["String"]["output"]>;
  shippingCharge: Maybe<Scalars["String"]["output"]>;
  shippingCostRefunded: Maybe<Scalars["Float"]["output"]>;
  shippingMethod: Maybe<Scalars["String"]["output"]>;
  shippingPayment: Maybe<Scalars["String"]["output"]>;
  snapId: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  total: Maybe<Scalars["Int"]["output"]>;
};

export type CustomerReturnInput = {
  action: Scalars["String"]["input"];
  orderId: Scalars["ID"]["input"];
  otcProducts: Array<InputMaybe<CustomerReturnOtcProductInput>>;
  reason: Scalars["String"]["input"];
  shippingCostRefunded?: InputMaybe<Scalars["Float"]["input"]>;
  shippingPayment?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomerReturnOtcProduct = {
  __typename?: "CustomerReturnOtcProduct";
  _id: Scalars["ID"]["output"];
  otcProductId: Maybe<Scalars["String"]["output"]>;
  quantity: Scalars["Int"]["output"];
  sku: Scalars["String"]["output"];
};

export type CustomerReturnOtcProductInput = {
  otcId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type CustomerReturnProduct = {
  __typename?: "CustomerReturnProduct";
  _id: Scalars["ID"]["output"];
  otcProductId: Maybe<Scalars["String"]["output"]>;
  quantity: Scalars["Int"]["output"];
  sku: Scalars["String"]["output"];
};

export type CustomerReturnReturn = {
  __typename?: "CustomerReturnReturn";
  action: Scalars["String"]["output"];
  orderId: Maybe<Scalars["ID"]["output"]>;
  otcProducts: Array<Maybe<CustomerReturnOtcProduct>>;
  reason: Scalars["String"]["output"];
  shippingMethod: Scalars["String"]["output"];
  snapId: Maybe<Scalars["ID"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type CustomerUpdate = {
  __typename?: "CustomerUpdate";
  customer: Maybe<Customer>;
  operation: Maybe<Scalars["String"]["output"]>;
};

export type CustomerX = {
  __typename?: "CustomerX";
  id: Scalars["String"]["output"];
  legacyId: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type DurMessage = {
  __typename?: "DURMessage";
  description: Scalars["String"]["output"];
  ignore: Maybe<Scalars["Boolean"]["output"]>;
  subject: Maybe<DurMessageSubject>;
  type: Scalars["String"]["output"];
};

export type DurMessageSubject = {
  __typename?: "DURMessageSubject";
  conceptType: Scalars["String"]["output"];
  idField: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type DurRecord = {
  __typename?: "DURRecord";
  createdAt: Maybe<Scalars["Date"]["output"]>;
  input: Maybe<DurSubjectInputs>;
  messages: Maybe<Array<Maybe<DurMessage>>>;
  results: Maybe<Array<Maybe<DurResult>>>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
};

export type DurResult = {
  __typename?: "DURResult";
  directions: Scalars["String"]["output"];
  ignore: Maybe<Scalars["Boolean"]["output"]>;
  releasedByUser: Maybe<User>;
  releasedByUserId: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  subjects: Maybe<Array<Maybe<DurSubject>>>;
  type: Scalars["String"]["output"];
};

export type DurSubject = {
  __typename?: "DURSubject";
  conceptType: Maybe<Scalars["String"]["output"]>;
  idField: Scalars["String"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  patientSurveyInput: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type DurSubjectInput = {
  fromHistory?: InputMaybe<Scalars["Boolean"]["input"]>;
  idField: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  patientSurveyInput?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type DurSubjectInputs = {
  __typename?: "DURSubjectInputs";
  allergies: Maybe<Array<Allergy>>;
  conditions: Maybe<Array<Condition>>;
  custom: Maybe<CustomDurSubjects>;
  medications: Maybe<Array<Medication>>;
};

export type DateOverride = {
  __typename?: "DateOverride";
  applyTo: Maybe<Scalars["String"]["output"]>;
  send: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["Date"]["output"]>;
};

export type DateOverrideInput = {
  applyTo?: InputMaybe<Scalars["String"]["input"]>;
  send?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Scalars["Date"]["input"]>;
};

export type DiabeticSupply = {
  __typename?: "DiabeticSupply";
  hasAutomatedInsulinDevice: Maybe<Scalars["Boolean"]["output"]>;
  insulinDependent: Maybe<Scalars["Boolean"]["output"]>;
  supplyIndicator: Maybe<Scalars["Boolean"]["output"]>;
  testingFrequency: Maybe<Scalars["Int"]["output"]>;
  testingFrequencyNotes: Maybe<Scalars["String"]["output"]>;
};

export type Diagnosis = {
  __typename?: "Diagnosis";
  clinicalInformationQualifier: Maybe<Scalars["Int"]["output"]>;
  primary: Maybe<DiagnosisQualifiedCodeWithDescription>;
  secondary: Maybe<DiagnosisQualifiedCodeWithDescription>;
};

export type DiagnosisGeneral = {
  __typename?: "DiagnosisGeneral";
  effectiveDate: Maybe<Scalars["Date"]["output"]>;
  expirationDate: Maybe<Scalars["Date"]["output"]>;
  problemNameCoded: Maybe<ProblemNameCode>;
  problemType: Maybe<SnomedCode>;
  sourceOfInformation: Maybe<Scalars["String"]["output"]>;
};

export type DiagnosisQualifiedCodeWithDescription = {
  __typename?: "DiagnosisQualifiedCodeWithDescription";
  code: Maybe<Scalars["String"]["output"]>;
  dateOfLastOfficeVisit: Maybe<Scalars["Date"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
};

export type DirectTransfer = {
  __typename?: "DirectTransfer";
  customerId: Maybe<Scalars["String"]["output"]>;
  directTransferId: Maybe<Scalars["String"]["output"]>;
  locationId: Maybe<Scalars["String"]["output"]>;
  patient: Maybe<DirectTransferPatient>;
  patientToken: Maybe<Scalars["String"]["output"]>;
  prescriber: Maybe<DirectTransferPrescriber>;
  prescription: Maybe<DirectTransferPrescription>;
  prescriptionImageS3Url: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  transferFrom: Maybe<DirectTransferFrom>;
  transferTo: Maybe<DirectTransferTo>;
};

export type DirectTransferAddress = {
  __typename?: "DirectTransferAddress";
  city: Maybe<Scalars["String"]["output"]>;
  company: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
  street1: Maybe<Scalars["String"]["output"]>;
  street2: Maybe<Scalars["String"]["output"]>;
  zip: Maybe<Scalars["String"]["output"]>;
};

export type DirectTransferFrom = {
  __typename?: "DirectTransferFrom";
  address: Maybe<DirectTransferAddress>;
  idNumber: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  pharmacist: Maybe<Scalars["String"]["output"]>;
};

export type DirectTransferPatient = {
  __typename?: "DirectTransferPatient";
  city: Maybe<Scalars["String"]["output"]>;
  country: Maybe<Scalars["String"]["output"]>;
  dob: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  gender: Maybe<Scalars["String"]["output"]>;
  guardian: Maybe<Scalars["String"]["output"]>;
  languagePreference: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  patientToken: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  species: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
  street1: Maybe<Scalars["String"]["output"]>;
  street2: Maybe<Scalars["String"]["output"]>;
  viewedNoticeOfPrivacyPractices: Maybe<Scalars["Boolean"]["output"]>;
  viewedNoticeOfPrivacyPracticesDate: Maybe<Scalars["String"]["output"]>;
  zip: Maybe<Scalars["String"]["output"]>;
};

export type DirectTransferPrescriber = {
  __typename?: "DirectTransferPrescriber";
  address: Maybe<DirectTransferAddress>;
  firstName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  prescriberType: Maybe<Scalars["String"]["output"]>;
};

export type DirectTransferPrescription = {
  __typename?: "DirectTransferPrescription";
  canSubstitute: Maybe<Scalars["Boolean"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  diagnosis: Maybe<Scalars["String"]["output"]>;
  directTransferUrl: Maybe<Scalars["String"]["output"]>;
  firstFillDate: Maybe<Scalars["String"]["output"]>;
  icd10Code: Maybe<Scalars["String"]["output"]>;
  lastFillDate: Maybe<Scalars["String"]["output"]>;
  medicationName: Maybe<Scalars["String"]["output"]>;
  medicationSig: Maybe<Scalars["String"]["output"]>;
  notes: Maybe<Scalars["String"]["output"]>;
  number: Maybe<Scalars["String"]["output"]>;
  prescribedNdc: Maybe<Scalars["String"]["output"]>;
  quantityDispensed: Maybe<Scalars["String"]["output"]>;
  quantityWritten: Maybe<Scalars["String"]["output"]>;
  refillsLeft: Maybe<Scalars["Int"]["output"]>;
  refillsTransferred: Maybe<Scalars["Int"]["output"]>;
  writtenDate: Maybe<Scalars["String"]["output"]>;
};

export type DirectTransferTo = {
  __typename?: "DirectTransferTo";
  idNumber: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  pharmacist: Maybe<Scalars["String"]["output"]>;
};

export type DiscontinuePrescription = {
  note?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
};

export type DiscontinuePrescriptionsResponse = {
  __typename?: "DiscontinuePrescriptionsResponse";
  successful: Array<Maybe<DiscontinuePrescriptionsSuccessful>>;
  unsuccessful: Array<Maybe<DiscontinuePrescriptionsUnsuccessful>>;
};

export type DiscontinuePrescriptionsSuccessful = {
  __typename?: "DiscontinuePrescriptionsSuccessful";
  prescriptionId: Scalars["String"]["output"];
  rxNumber: Scalars["Int"]["output"];
};

export type DiscontinuePrescriptionsUnsuccessful = {
  __typename?: "DiscontinuePrescriptionsUnsuccessful";
  error: Scalars["String"]["output"];
  prescriptionId: Scalars["String"]["output"];
  rxNumber: Maybe<Scalars["Int"]["output"]>;
};

export type DispensedMedication = {
  __typename?: "DispensedMedication";
  alternateLabelName: Maybe<Scalars["String"]["output"]>;
  date: Maybe<Scalars["String"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  directions: Maybe<Scalars["String"]["output"]>;
  dispensedAt: Maybe<Scalars["String"]["output"]>;
  ffsInventoryInfo: Maybe<FfsInventoryInfo>;
  gcn: Maybe<Scalars["String"]["output"]>;
  inventoryGroup: Maybe<InventoryGroup>;
  inventoryGroupId: Maybe<Scalars["ID"]["output"]>;
  manualEntryFilled: Maybe<Scalars["Boolean"]["output"]>;
  manufacturer: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  ndc: Maybe<Scalars["String"]["output"]>;
  packageSizeUnitOfMeasure: Maybe<Scalars["String"]["output"]>;
  packagesRequired: Maybe<Scalars["Int"]["output"]>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  quantityPerPackage: Maybe<Scalars["Float"]["output"]>;
  sigCode: Maybe<Scalars["String"]["output"]>;
  stockInfo: Maybe<StockInfo>;
  strength: Maybe<Strength>;
  upc: Maybe<Scalars["String"]["output"]>;
};

export type DispensingDecision = {
  __typename?: "DispensingDecision";
  _id: Scalars["ID"]["output"];
  augmentSubstitutionsResult: Maybe<Array<Maybe<NdcSubstitutionPartial>>>;
  classifySubstitutionsResult: Maybe<ClassifiedSubstitutionsPartial>;
  consigned: Maybe<Scalars["Boolean"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  dispenseAsRequested: Maybe<Scalars["Boolean"]["output"]>;
  dispenseAsWritten: Maybe<Scalars["Int"]["output"]>;
  fillId: Maybe<Scalars["ID"]["output"]>;
  janusError: Maybe<Scalars["String"]["output"]>;
  janusResponse: Maybe<JanusResultsPartial>;
  onFormulary: Maybe<Scalars["Boolean"]["output"]>;
  patientRequestedNdc: Scalars["Boolean"]["output"];
  preferred: Maybe<Scalars["Boolean"]["output"]>;
  prescribedNdc: Scalars["String"]["output"];
  prescriptionId: Scalars["ID"]["output"];
  selectedInventoryGroupId: Maybe<Scalars["ID"]["output"]>;
  selectedNdc: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
  useJanusFormulary: Maybe<Scalars["Boolean"]["output"]>;
};

export type Document = {
  __typename?: "Document";
  _id: Maybe<Scalars["ID"]["output"]>;
  caption: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  fill: Maybe<Scalars["String"]["output"]>;
  isArchived: Maybe<Scalars["Boolean"]["output"]>;
  labelTypes: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderId: Maybe<Scalars["String"]["output"]>;
  patient: Maybe<Scalars["String"]["output"]>;
  prescription: Maybe<Scalars["String"]["output"]>;
  s3: Maybe<S3Document>;
  s3SignedUrl: Maybe<Scalars["String"]["output"]>;
  tag: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
  uploadedBy: Maybe<UploadedBy>;
};

export type DrugAdministrationRequest = {
  __typename?: "DrugAdministrationRequest";
  cancelSuspension: Maybe<Scalars["String"]["output"]>;
  drugAdminReasonCode: Maybe<Scalars["String"]["output"]>;
  drugAdminReasonText: Maybe<Scalars["String"]["output"]>;
  fixedLengthSuspension: Maybe<FixedLengthSuspension>;
  indefiniteSuspension: Maybe<IndefiniteSuspension>;
  resumeAdministration: Maybe<ResumeAdministration>;
};

export type DrugCoded = {
  __typename?: "DrugCoded";
  deaSchedule: Maybe<NciCode>;
  drugDBCode: Maybe<EscriptDrugDbCode>;
  productCode: Maybe<EscriptProductCode>;
  strength: Maybe<EscriptStrength>;
};

export type DrugDescriptors = {
  __typename?: "DrugDescriptors";
  back: Maybe<Scalars["String"]["output"]>;
  clarity: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  coating: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  color: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  flavor: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  form: Scalars["String"]["output"];
  front: Maybe<Scalars["String"]["output"]>;
  score: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shape: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type DrugDescriptorsInput = {
  back?: InputMaybe<Scalars["String"]["input"]>;
  clarity?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  coating?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  color?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  flavor?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  form: Scalars["String"]["input"];
  front?: InputMaybe<Scalars["String"]["input"]>;
  score?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  shape?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type DrugImageImprint = {
  __typename?: "DrugImageImprint";
  descriptors: DrugDescriptors;
  image: Maybe<Scalars["String"]["output"]>;
  manufacturer: Maybe<Scalars["String"]["output"]>;
  ndcPackage: Scalars["String"]["output"];
  propertyId: Scalars["Int"]["output"];
  udid: Scalars["Int"]["output"];
};

export type DrugInfo = {
  __typename?: "DrugInfo";
  description: Maybe<Scalars["String"]["output"]>;
  dosageForm: Maybe<Scalars["String"]["output"]>;
  drugDescriptorId: Maybe<Scalars["Int"]["output"]>;
  drugName: Maybe<Scalars["String"]["output"]>;
  equivalentNDCs: Maybe<NdcSubstitution>;
  genericFor: Maybe<Scalars["String"]["output"]>;
  gpiName: Maybe<Scalars["String"]["output"]>;
  labeler: Maybe<NdcLabeler>;
  ndc: Maybe<Scalars["String"]["output"]>;
  packagePrice: Maybe<Scalars["Float"]["output"]>;
  packages: Maybe<Array<Maybe<NdcFullInfoPackage>>>;
  productInfo: Maybe<NdcProduct>;
  routeOfAdministration: Maybe<Scalars["String"]["output"]>;
  strength: Maybe<Scalars["String"]["output"]>;
  strengthUnitOfMeasure: Maybe<Scalars["String"]["output"]>;
  substitution: Maybe<Scalars["String"]["output"]>;
  therapeuticClass: Maybe<Scalars["String"]["output"]>;
  unitPrice: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
};

export type DrugProductCodeWithText = {
  __typename?: "DrugProductCodeWithText";
  code: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
};

export type DrugUseEvaluation = {
  __typename?: "DrugUseEvaluation";
  acknowledgementReason: Maybe<Scalars["String"]["output"]>;
  clinicalSignificanceCode: Maybe<Scalars["String"]["output"]>;
  coAgent: Maybe<CoAgentCode>;
  professionalServiceCode: Maybe<Scalars["String"]["output"]>;
  serviceReasonCode: Maybe<Scalars["String"]["output"]>;
  serviceResultCode: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverrides = {
  __typename?: "EdiOverrides";
  additionalDocumentation: Maybe<EdiOverridesAdditionalDocumentation>;
  claim: Maybe<EdiOverridesClaim>;
  clinical: Maybe<EdiOverridesClinical>;
  coordinationOfBenefits: Maybe<EdiOverridesCob>;
  facility: Maybe<EdiOverridesFacility>;
  header: Maybe<EdiOverridesHeader>;
  insurance: Maybe<EdiOverridesInsurance>;
  narrative: Maybe<EdiOverridesNarrative>;
  orderInsuranceOptionId: Maybe<Scalars["String"]["output"]>;
  patient: Maybe<EdiOverridesPatient>;
  pharmacyProvider: Maybe<EdiOverridesPharmacyProvider>;
  prescriber: Maybe<EdiOverridesPrescriber>;
  pricing: Maybe<EdiOverridesPricing>;
};

export type EdiOverridesAdditionalDocumentation = {
  __typename?: "EdiOverridesAdditionalDocumentation";
  additionalDocumentationTypeID: Maybe<Scalars["String"]["output"]>;
  lengthOfNeed: Maybe<Scalars["String"]["output"]>;
  lengthOfNeedQualifier: Maybe<Scalars["String"]["output"]>;
  prescriberDateSigned: Maybe<Scalars["String"]["output"]>;
  requestPeriodBeginDate: Maybe<Scalars["String"]["output"]>;
  requestPeriodRecertificationDate: Maybe<Scalars["String"]["output"]>;
  requestStatus: Maybe<Scalars["String"]["output"]>;
  supportingDocumentation: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesAdditionalDocumentationInput = {
  additionalDocumentationTypeID?: InputMaybe<Scalars["String"]["input"]>;
  lengthOfNeed?: InputMaybe<Scalars["String"]["input"]>;
  lengthOfNeedQualifier?: InputMaybe<Scalars["String"]["input"]>;
  prescriberDateSigned?: InputMaybe<Scalars["String"]["input"]>;
  requestPeriodBeginDate?: InputMaybe<Scalars["String"]["input"]>;
  requestPeriodRecertificationDate?: InputMaybe<Scalars["String"]["input"]>;
  requestStatus?: InputMaybe<Scalars["String"]["input"]>;
  supportingDocumentation?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesCob = {
  __typename?: "EdiOverridesCOB";
  internalControlNumber: Maybe<Scalars["String"]["output"]>;
  otherPayerCoverageType: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesCoBinput = {
  internalControlNumber?: InputMaybe<Scalars["String"]["input"]>;
  otherPayerCoverageType?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesClaim = {
  __typename?: "EdiOverridesClaim";
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  intermediaryAuthorizationId: Maybe<Scalars["String"]["output"]>;
  intermediaryAuthorizationTypeId: Maybe<Scalars["String"]["output"]>;
  pharmacyServiceType: Maybe<Scalars["String"]["output"]>;
  scheduledPrescriptionIdNumber: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesClaimInput = {
  daysSupply?: InputMaybe<Scalars["Int"]["input"]>;
  intermediaryAuthorizationId?: InputMaybe<Scalars["String"]["input"]>;
  intermediaryAuthorizationTypeId?: InputMaybe<Scalars["String"]["input"]>;
  pharmacyServiceType?: InputMaybe<Scalars["String"]["input"]>;
  scheduledPrescriptionIdNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesClinical = {
  __typename?: "EdiOverridesClinical";
  clinicalInformationCounter: Maybe<Scalars["Int"]["output"]>;
  diagnosisCode: Maybe<Scalars["String"]["output"]>;
  diagnosisCodeQualifier: Maybe<Scalars["String"]["output"]>;
  measurementDate: Maybe<Scalars["String"]["output"]>;
  measurementDimension: Maybe<Scalars["String"]["output"]>;
  measurementTime: Maybe<Scalars["String"]["output"]>;
  measurementUnit: Maybe<Scalars["String"]["output"]>;
  measurementValue: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesClinicalInput = {
  clinicalInformationCounter?: InputMaybe<Scalars["Int"]["input"]>;
  diagnosisCode?: InputMaybe<Scalars["String"]["input"]>;
  diagnosisCodeQualifier?: InputMaybe<Scalars["String"]["input"]>;
  measurementDate?: InputMaybe<Scalars["String"]["input"]>;
  measurementDimension?: InputMaybe<Scalars["String"]["input"]>;
  measurementTime?: InputMaybe<Scalars["String"]["input"]>;
  measurementUnit?: InputMaybe<Scalars["String"]["input"]>;
  measurementValue?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesFacility = {
  __typename?: "EdiOverridesFacility";
  facilityCityAddress: Maybe<Scalars["String"]["output"]>;
  facilityId: Maybe<Scalars["String"]["output"]>;
  facilityName: Maybe<Scalars["String"]["output"]>;
  facilityStateProvinceAddress: Maybe<Scalars["String"]["output"]>;
  facilityStreetAddress: Maybe<Scalars["String"]["output"]>;
  facilityZipPostalCode: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesFacilityInput = {
  facilityCityAddress?: InputMaybe<Scalars["String"]["input"]>;
  facilityId?: InputMaybe<Scalars["String"]["input"]>;
  facilityName?: InputMaybe<Scalars["String"]["input"]>;
  facilityStateProvinceAddress?: InputMaybe<Scalars["String"]["input"]>;
  facilityStreetAddress?: InputMaybe<Scalars["String"]["input"]>;
  facilityZipPostalCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesHeader = {
  __typename?: "EdiOverridesHeader";
  dateOfService: Maybe<Scalars["String"]["output"]>;
  serviceProviderId: Maybe<Scalars["String"]["output"]>;
  softwareVendorId: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesHeaderInput = {
  dateOfService?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  softwareVendorId?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesInput = {
  additionalDocumentation?: InputMaybe<EdiOverridesAdditionalDocumentationInput>;
  claim?: InputMaybe<EdiOverridesClaimInput>;
  clinical?: InputMaybe<EdiOverridesClinicalInput>;
  coordinationOfBenefits?: InputMaybe<EdiOverridesCoBinput>;
  facility?: InputMaybe<EdiOverridesFacilityInput>;
  header?: InputMaybe<EdiOverridesHeaderInput>;
  insurance?: InputMaybe<EdiOverridesInsuranceInput>;
  narrative?: InputMaybe<EdiOverridesNarrativeInput>;
  orderInsuranceOptionId?: InputMaybe<Scalars["String"]["input"]>;
  patient?: InputMaybe<EdiOverridesPatientInput>;
  pharmacyProvider?: InputMaybe<EdiOverridesPharmacyProviderInput>;
  prescriber?: InputMaybe<EdiOverridesPrescriberInput>;
  pricing?: InputMaybe<EdiOverridesPricingInput>;
};

export type EdiOverridesInsurance = {
  __typename?: "EdiOverridesInsurance";
  eligibilityClarification: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  homePlan: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  planId: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesInsuranceInput = {
  eligibilityClarification?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  homePlan?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  planId?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesNarrative = {
  __typename?: "EdiOverridesNarrative";
  narrativeMessage: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesNarrativeInput = {
  narrativeMessage?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesPatient = {
  __typename?: "EdiOverridesPatient";
  id: Maybe<Scalars["String"]["output"]>;
  residence: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesPatientInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  residence?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesPharmacyProvider = {
  __typename?: "EdiOverridesPharmacyProvider";
  medicaidId: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesPharmacyProviderInput = {
  medicaidId?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesPrescriber = {
  __typename?: "EdiOverridesPrescriber";
  primaryCareProviderId: Maybe<Scalars["String"]["output"]>;
  primaryCareProviderIdQualifier: Maybe<Scalars["String"]["output"]>;
  primaryCareProviderLastName: Maybe<Scalars["String"]["output"]>;
};

export type EdiOverridesPrescriberInput = {
  primaryCareProviderId?: InputMaybe<Scalars["String"]["input"]>;
  primaryCareProviderIdQualifier?: InputMaybe<Scalars["String"]["input"]>;
  primaryCareProviderLastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type EdiOverridesPricing = {
  __typename?: "EdiOverridesPricing";
  basisOfCostDetermination: Maybe<Scalars["String"]["output"]>;
  dispensingFeeSubmitted: Maybe<Scalars["Float"]["output"]>;
  flatSalesTax: Maybe<Scalars["Float"]["output"]>;
  grossAmountDue: Maybe<Scalars["Float"]["output"]>;
  ingredientCostSubmitted: Maybe<Scalars["Float"]["output"]>;
  patientPayAmount: Maybe<Scalars["Float"]["output"]>;
  percentageSalesTaxAmount: Maybe<Scalars["Float"]["output"]>;
  percentageSalesTaxBasis: Maybe<Scalars["String"]["output"]>;
  percentageSalesTaxRate: Maybe<Scalars["Float"]["output"]>;
  usualAndCustomaryCharge: Maybe<Scalars["Float"]["output"]>;
};

export type EdiOverridesPricingInput = {
  basisOfCostDetermination?: InputMaybe<Scalars["String"]["input"]>;
  dispensingFeeSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
  flatSalesTax?: InputMaybe<Scalars["Float"]["input"]>;
  grossAmountDue?: InputMaybe<Scalars["Float"]["input"]>;
  ingredientCostSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
  patientPayAmount?: InputMaybe<Scalars["Float"]["input"]>;
  percentageSalesTaxAmount?: InputMaybe<Scalars["Float"]["input"]>;
  percentageSalesTaxBasis?: InputMaybe<Scalars["String"]["input"]>;
  percentageSalesTaxRate?: InputMaybe<Scalars["Float"]["input"]>;
  usualAndCustomaryCharge?: InputMaybe<Scalars["Float"]["input"]>;
};

export type EdiReversalOverrides = {
  __typename?: "EdiReversalOverrides";
  claim: Maybe<EdiReversalOverridesClaim>;
  coordinationOfBenefits: Maybe<EdiReversalOverridesCob>;
  header: Maybe<EdiReversalOverridesHeader>;
  insurance: Maybe<EdiReversalOverridesInsurance>;
  orderInsuranceOptionId: Maybe<Scalars["String"]["output"]>;
  pricing: Maybe<EdiReversalOverridesPricing>;
};

export type EdiReversalOverridesCob = {
  __typename?: "EdiReversalOverridesCOB";
  internalControlNumber: Maybe<StringWithToggle>;
  otherPayerCoverageType: Maybe<StringWithToggle>;
};

export type EdiReversalOverridesCobInput = {
  otherPayerCoverageType?: InputMaybe<StringWithToggleInput>;
};

export type EdiReversalOverridesClaim = {
  __typename?: "EdiReversalOverridesClaim";
  fillNumber: Maybe<NumberWithToggle>;
  otherCoverageCode: Maybe<StringWithToggle>;
  pharmacyServiceType: Maybe<StringWithToggle>;
  prescriptionRefNumber: Maybe<StringWithToggle>;
  productId: Maybe<StringWithToggle>;
};

export type EdiReversalOverridesClaimInput = {
  fillNumber?: InputMaybe<NumberWithToggleInput>;
  otherCoverageCode?: InputMaybe<NumberWithToggleInput>;
  pharmacyServiceType?: InputMaybe<StringWithToggleInput>;
  prescriptionRefNumber?: InputMaybe<StringWithToggleInput>;
  productId?: InputMaybe<StringWithToggleInput>;
};

export type EdiReversalOverridesHeader = {
  __typename?: "EdiReversalOverridesHeader";
  bin: Maybe<StringWithToggle>;
  dateOfService: Maybe<StringWithToggle>;
  processorControlNumber: Maybe<StringWithToggle>;
  serviceProviderId: Maybe<StringWithToggle>;
  softwareVendorId: Maybe<StringWithToggle>;
};

export type EdiReversalOverridesHeaderInput = {
  bin?: InputMaybe<StringWithToggleInput>;
  dateOfService?: InputMaybe<StringWithToggleInput>;
  processorControlNumber?: InputMaybe<StringWithToggleInput>;
  serviceProviderId?: InputMaybe<StringWithToggleInput>;
  softwareVendorId?: InputMaybe<StringWithToggleInput>;
};

export type EdiReversalOverridesInput = {
  claim?: InputMaybe<EdiReversalOverridesClaimInput>;
  coordinationOfBenefits?: InputMaybe<EdiReversalOverridesCobInput>;
  header?: InputMaybe<EdiReversalOverridesHeaderInput>;
  insurance?: InputMaybe<EdiReversalOverridesInsuranceInput>;
  orderInsuranceOptionId?: InputMaybe<Scalars["String"]["input"]>;
  pricing?: InputMaybe<EdiReversalOverridesPricingInput>;
};

export type EdiReversalOverridesInsurance = {
  __typename?: "EdiReversalOverridesInsurance";
  cardholderId: Maybe<StringWithToggle>;
  groupId: Maybe<StringWithToggle>;
  medigapId: Maybe<StringWithToggle>;
};

export type EdiReversalOverridesInsuranceInput = {
  cardholderId?: InputMaybe<StringWithToggleInput>;
  groupId?: InputMaybe<StringWithToggleInput>;
  medigapId?: InputMaybe<StringWithToggleInput>;
};

export type EdiReversalOverridesPricing = {
  __typename?: "EdiReversalOverridesPricing";
  grossAmountDue: Maybe<NumberWithToggle>;
  incentiveAmountSubmitted: Maybe<NumberWithToggle>;
};

export type EdiReversalOverridesPricingInput = {
  grossAmountDue?: InputMaybe<NumberWithToggleInput>;
  incentiveAmountSubmitted?: InputMaybe<NumberWithToggleInput>;
};

export type EligibilityCheck = {
  __typename?: "EligibilityCheck";
  _id: Maybe<Scalars["ID"]["output"]>;
  received: Maybe<EligibilityCheckReceived>;
  sent: Maybe<EligibilityCheckSent>;
};

export type EligibilityCheckHeader = {
  __typename?: "EligibilityCheckHeader";
  bin: Maybe<Scalars["String"]["output"]>;
  dateOfService: Maybe<Scalars["Date"]["output"]>;
  processorControlNumber: Maybe<Scalars["String"]["output"]>;
  serviceProviderId: Maybe<Scalars["String"]["output"]>;
  serviceProviderIdQualifier: Maybe<Scalars["String"]["output"]>;
  softwareVendorId: Maybe<Scalars["String"]["output"]>;
  transactionCode: Maybe<Scalars["String"]["output"]>;
  transactionCount: Maybe<Scalars["Int"]["output"]>;
  version: Maybe<Scalars["String"]["output"]>;
};

export type EligibilityCheckMetaData = {
  __typename?: "EligibilityCheckMetaData";
  insurance: Maybe<EligibilityCheckMetaDataInsurance>;
};

export type EligibilityCheckMetaDataInsurance = {
  __typename?: "EligibilityCheckMetaDataInsurance";
  cardholderId: Maybe<Scalars["String"]["output"]>;
};

export type EligibilityCheckReceived = {
  __typename?: "EligibilityCheckReceived";
  edi: Maybe<Scalars["String"]["output"]>;
  groups: Maybe<Array<Maybe<EligibilityCheckReceivedGroup>>>;
  header: Maybe<EligibilityCheckReceivedHeader>;
  metadata: Maybe<EligibilityCheckReceivedMetadata>;
};

export type EligibilityCheckReceivedGroup = {
  __typename?: "EligibilityCheckReceivedGroup";
  coordinationOfBenefitsResponse: Maybe<EligibilityCheckReceivedGroupCob>;
  response: Maybe<EligibilityCheckReceivedGroupResponse>;
};

export type EligibilityCheckReceivedGroupCob = {
  __typename?: "EligibilityCheckReceivedGroupCOB";
  otherPayerBenefitEffectiveDate: Maybe<Scalars["String"]["output"]>;
  otherPayerBenefitTerminationDate: Maybe<Scalars["String"]["output"]>;
  otherPayerCardholderId: Maybe<Scalars["String"]["output"]>;
  otherPayerCoverageType: Maybe<Scalars["String"]["output"]>;
  otherPayerGroupId: Maybe<Scalars["String"]["output"]>;
  otherPayerHelpDeskPhoneNumber: Maybe<Scalars["String"]["output"]>;
  otherPayerId: Maybe<Scalars["String"]["output"]>;
  otherPayerIdCount: Maybe<Scalars["String"]["output"]>;
  otherPayerIdQualifier: Maybe<Scalars["String"]["output"]>;
  otherPayerPatientRelationshipCode: Maybe<Scalars["String"]["output"]>;
  otherPayerPersonCode: Maybe<Scalars["String"]["output"]>;
  otherPayerProcessorControlNumber: Maybe<Scalars["String"]["output"]>;
};

export type EligibilityCheckReceivedGroupResponse = {
  __typename?: "EligibilityCheckReceivedGroupResponse";
  additionalMessageInformation: Maybe<Scalars["String"]["output"]>;
  authorizationNumber: Maybe<Scalars["String"]["output"]>;
  rejectCodes: Maybe<
    Array<Maybe<EligibilityCheckReceivedGroupResponseRejectCode>>
  >;
  transactionStatus: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type EligibilityCheckReceivedGroupResponseRejectCode = {
  __typename?: "EligibilityCheckReceivedGroupResponseRejectCode";
  _id: Maybe<Scalars["ID"]["output"]>;
  rejectCode: Maybe<Scalars["String"]["output"]>;
  rejectCodeTranslated: Maybe<Scalars["String"]["output"]>;
};

export type EligibilityCheckReceivedHeader = {
  __typename?: "EligibilityCheckReceivedHeader";
  dateOfService: Maybe<Scalars["String"]["output"]>;
  responseStatus: Maybe<Scalars["String"]["output"]>;
  serviceProviderId: Maybe<Scalars["String"]["output"]>;
  serviceProviderIdQualifier: Maybe<Scalars["String"]["output"]>;
  transactionCode: Maybe<Scalars["String"]["output"]>;
  transactionCount: Maybe<Scalars["Int"]["output"]>;
  version: Maybe<Scalars["String"]["output"]>;
};

export type EligibilityCheckReceivedMetadata = {
  __typename?: "EligibilityCheckReceivedMetadata";
  responseMessage: Maybe<EligibilityCheckReceivedMetadataResponseMessage>;
};

export type EligibilityCheckReceivedMetadataResponseMessage = {
  __typename?: "EligibilityCheckReceivedMetadataResponseMessage";
  message: Maybe<Scalars["String"]["output"]>;
};

export type EligibilityCheckSent = {
  __typename?: "EligibilityCheckSent";
  edi: Maybe<Scalars["String"]["output"]>;
  header: Maybe<EligibilityCheckHeader>;
  metadata: Maybe<EligibilityCheckMetaData>;
};

export type EndSession = {
  __typename?: "EndSession";
  succesfull: Maybe<Scalars["Boolean"]["output"]>;
};

export type Escript = {
  __typename?: "Escript";
  _id: Maybe<Scalars["ID"]["output"]>;
  allergyOrAdverseEvent: Maybe<AllergyRestrictedChoice>;
  benefitsCoordination: Maybe<BenefitsCoordinationRestricted>;
  changeOfPrescriptionStatusFlag: Maybe<Scalars["String"]["output"]>;
  changeReasonText: Maybe<Scalars["String"]["output"]>;
  clinicName: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customerId: Maybe<Scalars["ID"]["output"]>;
  diagnosisGeneral: Maybe<DiagnosisGeneral>;
  dispensingRequestCode: Maybe<Scalars["String"]["output"]>;
  drugAdministrationRequest: Maybe<DrugAdministrationRequest>;
  externalId: Maybe<Scalars["String"]["output"]>;
  facility: Maybe<Facility>;
  fillStatus: Maybe<EscriptFillStatus>;
  followUpPrescriber: Maybe<FollowUpPrescriber>;
  followUpRequest: Maybe<Scalars["Int"]["output"]>;
  inTriage: Maybe<Scalars["Boolean"]["output"]>;
  medicationDispensed: Maybe<PrescribedMedication>;
  medicationPrescribed: Maybe<NewRxPrescribedMedication>;
  medicationRequested: Maybe<PrescribedMedication>;
  messageId: Maybe<Scalars["String"]["output"]>;
  messageRequestCode: Maybe<Scalars["String"]["output"]>;
  messageType: Maybe<Scalars["String"]["output"]>;
  ndc: Maybe<Scalars["String"]["output"]>;
  observation: Maybe<Observation>;
  originator: Maybe<Scalars["String"]["output"]>;
  patient: Maybe<PatientMandatoryAddress>;
  pharmacy: Maybe<MandatoryPharmacy>;
  pioneerId: Maybe<Scalars["String"]["output"]>;
  pioneerMessageId: Maybe<Scalars["String"]["output"]>;
  prescriber: Maybe<MandatoryPrescriber>;
  prescriberOrderNumber: Maybe<Scalars["String"]["output"]>;
  prescription: Maybe<Prescription>;
  prescriptionId: Maybe<Scalars["ID"]["output"]>;
  prohibitRenewalRequest: Maybe<Scalars["String"]["output"]>;
  raw: Maybe<Scalars["String"]["output"]>;
  receivedAt: Maybe<Scalars["Date"]["output"]>;
  requestReferenceNumber: Maybe<Scalars["String"]["output"]>;
  requestedDates: Maybe<RequestedDates>;
  rerouted: Maybe<Scalars["Boolean"]["output"]>;
  returnReceipt: Maybe<Scalars["String"]["output"]>;
  rx: Maybe<Scalars["ID"]["output"]>;
  sender: Maybe<Scalars["String"]["output"]>;
  sentTime: Maybe<Scalars["Date"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  supervisor: Maybe<SupervisorMandatoryAddress>;
  triageReason: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
  urgencyIndicatorCode: Maybe<Scalars["String"]["output"]>;
  validationIssues: Maybe<Array<Maybe<ValidationIssue>>>;
};

export type EscriptAddress = {
  __typename?: "EscriptAddress";
  addressLine1: Maybe<Scalars["String"]["output"]>;
  addressLine2: Maybe<Scalars["String"]["output"]>;
  city: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
  zip: Maybe<Scalars["String"]["output"]>;
};

export type EscriptCommunication = {
  __typename?: "EscriptCommunication";
  email: Maybe<Scalars["String"]["output"]>;
  fax: Maybe<Scalars["String"]["output"]>;
  primary: Maybe<Scalars["String"]["output"]>;
};

export type EscriptDrugCoded = {
  __typename?: "EscriptDrugCoded";
  dbCode: Maybe<Scalars["String"]["output"]>;
  dbCodeQualifier: Maybe<Scalars["String"]["output"]>;
  productCode: Maybe<Scalars["String"]["output"]>;
  productCodeQualifier: Maybe<Scalars["String"]["output"]>;
  strength: Maybe<Scalars["String"]["output"]>;
  strengthForm: Maybe<Scalars["String"]["output"]>;
  strengthUnitOfMeasure: Maybe<Scalars["String"]["output"]>;
};

export type EscriptDrugDbCode = {
  __typename?: "EscriptDrugDBCode";
  code: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
};

export type EscriptFillStatus = {
  __typename?: "EscriptFillStatus";
  Dispensed: Maybe<NoteTypeWithReasonCode18>;
  NotDispensed: Maybe<NoteTypeWithReasonCode>;
  PartiallyDispensed: Maybe<NoteTypeWithReasonCode21>;
  Transferred: Maybe<NoteType>;
};

export type EscriptFormDataInput = {
  medicationPrescribed?: InputMaybe<MedicationPrescribedInput>;
};

export type EscriptHeader = {
  __typename?: "EscriptHeader";
  from: Maybe<Scalars["String"]["output"]>;
  messageId: Maybe<Scalars["String"]["output"]>;
  prescriberOrderNumber: Maybe<Scalars["String"]["output"]>;
  senderSoftware: Maybe<EscriptSenderSoftware>;
  sent: Maybe<Scalars["String"]["output"]>;
  to: Maybe<Scalars["String"]["output"]>;
};

export type EscriptMedicationPrescribed = {
  __typename?: "EscriptMedicationPrescribed";
  daw: Maybe<Scalars["Int"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  directions: Maybe<Scalars["String"]["output"]>;
  drugCoded: Maybe<EscriptDrugCoded>;
  effectiveDate: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  ndc: Maybe<Scalars["String"]["output"]>;
  note: Maybe<Scalars["String"]["output"]>;
  other: Maybe<EscriptPrescribedOther>;
  otherMedicationDate: Maybe<Array<Maybe<EscriptOtherMedicationDate>>>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  refills: Maybe<EscriptRefills>;
  writtenDate: Maybe<Scalars["String"]["output"]>;
};

export type EscriptMedicationSearchResult = {
  __typename?: "EscriptMedicationSearchResult";
  name: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
};

export type EscriptMessage = {
  __typename?: "EscriptMessage";
  header: EscriptHeader;
  medicationPrescribed: EscriptMedicationPrescribed;
  patient: EscriptPatient;
  pharmacy: EscriptPharmacy;
  prescriber: EscriptPrescriber;
};

export type EscriptMessages = {
  __typename?: "EscriptMessages";
  messages: Array<Maybe<EscriptMessage>>;
};

export type EscriptOtherMedicationDate = {
  __typename?: "EscriptOtherMedicationDate";
  otherMedicationDate: Maybe<EscriptOtherMedicationDateOtherMedicationDate>;
  otherMedicationDateQualifier: Maybe<Scalars["String"]["output"]>;
};

export type EscriptOtherMedicationDateOtherMedicationDate = {
  __typename?: "EscriptOtherMedicationDateOtherMedicationDate";
  dateTime: Maybe<Scalars["Date"]["output"]>;
};

export type EscriptPatient = {
  __typename?: "EscriptPatient";
  address: Maybe<EscriptAddress>;
  contactInformation: Maybe<EscriptCommunication>;
  dob: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  gender: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
};

export type EscriptPharmacy = {
  __typename?: "EscriptPharmacy";
  Address: Maybe<EscriptAddress>;
  contactInformation: Maybe<EscriptCommunication>;
  name: Maybe<Scalars["String"]["output"]>;
  ncpdpid: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
};

export type EscriptPrescribedOther = {
  __typename?: "EscriptPrescribedOther";
  code: Maybe<EscriptDrugCoded>;
  quantity: Maybe<Scalars["Float"]["output"]>;
};

export type EscriptPrescriber = {
  __typename?: "EscriptPrescriber";
  _id: Maybe<Scalars["String"]["output"]>;
  address: Maybe<EscriptAddress>;
  clinicName: Maybe<Scalars["String"]["output"]>;
  contactInformation: Maybe<EscriptCommunication>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
};

export type EscriptProductCode = {
  __typename?: "EscriptProductCode";
  code: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
};

export type EscriptQuantityType = {
  value?: InputMaybe<Scalars["Float"]["input"]>;
};

export type EscriptRefills = {
  __typename?: "EscriptRefills";
  qualifier: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Int"]["output"]>;
};

export type EscriptSenderSoftware = {
  __typename?: "EscriptSenderSoftware";
  developer: Maybe<Scalars["String"]["output"]>;
  product: Maybe<Scalars["String"]["output"]>;
  versionRelease: Maybe<Scalars["String"]["output"]>;
};

export type EscriptStrength = {
  __typename?: "EscriptStrength";
  strengthForm: Maybe<NciCode>;
  strengthUnitOfMeasure: Maybe<NciCode>;
  strengthValue: Maybe<Scalars["String"]["output"]>;
};

export type EscriptValidationIssueTypes = {
  __typename?: "EscriptValidationIssueTypes";
  _id: Maybe<Scalars["ID"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  identifier: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  priority: Maybe<Scalars["Int"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type EscriptWithPaging = {
  __typename?: "EscriptWithPaging";
  currentPage: Maybe<Scalars["Int"]["output"]>;
  escripts: Maybe<Array<Maybe<Escript>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type ExceptionNdc = {
  __typename?: "ExceptionNDC";
  medicationName: Maybe<Scalars["String"]["output"]>;
  multiplier: Maybe<Scalars["Int"]["output"]>;
  ndc: Scalars["String"]["output"];
};

export type ExceptionNdcInput = {
  medicationName?: InputMaybe<Scalars["String"]["input"]>;
  multiplier?: InputMaybe<Scalars["Float"]["input"]>;
  ndc: Scalars["String"]["input"];
};

export type ExpectedLots = {
  __typename?: "ExpectedLots";
  expectedLots: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type ExternalLocation = {
  __typename?: "ExternalLocation";
  address: ExternalLocationAddress;
  id: Scalars["String"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  npi: Scalars["String"]["output"];
};

export type ExternalLocationAddress = {
  __typename?: "ExternalLocationAddress";
  city: Scalars["String"]["output"];
  fax: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  street1: Scalars["String"]["output"];
  street2: Maybe<Scalars["String"]["output"]>;
  zip: Scalars["String"]["output"];
};

export type ExternalTransfer = ExternalTransferBase & {
  __typename?: "ExternalTransfer";
  createdAt: Scalars["Date"]["output"];
  externalLocation: ExternalLocation;
  id: Scalars["String"]["output"];
  locked: Maybe<Scalars["Boolean"]["output"]>;
  lockedAt: Maybe<Scalars["Date"]["output"]>;
  lockedBy: Maybe<Scalars["String"]["output"]>;
  prescription: PrescriptionX;
  status: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
};

export type ExternalTransferBase = {
  createdAt: Scalars["Date"]["output"];
  externalLocation: ExternalLocation;
  id: Scalars["String"]["output"];
  locked: Maybe<Scalars["Boolean"]["output"]>;
  lockedAt: Maybe<Scalars["Date"]["output"]>;
  lockedBy: Maybe<Scalars["String"]["output"]>;
  prescription: PrescriptionX;
  status: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
};

export type ExternalTransferQueueItem = ExternalTransferBase & {
  __typename?: "ExternalTransferQueueItem";
  createdAt: Scalars["Date"]["output"];
  customer: Maybe<CustomerX>;
  externalLocation: ExternalLocation;
  id: Scalars["String"]["output"];
  locked: Maybe<Scalars["Boolean"]["output"]>;
  lockedAt: Maybe<Scalars["Date"]["output"]>;
  lockedBy: Maybe<Scalars["String"]["output"]>;
  patient: Maybe<PatientX>;
  prescription: PrescriptionX;
  status: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
};

export type ExternalTransferResponse = {
  __typename?: "ExternalTransferResponse";
  externalTransfer: Maybe<ExternalTransfer>;
  tposRx: Maybe<Prescription>;
  tposRxLocation: Maybe<Location>;
};

export type ExternalTransfersWithPaging = {
  __typename?: "ExternalTransfersWithPaging";
  externalTransfers: Maybe<Array<Maybe<ExternalTransferQueueItem>>>;
  nextPageToken: Maybe<Scalars["String"]["output"]>;
};

export type Facility = {
  __typename?: "Facility";
  address: Maybe<Address>;
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  facilityName: Maybe<Scalars["String"]["output"]>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  remsHealthcareSettingEnrollmentID: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type FacilityIdType = {
  __typename?: "FacilityIDType";
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  remsHealthcareSettingEnrollmentID: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type FacilitySpecificHoursOfAdministrationTiming = {
  __typename?: "FacilitySpecificHoursOfAdministrationTiming";
  HoursOfAdministration: Maybe<TitrationSigCode>;
  HoursOfAdministrationValue: Maybe<Scalars["Int"]["output"]>;
};

export type FaxExternalTransferResponse = {
  __typename?: "FaxExternalTransferResponse";
  faxId: Maybe<Scalars["String"]["output"]>;
  prescriptionId: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export type FaxRenewalResponse = {
  __typename?: "FaxRenewalResponse";
  faxId: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
};

export type FetchOrderInvoiceResponse = {
  __typename?: "FetchOrderInvoiceResponse";
  invoice: Maybe<Scalars["String"]["output"]>;
};

export type FfsInventoryInfo = {
  __typename?: "FfsInventoryInfo";
  expectedLots: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  externalSystemId: Maybe<Scalars["String"]["output"]>;
  externalSystemName: Maybe<Scalars["String"]["output"]>;
  inventoryId: Maybe<Scalars["String"]["output"]>;
  ownerCustomerId: Maybe<Scalars["String"]["output"]>;
  ownerId: Maybe<Scalars["String"]["output"]>;
  ownerLegacyId: Maybe<Scalars["Int"]["output"]>;
  ownerName: Maybe<Scalars["String"]["output"]>;
  warehouseLocation: Maybe<Scalars["String"]["output"]>;
};

export type FfsInventoryInfoInput = {
  expectedLots?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSystemId?: InputMaybe<Scalars["String"]["input"]>;
  externalSystemName?: InputMaybe<Scalars["String"]["input"]>;
  inventoryId?: InputMaybe<Scalars["String"]["input"]>;
  ownerCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  ownerId?: InputMaybe<Scalars["String"]["input"]>;
  ownerLegacyId?: InputMaybe<Scalars["Int"]["input"]>;
  ownerName?: InputMaybe<Scalars["String"]["input"]>;
  warehouseLocation?: InputMaybe<Scalars["String"]["input"]>;
};

export type Fill = {
  __typename?: "Fill";
  _id: Maybe<Scalars["ID"]["output"]>;
  claimSummary: Maybe<ClaimSummary>;
  claims: Maybe<Array<Maybe<Claims>>>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  dispensed: Maybe<DispensedMedication>;
  durScreenings: Maybe<Array<Maybe<DurRecord>>>;
  ediPricing: Maybe<FillEdiPricing>;
  fillNumber: Maybe<Scalars["Int"]["output"]>;
  genericFor: Maybe<Scalars["String"]["output"]>;
  gpi: Maybe<Scalars["String"]["output"]>;
  handlingTags: Maybe<HandlingTags>;
  inTriage: Maybe<Scalars["Boolean"]["output"]>;
  isPartialComplete: Maybe<Scalars["Boolean"]["output"]>;
  isPartialFill: Maybe<Scalars["Boolean"]["output"]>;
  isPioneerHistory: Maybe<Scalars["Boolean"]["output"]>;
  isReplacement: Maybe<Scalars["Boolean"]["output"]>;
  labelsPrinted: Maybe<Scalars["Int"]["output"]>;
  lastPrintedAt: Maybe<Scalars["String"]["output"]>;
  location: Maybe<Location>;
  locationId: Maybe<Scalars["ID"]["output"]>;
  patientConsent: Maybe<PatientConsent>;
  patientConsents: Maybe<Array<Maybe<PatientConsent>>>;
  prescribed: Maybe<FillPrescribed>;
  printHistory: Maybe<Array<Maybe<PrintHistory>>>;
  replacementFills: Maybe<Scalars["Int"]["output"]>;
  reversalDetails: Maybe<ReversalDetails>;
  rxFillCode: Maybe<Scalars["String"]["output"]>;
  shortFillCode: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  transfer: Maybe<FillTransfer>;
  transferImage: Maybe<Document>;
  triages: Maybe<Array<Maybe<Triage>>>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
  verifications: Maybe<Verifications>;
};

export type FillDispensed = {
  daysSupply?: InputMaybe<Scalars["Int"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  ffsInventoryInfo?: InputMaybe<FfsInventoryInfoInput>;
  formularyResult?: InputMaybe<FormularyResult>;
  inventoryGroupId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  sigCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type FillEdiPricing = {
  __typename?: "FillEdiPricing";
  basisOfCostDetermination: Maybe<Scalars["String"]["output"]>;
  dispensingFeeSubmitted: Maybe<Scalars["Float"]["output"]>;
  grossAmountDue: Maybe<Scalars["Float"]["output"]>;
  ingredientCostSubmitted: Maybe<Scalars["Float"]["output"]>;
  usualAndCustomaryCharge: Maybe<Scalars["Float"]["output"]>;
};

export type FillInput = {
  _id?: InputMaybe<Scalars["ID"]["input"]>;
  dispensed?: InputMaybe<MedicationInput>;
  fillNumber?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  triage?: InputMaybe<Scalars["String"]["input"]>;
};

export type FillLabelPrinted = {
  __typename?: "FillLabelPrinted";
  date: Maybe<Scalars["String"]["output"]>;
  isReprint: Maybe<Scalars["Boolean"]["output"]>;
  reason: Maybe<Scalars["String"]["output"]>;
  userId: Maybe<Scalars["ID"]["output"]>;
};

export type FillPrescribed = {
  __typename?: "FillPrescribed";
  daw: Maybe<Scalars["Int"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  directions: Maybe<Scalars["String"]["output"]>;
  expirationDate: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  ndc: Maybe<Scalars["String"]["output"]>;
  numberOfRefills: Maybe<Scalars["Int"]["output"]>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  quantityRemaining: Maybe<Scalars["Float"]["output"]>;
  refillsRemaining: Maybe<Scalars["Int"]["output"]>;
  totalQuantity: Maybe<Scalars["Float"]["output"]>;
  writtenDate: Maybe<Scalars["String"]["output"]>;
};

export type FillTransfer = {
  __typename?: "FillTransfer";
  fromLocationId: Maybe<Scalars["String"]["output"]>;
  imageId: Maybe<Scalars["ID"]["output"]>;
  pharmacistInChargeId: Maybe<Scalars["ID"]["output"]>;
  receivingPharmacistId: Maybe<Scalars["ID"]["output"]>;
  toLocationId: Maybe<Scalars["String"]["output"]>;
  transferredAt: Maybe<Scalars["Date"]["output"]>;
  transferredByUserId: Maybe<Scalars["ID"]["output"]>;
  transferredTo: Maybe<Scalars["String"]["output"]>;
};

export type FillWithOrderId = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type FixedLengthSuspension = {
  __typename?: "FixedLengthSuspension";
  resumeDateTime: Maybe<Scalars["Date"]["output"]>;
  suspendDateTime: Maybe<Scalars["Date"]["output"]>;
  timeZone: Maybe<TimeZone>;
};

export type FollowUpPrescriber = {
  __typename?: "FollowUpPrescriber";
  Address: Maybe<Address>;
  CommunicationNumbers: Maybe<CommunicationNumbers>;
  FormerName: Maybe<Name>;
  Name: Maybe<Name>;
  PracticeLocation: Maybe<PracticeLocationType>;
  Specialty: Maybe<Scalars["String"]["output"]>;
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  remsHealthcareProviderEnrollmentID: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  stateControlSubstanceNumber: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type FormularyResult = {
  acquisitionCost?: InputMaybe<Scalars["Float"]["input"]>;
  consigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  onFormulary?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferred?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GetPreferredSubstitutionResponse = {
  __typename?: "GetPreferredSubstitutionResponse";
  ndcSubstitution: Maybe<NdcSubstitutionPackagePreferred>;
  onFormulary: Maybe<Scalars["Boolean"]["output"]>;
};

export type GetPrescriptionWarnings = {
  __typename?: "GetPrescriptionWarnings";
  prescriptionId: Scalars["String"]["output"];
  type: Maybe<Scalars["String"]["output"]>;
};

export type GroupedNdcInfo = {
  __typename?: "GroupedNdcInfo";
  dosageForm: Maybe<Scalars["String"]["output"]>;
  drugName: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  strength: Maybe<Scalars["String"]["output"]>;
  strengthUnitOfMeasure: Maybe<Scalars["String"]["output"]>;
};

export type GuestPatient = {
  __typename?: "GuestPatient";
  _id: Scalars["ID"]["output"];
  contacts: Maybe<GuestPatientContacts>;
  firstName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
};

export type GuestPatientContacts = {
  __typename?: "GuestPatientContacts";
  email: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type HandlingTags = {
  __typename?: "HandlingTags";
  deaScheduleNum: Maybe<Scalars["String"]["output"]>;
  isColdChain: Maybe<Scalars["Boolean"]["output"]>;
  isControlledSubstance: Maybe<Scalars["Boolean"]["output"]>;
  isExpensive: Maybe<Scalars["Boolean"]["output"]>;
  isSpecialty: Maybe<Scalars["Boolean"]["output"]>;
};

export type HealthProfile = {
  __typename?: "HealthProfile";
  allergies: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  dob: Maybe<Scalars["String"]["output"]>;
  ethnicity: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  healthConditions: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  medications: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  patientToken: Maybe<Scalars["String"]["output"]>;
  race: Maybe<Scalars["String"]["output"]>;
  sex: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type HistorySourceId = {
  __typename?: "HistorySourceID";
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type HistorySourceWithoutPayment = {
  __typename?: "HistorySourceWithoutPayment";
  fillNumber: Maybe<Scalars["String"]["output"]>;
  paymentType: Maybe<Scalars["String"]["output"]>;
  source: Maybe<SourceQualifier>;
  sourceReference: Maybe<Scalars["String"]["output"]>;
};

export type Icd10 = {
  __typename?: "ICD10";
  _id: Scalars["ID"]["output"];
  code: Scalars["String"]["output"];
  decimal: Scalars["String"]["output"];
  main: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type Icd10Input = {
  _id: Scalars["ID"]["input"];
};

export type IvAccessCatheterTip = {
  __typename?: "IVAccessCatheterTip";
  ivAccessCatheterTipDescription: Maybe<Scalars["String"]["output"]>;
  ivAccessCatheterTipType: Maybe<SnomedCode>;
};

export type IvAccessDeviceType = {
  __typename?: "IVAccessDeviceType";
  ivAccessDeviceType: Maybe<SnomedCode>;
  ivAccessDeviceTypeDescription: Maybe<Scalars["String"]["output"]>;
};

export type IvAdministrationType = {
  __typename?: "IVAdministrationType";
  diluentAmount: Maybe<QuantityWithoutSecondQuantity>;
  ivAccessCatheterTip: Maybe<IvAccessCatheterTip>;
  ivAccessDeviceType: Maybe<IvAccessDeviceType>;
  ivAccessType: Maybe<SnomedCode>;
  ivInfusion: Maybe<IvInfusion>;
  numberOfLumens: Maybe<Scalars["String"]["output"]>;
  specificAdministrationBrand: Maybe<Scalars["String"]["output"]>;
  specificAdministrationGauge: Maybe<Scalars["Int"]["output"]>;
  specificAdministrationLength: Maybe<Scalars["String"]["output"]>;
  specificAdministrationPump: Maybe<Scalars["Boolean"]["output"]>;
};

export type IvInfusion = {
  __typename?: "IVInfusion";
  ivInfusionDescription: Maybe<Scalars["String"]["output"]>;
  ivInfusionType: Maybe<SnomedCode>;
};

export type InTriage = {
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  inTriageUserMessage?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  reason: Scalars["String"]["input"];
  rxChange?: InputMaybe<RxChangeTriage>;
};

export type IndefiniteSuspension = {
  __typename?: "IndefiniteSuspension";
  suspendDateTime: Maybe<Scalars["Date"]["output"]>;
  timeZone: Maybe<TimeZone>;
};

export type Insurance = {
  __typename?: "Insurance";
  _id: Maybe<Scalars["String"]["output"]>;
  bin: Maybe<Scalars["String"]["output"]>;
  cardholderId: Maybe<Scalars["String"]["output"]>;
  coreInsuranceToken: Maybe<Scalars["String"]["output"]>;
  designation: Maybe<Scalars["String"]["output"]>;
  governmentSponsoredPlan: Maybe<Scalars["Boolean"]["output"]>;
  group: Maybe<Scalars["String"]["output"]>;
  helpdeskNumber: Maybe<Scalars["String"]["output"]>;
  images: Maybe<InsuranceImages>;
  name: Maybe<Scalars["String"]["output"]>;
  pcn: Maybe<Scalars["String"]["output"]>;
  personCode: Maybe<Scalars["String"]["output"]>;
  relationship: Maybe<Scalars["String"]["output"]>;
};

export type InsuranceImages = {
  __typename?: "InsuranceImages";
  back: Maybe<Scalars["String"]["output"]>;
  front: Maybe<Scalars["String"]["output"]>;
};

export type InsuranceImagesInput = {
  back?: InputMaybe<Scalars["String"]["input"]>;
  front?: InputMaybe<Scalars["String"]["input"]>;
};

export type InsuranceInput = {
  _id?: InputMaybe<Scalars["String"]["input"]>;
  bin?: InputMaybe<Scalars["String"]["input"]>;
  cardholderId?: InputMaybe<Scalars["String"]["input"]>;
  coreInsuranceToken?: InputMaybe<Scalars["String"]["input"]>;
  designation?: InputMaybe<Scalars["String"]["input"]>;
  governmentSponsoredPlan?: InputMaybe<Scalars["Boolean"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  helpdeskNumber?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<InsuranceImagesInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  patientInsuranceId?: InputMaybe<Scalars["String"]["input"]>;
  pcn?: InputMaybe<Scalars["String"]["input"]>;
  personCode?: InputMaybe<Scalars["String"]["input"]>;
  relationship?: InputMaybe<Scalars["String"]["input"]>;
};

export type InsuranceProvider = {
  __typename?: "InsuranceProvider";
  _id: Scalars["ID"]["output"];
  address: Maybe<Address>;
  billingType: Maybe<Scalars["String"]["output"]>;
  bin: Scalars["String"]["output"];
  code: Maybe<Scalars["String"]["output"]>;
  configurations: Maybe<InsuranceProviderConfiguration>;
  contractExpiration: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  faxNumber: Maybe<Scalars["String"]["output"]>;
  helpdeskNumber: Maybe<Scalars["String"]["output"]>;
  importDate: Maybe<Scalars["String"]["output"]>;
  marketCoverage: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  pcn: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  planType: Maybe<Scalars["String"]["output"]>;
  priorAuthorizationNumber: Maybe<Scalars["String"]["output"]>;
  sourceName: Maybe<Scalars["String"]["output"]>;
  stateCode: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  submissionType: Maybe<Scalars["String"]["output"]>;
  website: Maybe<Scalars["String"]["output"]>;
};

export type InsuranceProviderConfiguration = {
  __typename?: "InsuranceProviderConfiguration";
  minimumProfitRejection: Maybe<InsuranceProviderConfigurationMinimumProfitRejection>;
  options: Maybe<InsuranceProviderConfigurationOptions>;
  pricing: Maybe<InsuranceProviderConfigurationPricing>;
  reconciliation: Maybe<InsuranceProviderConfigurationReconciliation>;
};

export type InsuranceProviderConfigurationInput = {
  minimumProfitRejection?: InputMaybe<InsuranceProviderConfigurationMinimumProfitRejectionInput>;
  options?: InputMaybe<InsuranceProviderConfigurationOptionsInput>;
  pricing?: InputMaybe<InsuranceProviderConfigurationPricingInput>;
  reconciliation?: InputMaybe<InsuranceProviderConfigurationReconciliationInput>;
};

export type InsuranceProviderConfigurationMinimumProfitRejection = {
  __typename?: "InsuranceProviderConfigurationMinimumProfitRejection";
  amountDueOverCost: Maybe<Scalars["Float"]["output"]>;
  percentOverCost: Maybe<Scalars["Float"]["output"]>;
  percentSubmitted: Maybe<Scalars["Float"]["output"]>;
};

export type InsuranceProviderConfigurationMinimumProfitRejectionInput = {
  amountDueOverCost?: InputMaybe<Scalars["Float"]["input"]>;
  percentOverCost?: InputMaybe<Scalars["Float"]["input"]>;
  percentSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
};

export type InsuranceProviderConfigurationOptions = {
  __typename?: "InsuranceProviderConfigurationOptions";
  allowPartialFills: Maybe<Scalars["Boolean"]["output"]>;
  allowPartialFillsCII: Maybe<Scalars["Boolean"]["output"]>;
  allowPartialFillsCIII: Maybe<Scalars["Boolean"]["output"]>;
  allowPartialFillsCIV: Maybe<Scalars["Boolean"]["output"]>;
  allowPartialFillsCV: Maybe<Scalars["Boolean"]["output"]>;
  b3: Maybe<Scalars["String"]["output"]>;
  cIIExpiration: Maybe<Scalars["Int"]["output"]>;
  copayMethod: Maybe<Scalars["String"]["output"]>;
  maxCIIIRefills: Maybe<Scalars["Int"]["output"]>;
  maxCIVRefills: Maybe<Scalars["Int"]["output"]>;
  maxCVRefills: Maybe<Scalars["Int"]["output"]>;
  maxClaimsPerTransmit: Maybe<Scalars["Int"]["output"]>;
  maxDaySupply: Maybe<Scalars["Int"]["output"]>;
  maxMonthlyAmount: Maybe<Scalars["Float"]["output"]>;
  maxMonthlyFills: Maybe<Scalars["Int"]["output"]>;
  maxRxPrice: Maybe<Scalars["Float"]["output"]>;
  maxRxRefills: Maybe<Scalars["Int"]["output"]>;
  minRxPrice: Maybe<Scalars["Float"]["output"]>;
  otherCExpiration: Maybe<Scalars["Int"]["output"]>;
  refillsDue: Maybe<Scalars["Int"]["output"]>;
  rxExpiration: Maybe<Scalars["Int"]["output"]>;
  taxMethod: Maybe<Scalars["String"]["output"]>;
};

export type InsuranceProviderConfigurationOptionsInput = {
  allowPartialFills?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowPartialFillsCII?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowPartialFillsCIII?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowPartialFillsCIV?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowPartialFillsCV?: InputMaybe<Scalars["Boolean"]["input"]>;
  b3?: InputMaybe<Scalars["String"]["input"]>;
  cIIExpiration?: InputMaybe<Scalars["Int"]["input"]>;
  copayMethod?: InputMaybe<Scalars["String"]["input"]>;
  maxCIIIRefills?: InputMaybe<Scalars["Int"]["input"]>;
  maxCIVRefills?: InputMaybe<Scalars["Int"]["input"]>;
  maxCVRefills?: InputMaybe<Scalars["Int"]["input"]>;
  maxClaimsPerTransmit?: InputMaybe<Scalars["Int"]["input"]>;
  maxDaySupply?: InputMaybe<Scalars["Int"]["input"]>;
  maxMonthlyAmount?: InputMaybe<Scalars["Float"]["input"]>;
  maxMonthlyFills?: InputMaybe<Scalars["Int"]["input"]>;
  maxRxPrice?: InputMaybe<Scalars["Float"]["input"]>;
  maxRxRefills?: InputMaybe<Scalars["Int"]["input"]>;
  minRxPrice?: InputMaybe<Scalars["Float"]["input"]>;
  otherCExpiration?: InputMaybe<Scalars["Int"]["input"]>;
  refillsDue?: InputMaybe<Scalars["Int"]["input"]>;
  rxExpiration?: InputMaybe<Scalars["Int"]["input"]>;
  taxMethod?: InputMaybe<Scalars["String"]["input"]>;
};

export type InsuranceProviderConfigurationPricing = {
  __typename?: "InsuranceProviderConfigurationPricing";
  brandDiscount: Maybe<Scalars["Float"]["output"]>;
  brandMaxDiscount: Maybe<Scalars["Float"]["output"]>;
  claimProcessingFee: Maybe<Scalars["Float"]["output"]>;
  compoundDiscount: Maybe<Scalars["Float"]["output"]>;
  compoundMaxDiscount: Maybe<Scalars["Float"]["output"]>;
  genericDiscount: Maybe<Scalars["Float"]["output"]>;
  genericMaxDiscount: Maybe<Scalars["Float"]["output"]>;
};

export type InsuranceProviderConfigurationPricingInput = {
  brandDiscount?: InputMaybe<Scalars["Float"]["input"]>;
  brandMaxDiscount?: InputMaybe<Scalars["Float"]["input"]>;
  claimProcessingFee?: InputMaybe<Scalars["Float"]["input"]>;
  compoundDiscount?: InputMaybe<Scalars["Float"]["input"]>;
  compoundMaxDiscount?: InputMaybe<Scalars["Float"]["input"]>;
  genericDiscount?: InputMaybe<Scalars["Float"]["input"]>;
  genericMaxDiscount?: InputMaybe<Scalars["Float"]["input"]>;
};

export type InsuranceProviderConfigurationReconciliation = {
  __typename?: "InsuranceProviderConfigurationReconciliation";
  automaticallyReconcileClaims: Maybe<Scalars["Boolean"]["output"]>;
  billNoCostSecondaryClaims: Maybe<Scalars["Boolean"]["output"]>;
  paidClaimFee: Maybe<Scalars["Float"]["output"]>;
  reversalClaimFee: Maybe<Scalars["Float"]["output"]>;
  rxFee: Maybe<Scalars["Float"]["output"]>;
  transactionFee: Maybe<Scalars["Float"]["output"]>;
};

export type InsuranceProviderConfigurationReconciliationInput = {
  automaticallyReconcileClaims?: InputMaybe<Scalars["Boolean"]["input"]>;
  billNoCostSecondaryClaims?: InputMaybe<Scalars["Boolean"]["input"]>;
  paidClaimFee?: InputMaybe<Scalars["Float"]["input"]>;
  reversalClaimFee?: InputMaybe<Scalars["Float"]["input"]>;
  rxFee?: InputMaybe<Scalars["Float"]["input"]>;
  transactionFee?: InputMaybe<Scalars["Float"]["input"]>;
};

export type InsuranceProviderInput = {
  address?: InputMaybe<AddressInput>;
  billingType?: InputMaybe<Scalars["String"]["input"]>;
  bin?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  configurations?: InputMaybe<InsuranceProviderConfigurationInput>;
  contractExpiration?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  faxNumber?: InputMaybe<Scalars["String"]["input"]>;
  helpdeskNumber?: InputMaybe<Scalars["String"]["input"]>;
  importDate?: InputMaybe<Scalars["String"]["input"]>;
  marketCoverage?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pcn?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  planType?: InputMaybe<Scalars["String"]["input"]>;
  priorAuthorizationNumber?: InputMaybe<Scalars["String"]["input"]>;
  sourceName?: InputMaybe<Scalars["String"]["input"]>;
  stateCode?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  submissionType?: InputMaybe<Scalars["String"]["input"]>;
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type InsurenceProviderWithPagination = {
  __typename?: "InsurenceProviderWithPagination";
  currentPage: Maybe<Scalars["Int"]["output"]>;
  paginatedResult: Maybe<Array<Maybe<InsuranceProvider>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type IntegerArrayOverride = {
  __typename?: "IntegerArrayOverride";
  applyTo: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
};

export type IntegerArrayOverrideInput = {
  applyTo?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type IntegerOverride = {
  __typename?: "IntegerOverride";
  applyTo: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Int"]["output"]>;
};

export type IntegerOverrideInput = {
  applyTo?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InventoryAdjustment = {
  __typename?: "InventoryAdjustment";
  deductBalance: Scalars["Float"]["output"];
  locationId: Scalars["ID"]["output"];
  ndc: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
};

export type InventoryGroup = {
  __typename?: "InventoryGroup";
  _id: Scalars["ID"]["output"];
  active: Maybe<Scalars["Boolean"]["output"]>;
  customer: Maybe<Customer>;
  customerId: Scalars["ID"]["output"];
  pioneerAlias: Scalars["String"]["output"];
};

export type InventoryPricing = {
  __typename?: "InventoryPricing";
  awp: Maybe<Scalars["Int"]["output"]>;
  cost: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  wac: Maybe<Scalars["Int"]["output"]>;
};

export type IsReAttemptType = {
  __typename?: "IsReAttemptType";
  message: Maybe<Scalars["String"]["output"]>;
  previousStatus: Maybe<Scalars["String"]["output"]>;
  reason: Maybe<Scalars["String"]["output"]>;
};

export type JwtToken = {
  __typename?: "JWTToken";
  forcePasswordReset: Maybe<Scalars["Boolean"]["output"]>;
  token: Scalars["String"]["output"];
};

export type JanusResultsPartial = {
  __typename?: "JanusResultsPartial";
  formulary_status: Scalars["Boolean"]["output"];
  results: Array<Maybe<NdcResultPartial>>;
};

export type LabelSize = {
  __typename?: "LabelSize";
  height: Maybe<Scalars["Float"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type LabelSizeInput = {
  height?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type LastEquivalentDrugDispensedInfo = {
  __typename?: "LastEquivalentDrugDispensedInfo";
  dispensed: Maybe<DispensedMedication>;
  nextFillInfo: Maybe<NextFillInfo>;
};

export type License = {
  __typename?: "License";
  expirationDate: Maybe<Scalars["Date"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  image: Maybe<Document>;
  imageId: Maybe<Scalars["String"]["output"]>;
  number: Maybe<Scalars["String"]["output"]>;
  registeredState: Maybe<Scalars["String"]["output"]>;
};

export type LicenseInput = {
  expirationDate: Scalars["Date"]["input"];
  id: Scalars["String"]["input"];
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  number: Scalars["String"]["input"];
  registeredState: Scalars["String"]["input"];
};

export type ListInventoryGroupResponse = {
  __typename?: "ListInventoryGroupResponse";
  currentPage: Maybe<Scalars["Int"]["output"]>;
  records: Maybe<Array<Maybe<InventoryGroup>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type ListSigCodeResponse = {
  __typename?: "ListSigCodeResponse";
  records: Maybe<Array<Maybe<SigCode>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type Location = {
  __typename?: "Location";
  _id: Scalars["ID"]["output"];
  active: Maybe<Scalars["Boolean"]["output"]>;
  address: Maybe<Address>;
  contacts: Maybe<Contacts>;
  corporateName: Maybe<Scalars["String"]["output"]>;
  dba: Maybe<Scalars["String"]["output"]>;
  isSnapLocation: Maybe<Scalars["Boolean"]["output"]>;
  legacyId: Maybe<Scalars["Int"]["output"]>;
  licensedStates: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name: Scalars["String"]["output"];
  ncpdpid: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  pharmacistInCharge: Maybe<User>;
  pharmacistInChargeId: Maybe<Scalars["ID"]["output"]>;
  timezone: Maybe<Scalars["String"]["output"]>;
  users: Maybe<Array<Maybe<User>>>;
};

export type LocationPatch = {
  ncpdpid?: InputMaybe<Scalars["String"]["input"]>;
  npi?: InputMaybe<Scalars["String"]["input"]>;
  pharmacistInChargeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LocationUpdate = {
  __typename?: "LocationUpdate";
  location: Maybe<Location>;
  operation: Maybe<Scalars["String"]["output"]>;
};

export type Lock = {
  __typename?: "Lock";
  _id: Scalars["ID"]["output"];
  expiresAt: Scalars["Date"]["output"];
  user: User;
  userId: Scalars["ID"]["output"];
};

export type Log = {
  __typename?: "Log";
  _id: Maybe<Scalars["ID"]["output"]>;
  change: Maybe<LogChange>;
  copayRequest: Maybe<CopayRequest>;
  copayRequestId: Maybe<Scalars["ID"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  debug: Maybe<LogDebug>;
  details: Maybe<Scalars["String"]["output"]>;
  event: Maybe<Scalars["String"]["output"]>;
  fill: Maybe<Fill>;
  fillId: Maybe<Scalars["ID"]["output"]>;
  isAnnotation: Maybe<Scalars["Boolean"]["output"]>;
  isArchived: Maybe<Scalars["Boolean"]["output"]>;
  isExternal: Maybe<Scalars["Boolean"]["output"]>;
  message: Maybe<Scalars["String"]["output"]>;
  order: Maybe<Order>;
  orderId: Maybe<Scalars["ID"]["output"]>;
  page: Maybe<Scalars["String"]["output"]>;
  parataNdcId: Maybe<Scalars["ID"]["output"]>;
  patient: Maybe<Patient>;
  patientId: Maybe<Scalars["ID"]["output"]>;
  payerId: Maybe<Scalars["ID"]["output"]>;
  payerIndex: Maybe<Scalars["Int"]["output"]>;
  prescriber: Maybe<Prescriber>;
  prescriberId: Maybe<Scalars["ID"]["output"]>;
  prescriberPracticeId: Maybe<Scalars["ID"]["output"]>;
  prescription: Maybe<Prescription>;
  prescriptionId: Maybe<Scalars["ID"]["output"]>;
  priorAuthorizationId: Maybe<Scalars["ID"]["output"]>;
  tags: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
  updatedUserId: Maybe<Scalars["ID"]["output"]>;
  user: Maybe<User>;
  userId: Maybe<Scalars["ID"]["output"]>;
};

export type LogChange = {
  __typename?: "LogChange";
  field: Maybe<Scalars["String"]["output"]>;
  newValue: Maybe<Scalars["String"]["output"]>;
  oldValue: Maybe<Scalars["String"]["output"]>;
};

export type LogDebug = {
  __typename?: "LogDebug";
  origin: Maybe<Scalars["String"]["output"]>;
};

export type LogInput = {
  _id?: InputMaybe<Scalars["ID"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  isAnnotation?: InputMaybe<Scalars["Boolean"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<UserAnnotationInput>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MfaSecret = {
  __typename?: "MFASecret";
  otpauth_url: Scalars["String"]["output"];
};

export type MachineOptions = {
  __typename?: "MachineOptions";
  completedCount: Maybe<Scalars["Int"]["output"]>;
  deviceName: Maybe<Scalars["String"]["output"]>;
  error: Maybe<Scalars["String"]["output"]>;
  isDeferred: Maybe<Scalars["Boolean"]["output"]>;
  lastActivity: Maybe<Scalars["String"]["output"]>;
  rxId: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  vials: Maybe<Array<Maybe<Vials>>>;
};

export type MandatoryPharmacy = {
  __typename?: "MandatoryPharmacy";
  address: Maybe<Address>;
  businessName: Maybe<Scalars["String"]["output"]>;
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpid: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  pharmacist: Maybe<Pharmacist>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  specialty: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type MandatoryPrescriber = {
  __typename?: "MandatoryPrescriber";
  address: Maybe<Address>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  isNonVeterinarian: Maybe<Scalars["Boolean"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  practiceLocation: Maybe<PracticeLocationType>;
  prescriberAgent: Maybe<NameComposite>;
  prescriberPlaceOfService: Maybe<Scalars["String"]["output"]>;
  specialty: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  suffix: Maybe<Scalars["String"]["output"]>;
};

export type MatchCandidate = {
  __typename?: "MatchCandidate";
  patient: Maybe<Patient>;
  sortOrder: Maybe<Scalars["Int"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type Measurement = {
  __typename?: "Measurement";
  loincVersion: Maybe<Scalars["String"]["output"]>;
  observationDate: Maybe<Scalars["Date"]["output"]>;
  ucumVersion: Maybe<Scalars["String"]["output"]>;
  unitOfMeasure: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Int"]["output"]>;
  vitalSign: Maybe<Scalars["String"]["output"]>;
};

export type MeasurementDuration = {
  __typename?: "MeasurementDuration";
  measurementDurationNumericValue: Maybe<Scalars["Int"]["output"]>;
  measurementDurationText: Maybe<TitrationSigCode>;
};

export type MeasurementDurationTriggerTiming = {
  __typename?: "MeasurementDurationTriggerTiming";
  measurementDurationClarifyingFreeText: Maybe<Scalars["String"]["output"]>;
  measurementTrigger: Maybe<TitrationSigCode>;
};

export type MeasurementFrequencyTiming = {
  __typename?: "MeasurementFrequencyTiming";
  measurementFrequencyNumericValue: Maybe<Scalars["Int"]["output"]>;
  measurementFrequencyUnits: Maybe<TitrationSigCode>;
};

export type MeasurementIntervalTiming = {
  __typename?: "MeasurementIntervalTiming";
  measurementIntervalNumericValue: Maybe<Scalars["Int"]["output"]>;
  measurementIntervalUnits: Maybe<TitrationSigCode>;
};

export type MeasurementTiming = {
  __typename?: "MeasurementTiming";
  administrationTimingNumericValue: Maybe<Scalars["Int"]["output"]>;
  measurementTimingClarifyingFreeText: Maybe<Scalars["String"]["output"]>;
  measurementTimingEvent: Maybe<TitrationSigCode>;
  measurementTimingModifier: Maybe<TitrationSigCode>;
  measurementTimingSequence: Maybe<MeasurementTimingSequence>;
  measurementTimingUnits: Maybe<TitrationSigCode>;
};

export type MeasurementTimingSequence = {
  __typename?: "MeasurementTimingSequence";
  measurementTimingNumericValue: Maybe<Scalars["Int"]["output"]>;
  variableMeasurementTimingModifier: Maybe<Scalars["String"]["output"]>;
};

export type MedicalHistoryInput = {
  allergies?: InputMaybe<Array<InputMaybe<DurSubjectInput>>>;
  conditions?: InputMaybe<Array<InputMaybe<DurSubjectInput>>>;
  custom?: InputMaybe<CustomDurSubjectsInput>;
  medications?: InputMaybe<Array<InputMaybe<DurSubjectInput>>>;
};

export type Medication = {
  __typename?: "Medication";
  idField: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  patientSurveyInput: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type MedicationInput = {
  brandName?: InputMaybe<Scalars["String"]["input"]>;
  daysSupply?: InputMaybe<Scalars["Int"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  gcn?: InputMaybe<Scalars["String"]["input"]>;
  genericName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  ndc?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  strength?: InputMaybe<StrengthInput>;
  upc?: InputMaybe<Scalars["String"]["input"]>;
  writtenName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MedicationPrescribedDrugCodedInput = {
  productCode?: InputMaybe<MedicationPrescribedDrugCodedProductCodeInput>;
};

export type MedicationPrescribedDrugCodedProductCodeInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type MedicationPrescribedInput = {
  daysSupply?: InputMaybe<Scalars["Int"]["input"]>;
  drugCoded?: InputMaybe<MedicationPrescribedDrugCodedInput>;
  drugDescription?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<EscriptQuantityType>;
  sig?: InputMaybe<Scalars["String"]["input"]>;
};

export type MonographPreview = {
  __typename?: "MonographPreview";
  s3SignedUrl: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addAttachmentToRxImage: Maybe<Prescription>;
  addCopayInsurance: Maybe<CopayRequest>;
  addCopayInsuranceOption: Maybe<CopayRequest>;
  addImage: Maybe<Patient>;
  addOrderInsurance: Maybe<Order>;
  addOrderInsuranceOption: Maybe<Order>;
  addParataNdc: Maybe<ParataNdc>;
  addPractice: AddPracticeResponse;
  addPrescriber: Prescriber;
  addUserEvent: Maybe<UserEvent>;
  archive: Maybe<CriticalMedicalNotes>;
  bulkSendDeferredToAutomation: Maybe<Array<Maybe<SentToAutomationResponse>>>;
  bulkSendToManual: Maybe<Array<Maybe<SentToManualResponse>>>;
  bulkSyncAutomationStatus: Maybe<Array<Maybe<BulkSyncAutomationResponse>>>;
  calibratePrinter: Maybe<Printer>;
  canConfirmPV2: Maybe<CanConfirmPv2Response>;
  cancelAutofill: Maybe<CancelAutofillResponse>;
  cancelOptumUserSubscription: Maybe<OptumSubscriptionCancelationResponse>;
  cancelOrder: Maybe<CancelOrderResponse>;
  cancelSnapOrder: Maybe<Order>;
  changePassword: User;
  completeCopayRequestFill: Maybe<CopayRequestFill>;
  confirmCopayRequestFill: Maybe<ConfirmCopayRequestFill>;
  confirmExcessiveCsRejectionsReport: Maybe<Order>;
  confirmFillAdjudication: Maybe<ConfirmFillAdjudication>;
  confirmFillPV1: Maybe<RxFillRequest>;
  confirmFillPV2: Maybe<RxFillRequest>;
  /** @deprecated Use confirmFillScanWithStockInfo */
  confirmFillScan: Maybe<RxFillRequest>;
  confirmFillScanWithStockInfo: Maybe<RxFillRequest>;
  confirmOutboundTransferPending: Maybe<OutboundTransfer>;
  confirmPacking: Maybe<Order>;
  convertTemporaryMFASecret: ConvertSecretResponse;
  coordinateTransfer: Maybe<Prescription>;
  createAnnotation: Log;
  createAutobot: Maybe<Autobot>;
  createCopayRequest: Maybe<CopayRequest>;
  createDocument: Document;
  createInsuranceProvider: Maybe<InsuranceProvider>;
  createInventoryGroup: Maybe<InventoryGroup>;
  createLog: Log;
  createNote: Log;
  createOptumAddress: Maybe<Address>;
  createOptumNote: Maybe<Note>;
  createOrder: Maybe<Order>;
  createPatient: Maybe<Patient>;
  createPrescription: Maybe<Prescription>;
  createPreviewLabel: Maybe<Array<Maybe<PreviewLabel>>>;
  createSigCode: Maybe<SigCode>;
  createTemporaryMFASecret: MfaSecret;
  createUser: User;
  deleteAutobot: Maybe<Autobot>;
  deleteParataNdc: Maybe<Scalars["String"]["output"]>;
  discontinuePrescription: Maybe<RxNumber>;
  discontinuePrescriptions: Maybe<DiscontinuePrescriptionsResponse>;
  durScreen: DurSubjectInputs;
  endSession: EndSession;
  faxExternalTransfer: Maybe<FaxExternalTransferResponse>;
  forceStatusToComplete: Maybe<Order>;
  generateAndSendPrescriptionRenewalImage: Maybe<FaxRenewalResponse>;
  generateAndSendPrescriptionRxChangeImage: Maybe<FaxRenewalResponse>;
  generateOrder: Maybe<Order>;
  generateShippingLabel: Maybe<OrderShipment>;
  getOptumOrderInvoice: Maybe<FetchOrderInvoiceResponse>;
  initiatePA: Maybe<PriorAuthorization>;
  lockCopay: Maybe<CopayRequest>;
  lockOrder: Maybe<Order>;
  lockPatientMatch: Maybe<PatientMatch>;
  logPageView: Log;
  new: Maybe<CriticalMedicalNotes>;
  newPrinter: Maybe<Printer>;
  overrideOtcProduct: Maybe<Order>;
  overridePackingMaterial: Maybe<Order>;
  patchExternalTransfer: Maybe<ExternalTransfer>;
  printMonograph: Maybe<PrintMonographResponse>;
  printPickSlips: Maybe<PrintPickSlipResponse>;
  printRxLabel: Maybe<Array<Maybe<Fill>>>;
  printShippingLabel: Maybe<Order>;
  redownloadPrescribedImage: Maybe<Prescription>;
  reinstatePrescription: Maybe<RxNumber>;
  rejectCopayRequest: Maybe<RejectCopayRequestResponse>;
  rejectCopayRequestFill: Maybe<RejectCopayRequestFillResponse>;
  rejectOutboundTransfer: Maybe<RejectOutboundTransferResponse>;
  rejectPatientMatch: Maybe<PatientMatch>;
  rejectPatientMatchAndErx: Maybe<PatientMatch>;
  rejectRx: Maybe<Escript>;
  rejectRxFillRequest: Maybe<RejectRxFillRequestResponse>;
  removeAttachmentFromRxImage: Maybe<Prescription>;
  removeCopayInsurance: Maybe<CopayRequest>;
  removeFromAutomation: Maybe<AutomationResponse>;
  removeOrderFromAutomation: Maybe<Array<Maybe<AutomationResponse>>>;
  removeOrderInsurance: Maybe<Order>;
  removeOrdersFromAutomation: Maybe<Array<Maybe<AutomationResponse>>>;
  removeSigCode: Maybe<Scalars["String"]["output"]>;
  replaceRxFillRequest: Order;
  requestRenewal: Maybe<RxRenewalResponse>;
  reroute: Order;
  resetOrder: Maybe<Order>;
  resetPassword: ResetPasswordResponse;
  resolveCopayTriage: Maybe<CopayRequest>;
  resolveDuplicatePrescriptionTriages: Maybe<ResolveDuplicatePrescriptionTriagesResponse>;
  resolveEscriptTriage: Maybe<Escript>;
  resolveOutboundTransferTriage: Maybe<OutboundTransfer>;
  resolvePatientMatch: Maybe<Array<Maybe<PatientMatch>>>;
  resolveRxIntakeIssues: Maybe<Escript>;
  resolveTriage: Maybe<Order>;
  returnOrder: Maybe<ReturnOrder>;
  reverseActiveClaims: Maybe<Array<Maybe<ClaimsReverse>>>;
  reverseActiveCopayClaims: Maybe<Array<Maybe<ClaimsReverse>>>;
  reverseFill: Maybe<Fill>;
  revertToCore: Maybe<Order>;
  runEligibilityCheck: Maybe<EligibilityCheck>;
  runScreen: Maybe<Order>;
  runThroughClaimJumper: Maybe<Order>;
  sendBackToAdjudication: Maybe<Order>;
  sendBackToFill: Maybe<Order>;
  sendBackToPV1: Maybe<Order>;
  sendBackToPV1AndTriage: Maybe<Order>;
  sendBackToPending: Maybe<CopayRequest>;
  sendOptumPasswordResetEmail: Maybe<SendOptumPasswordResetEmailResponse>;
  sendOrderToAutomation: Maybe<Array<Maybe<AutomationResponse>>>;
  sendOrdersToAutomation: Maybe<Array<Maybe<AutomationResponse>>>;
  sendOutboundScan: Maybe<OutboundScan>;
  sendToAutomation: Maybe<AutomationResponse>;
  sendToManual: Maybe<Array<Maybe<SentToManualResponse>>>;
  setCopayInPA: Maybe<CopayRequest>;
  setCopayInTriage: Maybe<CopayRequest>;
  setCopayInsurance: Maybe<CopayRequest>;
  setInTriage: Maybe<Order>;
  setLastCheckedReleaseNote: Maybe<Scalars["Boolean"]["output"]>;
  setOrderInPA: Maybe<Order>;
  setOrderInsurance: Maybe<Order>;
  setOtcSkus: Maybe<Order>;
  setOutboundTransferInTriage: Maybe<OutboundTransfer>;
  snapfulfilCreateReplace: Maybe<CustomerReplace>;
  snapfulfilCreateReturn: Maybe<CustomerReturnReturn>;
  syncAutomationStatus: Maybe<AutomationResponse>;
  takeOverPrescription: Maybe<Prescription>;
  trackControlledSubstanceRejections: Maybe<Order>;
  uncancelFill: Maybe<Order>;
  unlockCopay: Maybe<CopayRequest>;
  unlockOrder: Maybe<Order>;
  unlockPatientMatch: Maybe<PatientMatch>;
  updateAutobot: Maybe<Autobot>;
  updateByIdentifier: Maybe<CriticalMedicalNotes>;
  updateCopayInsurance: Maybe<CopayRequest>;
  updateCopayRequestFillEdiOverrides: Maybe<CopayRequestFill>;
  updateCopayRequestFillEdiReversalOverrides: Maybe<CopayRequestFill>;
  updateCopayRequestFillInsuranceCobs: Maybe<CopayRequest>;
  updateCopayRequestFillInsuranceDurs: Maybe<CopayRequest>;
  updateCopayRequestFillInsuranceOverrideCodes: Maybe<CopayRequest>;
  updateCopayRequestLocation: CopayRequest;
  updateCoverMyMedsKey: Maybe<RxFillRequest>;
  updateDispensed: Maybe<Fill>;
  updateDocument: Document;
  updateEscriptCustomer: Maybe<Escript>;
  updateEscriptExternalId: Maybe<Escript>;
  updateGuestPatient: Maybe<GuestPatient>;
  updateInsuranceProvider: Maybe<InsuranceProvider>;
  updateInventoryGroup: Maybe<InventoryGroup>;
  updateIsReplacementOrder: Order;
  updateLocation: Maybe<Location>;
  updateNote: Log;
  updateOptumAccount: Maybe<User>;
  updateOptumAddress: Maybe<Address>;
  updateOrder: Order;
  updateOrderFillRequestEdiOverrides: Maybe<RxFillRequest>;
  updateOrderFillRequestEdiReversalOverrides: Maybe<RxFillRequest>;
  updateOrderFillRequestInsuranceCobs: Maybe<Order>;
  updateOrderFillRequestInsuranceDurs: Maybe<Order>;
  updateOrderFillRequestInsuranceOverrideCodes: Maybe<Order>;
  updateOrderInsurance: Maybe<Order>;
  updateOrderPaymentType: Order;
  updateOrderShippingAddress: Order;
  updateOrderShippingMethod: Order;
  updateParataNdc: Maybe<ParataNdc>;
  updatePatient: Maybe<Patient>;
  updatePatientInsurance: Maybe<Insurance>;
  updatePatientStatus: Maybe<Patient>;
  updatePractice: Prescriber;
  updatePrescribed: Maybe<Prescription>;
  updatePrescribedCustomer: Maybe<Prescription>;
  updatePrescription: Maybe<RxNumber>;
  updatePrinter: Maybe<Printer>;
  updatePriorAuthorization: Maybe<PriorAuthorization>;
  updateSigCode: Maybe<SigCode>;
  updateTrackingNumber: Order;
  updateUser: User;
  updateUserInfoFromAuth0: User;
  verifyTransferOut: Maybe<Prescription>;
};

export type MutationAddAttachmentToRxImageArgs = {
  newDocumentId: Scalars["ID"]["input"];
  prescriptionId: Scalars["ID"]["input"];
};

export type MutationAddCopayInsuranceArgs = {
  addInsurance?: InputMaybe<OrderInsuranceInput>;
  copayRequestId: Scalars["ID"]["input"];
};

export type MutationAddCopayInsuranceOptionArgs = {
  addInsurance?: InputMaybe<OrderInsuranceInput>;
  copayRequestId: Scalars["ID"]["input"];
  insuranceId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationAddImageArgs = {
  imageId: Scalars["ID"]["input"];
  patientId: Scalars["ID"]["input"];
};

export type MutationAddOrderInsuranceArgs = {
  addInsurance?: InputMaybe<OrderInsuranceInput>;
  orderId: Scalars["ID"]["input"];
};

export type MutationAddOrderInsuranceOptionArgs = {
  addInsurance?: InputMaybe<OrderInsuranceInput>;
  insuranceId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId: Scalars["ID"]["input"];
};

export type MutationAddParataNdcArgs = {
  ndc: Scalars["String"]["input"];
  parataNdcOptions?: InputMaybe<ParataNdcOptions>;
};

export type MutationAddPracticeArgs = {
  newPractice: PrescriberPractice;
  npi?: InputMaybe<Scalars["String"]["input"]>;
  stateLicense?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAddPrescriberArgs = {
  newPractice: PrescriberPractice;
  newPrescriber: NewPrescriber;
};

export type MutationAddUserEventArgs = {
  orderId: Scalars["ID"]["input"];
  rxFillRequestId?: InputMaybe<Scalars["ID"]["input"]>;
  userEvent?: InputMaybe<AddUserEvent>;
};

export type MutationArchiveArgs = {
  identifier: Scalars["String"]["input"];
};

export type MutationBulkSendDeferredToAutomationArgs = {
  fills: Array<InputMaybe<FillWithOrderId>>;
};

export type MutationBulkSendToManualArgs = {
  fillIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type MutationBulkSyncAutomationStatusArgs = {
  fills: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type MutationCalibratePrinterArgs = {
  _id: Scalars["ID"]["input"];
  patch: CalibratePrinterInput;
};

export type MutationCanConfirmPv2Args = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationCancelAutofillArgs = {
  prescriptionToken: Scalars["String"]["input"];
};

export type MutationCancelOptumUserSubscriptionArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationCancelOrderArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
};

export type MutationCancelSnapOrderArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationChangePasswordArgs = {
  password: Scalars["String"]["input"];
};

export type MutationCompleteCopayRequestFillArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
};

export type MutationConfirmCopayRequestFillArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
  pricing?: InputMaybe<PricingSpecInput>;
};

export type MutationConfirmExcessiveCsRejectionsReportArgs = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationConfirmFillAdjudicationArgs = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  pricing?: InputMaybe<PricingSpecInput>;
};

export type MutationConfirmFillPv1Args = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  verification?: InputMaybe<ConfirmFillPv1VerificationInput>;
};

export type MutationConfirmFillPv2Args = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationConfirmFillScanArgs = {
  fillId: Scalars["ID"]["input"];
  manualEntryFilled: Scalars["Boolean"]["input"];
  ndcPackage: Scalars["String"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationConfirmFillScanWithStockInfoArgs = {
  fillId: Scalars["ID"]["input"];
  manualEntryFilled: Scalars["Boolean"]["input"];
  ndcPackage: Scalars["String"]["input"];
  orderId: Scalars["ID"]["input"];
  stockInfo: Array<StockInfoInput>;
};

export type MutationConfirmOutboundTransferPendingArgs = {
  outboundTransferId: Scalars["ID"]["input"];
};

export type MutationConfirmPackingArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationConvertTemporaryMfaSecretArgs = {
  time_token?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCoordinateTransferArgs = {
  rxId: Scalars["String"]["input"];
  toPioneer?: InputMaybe<Scalars["Boolean"]["input"]>;
  transferInfo: TransferEngineInput;
  transferType?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateAnnotationArgs = {
  message: Scalars["String"]["input"];
  prescriptionId: Scalars["ID"]["input"];
};

export type MutationCreateAutobotArgs = {
  autobotOptions?: InputMaybe<AutobotOptions>;
};

export type MutationCreateCopayRequestArgs = {
  locationId: Scalars["String"]["input"];
  rxNumbers: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationCreateDocumentArgs = {
  caption?: InputMaybe<Scalars["String"]["input"]>;
  file: Scalars["Upload"]["input"];
  fill?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  patient?: InputMaybe<Scalars["String"]["input"]>;
  prescription?: InputMaybe<Scalars["String"]["input"]>;
  tag?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateInsuranceProviderArgs = {
  insuranceProvider?: InputMaybe<InsuranceProviderInput>;
};

export type MutationCreateInventoryGroupArgs = {
  createInventoryGroupInput: CreateInventoryGroupInput;
};

export type MutationCreateLogArgs = {
  event: Scalars["String"]["input"];
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  message: Scalars["String"]["input"];
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  priorAuthorizationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationCreateNoteArgs = {
  copayRequestId?: InputMaybe<Scalars["ID"]["input"]>;
  escriptId?: InputMaybe<Scalars["ID"]["input"]>;
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  isExternal?: InputMaybe<Scalars["Boolean"]["input"]>;
  message: Scalars["String"]["input"];
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  payerId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  priorAuthorizationId?: InputMaybe<Scalars["ID"]["input"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationCreateOptumAddressArgs = {
  address1: Scalars["String"]["input"];
  address2?: InputMaybe<Scalars["String"]["input"]>;
  city: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  isDefault: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
  zip: Scalars["String"]["input"];
};

export type MutationCreateOptumNoteArgs = {
  author?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type MutationCreateOrderArgs = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
  locationId: Scalars["ID"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  patientId: Scalars["ID"]["input"];
  paymentType: Scalars["String"]["input"];
  prescriptionIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  requireSignature?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingMethod: Scalars["String"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
  street1?: InputMaybe<Scalars["String"]["input"]>;
  street2?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreatePatientArgs = {
  address?: InputMaybe<AddressesInput>;
  contacts?: InputMaybe<ContactInput>;
  dob: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender: Scalars["String"]["input"];
  insurances?: InputMaybe<Array<InputMaybe<InsuranceInput>>>;
  lastName: Scalars["String"]["input"];
  medicalHistory?: InputMaybe<MedicalHistoryInput>;
  preferences?: InputMaybe<PreferencesInput>;
  species?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreatePrescriptionArgs = {
  calledInBy?: InputMaybe<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
  daw: Scalars["Int"]["input"];
  daysSupply?: InputMaybe<Scalars["Int"]["input"]>;
  deaSchedule?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  effectiveDate?: InputMaybe<Scalars["String"]["input"]>;
  expirationDate: Scalars["String"]["input"];
  gcn?: InputMaybe<Scalars["String"]["input"]>;
  icd10?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  manualRxImage?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  numberOfRefills?: InputMaybe<Scalars["Int"]["input"]>;
  origin: Scalars["String"]["input"];
  patientId: Scalars["ID"]["input"];
  prescriberAddressId: Scalars["ID"]["input"];
  prescriberId: Scalars["ID"]["input"];
  prn?: InputMaybe<Scalars["Boolean"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityRemaining?: InputMaybe<Scalars["Float"]["input"]>;
  refillsRemaining?: InputMaybe<Scalars["Int"]["input"]>;
  rxImageId?: InputMaybe<Scalars["ID"]["input"]>;
  strength?: InputMaybe<StrengthInput>;
  supervisingPrescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  transfer?: InputMaybe<TransferInput>;
  triplicate?: InputMaybe<Scalars["String"]["input"]>;
  upc?: InputMaybe<Scalars["String"]["input"]>;
  writtenDate: Scalars["String"]["input"];
};

export type MutationCreatePreviewLabelArgs = {
  fills: Array<InputMaybe<FillWithOrderId>>;
  printComponent?: InputMaybe<Scalars["String"]["input"]>;
  printerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateSigCodeArgs = {
  createSigCodeInput: CreateSigCodeInput;
};

export type MutationCreateUserArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  licenses?: InputMaybe<Array<InputMaybe<LicenseInput>>>;
  locationId: Scalars["ID"]["input"];
  preferredName?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationDeleteAutobotArgs = {
  autobotId: Scalars["ID"]["input"];
};

export type MutationDeleteParataNdcArgs = {
  parataNdcId: Scalars["String"]["input"];
};

export type MutationDiscontinuePrescriptionArgs = {
  note?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
};

export type MutationDiscontinuePrescriptionsArgs = {
  orderId: Scalars["ID"]["input"];
  prescriptions: Array<InputMaybe<DiscontinuePrescription>>;
};

export type MutationDurScreenArgs = {
  allergies: Array<InputMaybe<DurSubjectInput>>;
  conditions: Array<InputMaybe<DurSubjectInput>>;
  custom: CustomDurSubjectsInput;
  medications: Array<InputMaybe<DurSubjectInput>>;
  orderId: Scalars["ID"]["input"];
};

export type MutationEndSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationFaxExternalTransferArgs = {
  externalTransferId: Scalars["String"]["input"];
  pharmacyFax: Scalars["String"]["input"];
  pharmacyNpi: Scalars["String"]["input"];
  prescriptionId: Scalars["String"]["input"];
  transferType: Scalars["String"]["input"];
};

export type MutationForceStatusToCompleteArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationGenerateAndSendPrescriptionRenewalImageArgs = {
  prescriberAddressId?: InputMaybe<Scalars["String"]["input"]>;
  prescriberFax?: InputMaybe<Scalars["String"]["input"]>;
  prescriberId?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["String"]["input"]>;
  refillsRequested?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationGenerateAndSendPrescriptionRxChangeImageArgs = {
  prescriberAddressId?: InputMaybe<Scalars["String"]["input"]>;
  prescriberFax?: InputMaybe<Scalars["String"]["input"]>;
  prescriberId?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["String"]["input"]>;
  rxChangeNote?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationGenerateOrderArgs = {
  orderOptions?: InputMaybe<GenerateOrderOptionsInput>;
};

export type MutationGenerateShippingLabelArgs = {
  coreOrderId: Scalars["Int"]["input"];
  scannedTasks?: InputMaybe<Array<InputMaybe<ScannedTaskInput>>>;
};

export type MutationGetOptumOrderInvoiceArgs = {
  orderId: Scalars["String"]["input"];
};

export type MutationInitiatePaArgs = {
  claimEdi?: InputMaybe<Scalars["String"]["input"]>;
  claimId?: InputMaybe<Scalars["String"]["input"]>;
  copayRequestId?: InputMaybe<Scalars["String"]["input"]>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  fillId?: InputMaybe<Scalars["String"]["input"]>;
  force?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  medicationName?: InputMaybe<Scalars["String"]["input"]>;
  npi?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
  prescriberFax?: InputMaybe<Scalars["String"]["input"]>;
  prescriberId?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["String"]["input"]>;
  rejectCode?: InputMaybe<Scalars["String"]["input"]>;
  rejectMessage?: InputMaybe<Scalars["String"]["input"]>;
  rxNumber?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationLockCopayArgs = {
  copayId: Scalars["ID"]["input"];
};

export type MutationLockOrderArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationLockPatientMatchArgs = {
  patientMatchId: Scalars["ID"]["input"];
};

export type MutationLogPageViewArgs = {
  page: Scalars["String"]["input"];
  pageViewAttributes?: InputMaybe<PageViewAttributes>;
};

export type MutationNewArgs = {
  identifier: Scalars["String"]["input"];
  note: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
};

export type MutationNewPrinterArgs = {
  GCPAddress: Scalars["String"]["input"];
  locationId: Scalars["String"]["input"];
  make: Scalars["String"]["input"];
  printerIP: Scalars["String"]["input"];
  printerName: Scalars["String"]["input"];
  printerPurpose: Scalars["String"]["input"];
  printerType: PrinterType;
};

export type MutationOverrideOtcProductArgs = {
  orderId: Scalars["ID"]["input"];
  otcProductId: Scalars["ID"]["input"];
};

export type MutationOverridePackingMaterialArgs = {
  orderId: Scalars["ID"]["input"];
  packingMaterialId: Scalars["ID"]["input"];
};

export type MutationPatchExternalTransferArgs = {
  automationId?: InputMaybe<Scalars["String"]["input"]>;
  externalTransferId: Scalars["String"]["input"];
  failureMessage?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  transferType?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPrintMonographArgs = {
  copies?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  ndc: Scalars["String"]["input"];
  preview?: InputMaybe<Scalars["Boolean"]["input"]>;
  printerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPrintPickSlipsArgs = {
  orderIds: Array<Scalars["ID"]["input"]>;
  printerUrl: Scalars["String"]["input"];
};

export type MutationPrintRxLabelArgs = {
  fills: Array<InputMaybe<FillWithOrderId>>;
  packagesRequired: Scalars["Int"]["input"];
  printComponent?: InputMaybe<Scalars["String"]["input"]>;
  printerId: Scalars["ID"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPrintShippingLabelArgs = {
  labelReprintInfo?: InputMaybe<ShippingLabelReprintInformation>;
  orderId: Scalars["ID"]["input"];
  printerUrl: Scalars["String"]["input"];
  stickerPrinterUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRedownloadPrescribedImageArgs = {
  prescriptionId: Scalars["String"]["input"];
};

export type MutationReinstatePrescriptionArgs = {
  note?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
};

export type MutationRejectCopayRequestArgs = {
  copayRequestId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  nextFillableDate?: InputMaybe<Scalars["String"]["input"]>;
  reason: Scalars["String"]["input"];
  reasonCode: Scalars["String"]["input"];
};

export type MutationRejectCopayRequestFillArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  nextFillableDate?: InputMaybe<Scalars["String"]["input"]>;
  reason: Scalars["String"]["input"];
  reasonCode: Scalars["String"]["input"];
};

export type MutationRejectOutboundTransferArgs = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  outboundTransferId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
  reasonCode: Scalars["String"]["input"];
};

export type MutationRejectPatientMatchArgs = {
  patientId: Scalars["ID"]["input"];
  patientMatchId: Scalars["ID"]["input"];
};

export type MutationRejectPatientMatchAndErxArgs = {
  patientId: Scalars["ID"]["input"];
  patientMatchId: Scalars["ID"]["input"];
};

export type MutationRejectRxArgs = {
  escriptId: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRejectRxFillRequestArgs = {
  fillId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  nextFillableDate?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
  reasonCode: Scalars["String"]["input"];
};

export type MutationRemoveAttachmentFromRxImageArgs = {
  prescriptionId: Scalars["ID"]["input"];
};

export type MutationRemoveCopayInsuranceArgs = {
  copayRequestId: Scalars["ID"]["input"];
  insuranceId: Scalars["ID"]["input"];
};

export type MutationRemoveFromAutomationArgs = {
  fillId: Scalars["ID"]["input"];
};

export type MutationRemoveOrderFromAutomationArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationRemoveOrderInsuranceArgs = {
  insuranceId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationRemoveOrdersFromAutomationArgs = {
  orderIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type MutationRemoveSigCodeArgs = {
  _id: Scalars["ID"]["input"];
};

export type MutationReplaceRxFillRequestArgs = {
  orderId: Scalars["ID"]["input"];
  quantity: Scalars["Float"]["input"];
  rxFillRequestId: Scalars["ID"]["input"];
  rxId: Scalars["ID"]["input"];
};

export type MutationRequestRenewalArgs = {
  prescriptionId: Scalars["ID"]["input"];
  refillsRequested?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationRerouteArgs = {
  locationId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationResetOrderArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationResetPasswordArgs = {
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationResolveCopayTriageArgs = {
  copayRequestId: Scalars["ID"]["input"];
  triageId: Scalars["ID"]["input"];
};

export type MutationResolveDuplicatePrescriptionTriagesArgs = {
  orderId: Scalars["ID"]["input"];
  prescriptionIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type MutationResolveEscriptTriageArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  escriptId: Scalars["ID"]["input"];
};

export type MutationResolveOutboundTransferTriageArgs = {
  outboundTransferId: Scalars["ID"]["input"];
  triageId: Scalars["ID"]["input"];
};

export type MutationResolvePatientMatchArgs = {
  patientId: Scalars["ID"]["input"];
  patientMatchId: Scalars["ID"]["input"];
};

export type MutationResolveRxIntakeIssuesArgs = {
  escriptFormData?: InputMaybe<EscriptFormDataInput>;
  escriptId: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  validationIssueTypeIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type MutationResolveTriageArgs = {
  orderId: Scalars["ID"]["input"];
  triageId: Scalars["ID"]["input"];
};

export type MutationReturnOrderArgs = {
  note?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
};

export type MutationReverseActiveClaimsArgs = {
  fillId: Scalars["ID"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationReverseActiveCopayClaimsArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
};

export type MutationReverseFillArgs = {
  fillId: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  reason: Scalars["String"]["input"];
};

export type MutationRevertToCoreArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationRunEligibilityCheckArgs = {
  cardholderId: Scalars["String"]["input"];
  fillId: Scalars["ID"]["input"];
  itemId?: InputMaybe<Scalars["ID"]["input"]>;
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  patientId: Scalars["ID"]["input"];
  type: Scalars["String"]["input"];
};

export type MutationRunScreenArgs = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationRunThroughClaimJumperArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationSendBackToAdjudicationArgs = {
  fillId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
};

export type MutationSendBackToFillArgs = {
  fillId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  sendBackReason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSendBackToPv1Args = {
  fillId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  sendBackReason?: InputMaybe<Scalars["String"]["input"]>;
  skipPv1Check?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationSendBackToPv1AndTriageArgs = {
  fillId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  sendBackCode?: InputMaybe<Scalars["String"]["input"]>;
  sendBackReason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSendBackToPendingArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSendOptumPasswordResetEmailArgs = {
  _id: Scalars["ID"]["input"];
};

export type MutationSendOrderToAutomationArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationSendOrdersToAutomationArgs = {
  orderIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type MutationSendOutboundScanArgs = {
  outboundScanType: OutboundScanType;
  trackingNumber: Scalars["String"]["input"];
};

export type MutationSendToAutomationArgs = {
  fillId: Scalars["ID"]["input"];
};

export type MutationSendToManualArgs = {
  fillId: Scalars["ID"]["input"];
};

export type MutationSetCopayInPaArgs = {
  copayRequestId: Scalars["ID"]["input"];
};

export type MutationSetCopayInTriageArgs = {
  copayRequestId: Scalars["ID"]["input"];
  triages?: InputMaybe<Array<InputMaybe<InTriage>>>;
};

export type MutationSetCopayInsuranceArgs = {
  copayRequestId: Scalars["ID"]["input"];
  setInsurance?: InputMaybe<Array<InputMaybe<OrderInsuranceInput>>>;
};

export type MutationSetInTriageArgs = {
  orderId: Scalars["ID"]["input"];
  triages?: InputMaybe<Array<InputMaybe<InTriage>>>;
};

export type MutationSetLastCheckedReleaseNoteArgs = {
  _id: Scalars["ID"]["input"];
  release: Scalars["String"]["input"];
};

export type MutationSetOrderInPaArgs = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationSetOrderInsuranceArgs = {
  orderId: Scalars["ID"]["input"];
  setInsurance?: InputMaybe<Array<InputMaybe<OrderInsuranceInput>>>;
};

export type MutationSetOtcSkusArgs = {
  orderId: Scalars["ID"]["input"];
  otcSkus: Array<InputMaybe<SkuEntry>>;
};

export type MutationSetOutboundTransferInTriageArgs = {
  outboundTransferId: Scalars["ID"]["input"];
  triages?: InputMaybe<Array<InputMaybe<InTriage>>>;
};

export type MutationSnapfulfilCreateReplaceArgs = {
  returnPayload?: InputMaybe<CustomerReplaceInput>;
};

export type MutationSnapfulfilCreateReturnArgs = {
  returnPayload?: InputMaybe<CustomerReturnInput>;
};

export type MutationSyncAutomationStatusArgs = {
  fillId: Scalars["ID"]["input"];
};

export type MutationTakeOverPrescriptionArgs = {
  locationId: Scalars["ID"]["input"];
  patientId: Scalars["ID"]["input"];
  rxNumber: Scalars["Int"]["input"];
};

export type MutationTrackControlledSubstanceRejectionsArgs = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationUncancelFillArgs = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationUnlockCopayArgs = {
  copayId: Scalars["ID"]["input"];
};

export type MutationUnlockOrderArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationUnlockPatientMatchArgs = {
  patientMatchId: Scalars["ID"]["input"];
};

export type MutationUpdateAutobotArgs = {
  autobotId: Scalars["ID"]["input"];
  autobotOptions?: InputMaybe<AutobotOptions>;
};

export type MutationUpdateByIdentifierArgs = {
  identifier: Scalars["String"]["input"];
  note: Scalars["String"]["input"];
};

export type MutationUpdateCopayInsuranceArgs = {
  copayRequestId: Scalars["ID"]["input"];
  insuranceId: Scalars["ID"]["input"];
  patch?: InputMaybe<OrderInsuranceInput>;
};

export type MutationUpdateCopayRequestFillEdiOverridesArgs = {
  copayRequestId: Scalars["ID"]["input"];
  ediOverrides: Array<InputMaybe<EdiOverridesInput>>;
  fillId: Scalars["ID"]["input"];
};

export type MutationUpdateCopayRequestFillEdiReversalOverridesArgs = {
  copayRequestId: Scalars["ID"]["input"];
  ediReversalOverrides: Array<InputMaybe<EdiReversalOverridesInput>>;
  fillId: Scalars["ID"]["input"];
};

export type MutationUpdateCopayRequestFillInsuranceCobsArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
  insuranceCobs?: InputMaybe<OrderInsuranceOverrideCobInput>;
};

export type MutationUpdateCopayRequestFillInsuranceDursArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
  insuranceDurs?: InputMaybe<Array<InputMaybe<OrderInsuranceDurInput>>>;
  orderInsuranceOptionId: Scalars["ID"]["input"];
};

export type MutationUpdateCopayRequestFillInsuranceOverrideCodesArgs = {
  copayRequestId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
  insuranceOverrideCodes?: InputMaybe<OrderInsuranceOverrideCodesInput>;
};

export type MutationUpdateCopayRequestLocationArgs = {
  copayRequestId: Scalars["ID"]["input"];
  locationId: Scalars["ID"]["input"];
};

export type MutationUpdateCoverMyMedsKeyArgs = {
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationUpdateDispensedArgs = {
  fillId: Scalars["ID"]["input"];
  isCopay?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemId: Scalars["ID"]["input"];
  patch: FillDispensed;
};

export type MutationUpdateDocumentArgs = {
  caption?: InputMaybe<Scalars["String"]["input"]>;
  documentId: Scalars["ID"]["input"];
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  tag?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateEscriptCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  escriptId: Scalars["ID"]["input"];
};

export type MutationUpdateEscriptExternalIdArgs = {
  escriptId: Scalars["ID"]["input"];
  externalId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateGuestPatientArgs = {
  _id: Scalars["ID"]["input"];
  contacts?: InputMaybe<ContactInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateInsuranceProviderArgs = {
  _id: Scalars["ID"]["input"];
  insuranceProvider?: InputMaybe<InsuranceProviderInput>;
};

export type MutationUpdateInventoryGroupArgs = {
  _id: Scalars["ID"]["input"];
  patch: UpdateInventoryGroupInput;
};

export type MutationUpdateIsReplacementOrderArgs = {
  isReplacement: Scalars["Boolean"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationUpdateLocationArgs = {
  locationId: Scalars["ID"]["input"];
  patch?: InputMaybe<LocationPatch>;
};

export type MutationUpdateNoteArgs = {
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  noteId: Scalars["ID"]["input"];
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationUpdateOptumAccountArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  mobileNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateOptumAddressArgs = {
  address1: Scalars["String"]["input"];
  address2?: InputMaybe<Scalars["String"]["input"]>;
  addressId: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  isDefault: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
  zip: Scalars["String"]["input"];
};

export type MutationUpdateOrderArgs = {
  orderId: Scalars["ID"]["input"];
  patch: OrderUpdatePatch;
};

export type MutationUpdateOrderFillRequestEdiOverridesArgs = {
  ediOverrides: Array<InputMaybe<EdiOverridesInput>>;
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationUpdateOrderFillRequestEdiReversalOverridesArgs = {
  ediReversalOverrides: Array<InputMaybe<EdiReversalOverridesInput>>;
  fillId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationUpdateOrderFillRequestInsuranceCobsArgs = {
  fillId: Scalars["ID"]["input"];
  insuranceCobs?: InputMaybe<OrderInsuranceOverrideCobInput>;
  orderId: Scalars["ID"]["input"];
};

export type MutationUpdateOrderFillRequestInsuranceDursArgs = {
  fillId: Scalars["ID"]["input"];
  insuranceDurs?: InputMaybe<Array<InputMaybe<OrderInsuranceDurInput>>>;
  orderId: Scalars["ID"]["input"];
  orderInsuranceOptionId: Scalars["ID"]["input"];
};

export type MutationUpdateOrderFillRequestInsuranceOverrideCodesArgs = {
  fillId: Scalars["ID"]["input"];
  insuranceOverrideCodes?: InputMaybe<OrderInsuranceOverrideCodesInput>;
  orderId: Scalars["ID"]["input"];
};

export type MutationUpdateOrderInsuranceArgs = {
  insuranceId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
  patch?: InputMaybe<OrderInsuranceInput>;
};

export type MutationUpdateOrderPaymentTypeArgs = {
  note: Scalars["String"]["input"];
  orderId: Scalars["ID"]["input"];
  paymentType: Scalars["String"]["input"];
  reason: Scalars["String"]["input"];
};

export type MutationUpdateOrderShippingAddressArgs = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  orderId: Scalars["ID"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
  street1?: InputMaybe<Scalars["String"]["input"]>;
  street2?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateOrderShippingMethodArgs = {
  orderId: Scalars["ID"]["input"];
  shippingMethod?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateParataNdcArgs = {
  ndc: Scalars["String"]["input"];
  parataNdcId: Scalars["String"]["input"];
  parataNdcOptions?: InputMaybe<ParataNdcOptions>;
};

export type MutationUpdatePatientArgs = {
  patch?: InputMaybe<PatientInputUpdate>;
  patientId: Scalars["ID"]["input"];
};

export type MutationUpdatePatientInsuranceArgs = {
  insuranceId: Scalars["ID"]["input"];
  patch?: InputMaybe<InsuranceInput>;
  patientId: Scalars["ID"]["input"];
};

export type MutationUpdatePatientStatusArgs = {
  patientId: Scalars["ID"]["input"];
  reason: PatientStatusChangeReason;
  status: Scalars["String"]["input"];
};

export type MutationUpdatePracticeArgs = {
  npi?: InputMaybe<Scalars["String"]["input"]>;
  practice: PrescriberPractice;
  stateLicense?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdatePrescribedArgs = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  patch: PrescribedMedicationInput;
  rxId: Scalars["ID"]["input"];
};

export type MutationUpdatePrescribedCustomerArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  rxId: Scalars["ID"]["input"];
};

export type MutationUpdatePrescriptionArgs = {
  patch: PrescriptionUpdateInput;
  rxId: Scalars["ID"]["input"];
};

export type MutationUpdatePrinterArgs = {
  _id: Scalars["ID"]["input"];
  patch: UpdatePrinterInput;
};

export type MutationUpdatePriorAuthorizationArgs = {
  data: PriorAuthUpdateInput;
  priorAuthorizationId: Scalars["ID"]["input"];
};

export type MutationUpdateSigCodeArgs = {
  _id: Scalars["ID"]["input"];
  patch: UpdateSigCodeInput;
};

export type MutationUpdateTrackingNumberArgs = {
  orderId: Scalars["ID"]["input"];
  trackingNumber: Scalars["String"]["input"];
};

export type MutationUpdateUserArgs = {
  _id: Scalars["ID"]["input"];
  patch: UpdateUserInput;
};

export type MutationVerifyTransferOutArgs = {
  rxId: Scalars["ID"]["input"];
};

export type NciCode = {
  __typename?: "NCICode";
  code: Maybe<Scalars["String"]["output"]>;
};

export type NdcPackage = {
  __typename?: "NDCPackage";
  description: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  ndcProduct: NdcProduct;
  patientInformation: Maybe<Array<Maybe<NdcPatientInformation>>>;
  product: Scalars["String"]["output"];
  raw: Maybe<NdcPackageRawDescriptors>;
};

export type NdcPackageInput = {
  descriptors?: InputMaybe<DrugDescriptorsInput>;
  fullPackageCode: Scalars["String"]["input"];
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  packageCode: Scalars["String"]["input"];
  productCode: Scalars["String"]["input"];
};

export type NdcPackageRawDescriptors = {
  __typename?: "NDCPackageRawDescriptors";
  NDCPACKAGECODE: Maybe<Scalars["String"]["output"]>;
};

export type NdcPackageWithBalance = {
  __typename?: "NDCPackageWithBalance";
  balanceOnHand: Maybe<Scalars["Float"]["output"]>;
  ndcPackage: Maybe<NdcPackage>;
};

export type NdcPatientInformation = {
  __typename?: "NDCPatientInformation";
  html: Maybe<Scalars["String"]["output"]>;
  language: Maybe<Scalars["String"]["output"]>;
};

export type NdcProduct = {
  __typename?: "NDCProduct";
  alternativeNames: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  brandName: Maybe<Scalars["String"]["output"]>;
  certificationExpiration: Maybe<Scalars["String"]["output"]>;
  classes: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  deaSchedule: Maybe<Scalars["String"]["output"]>;
  dosages: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  genericName: Maybe<Scalars["String"]["output"]>;
  labeler: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  ndcPackages: Array<Maybe<NdcPackage>>;
  productType: Maybe<Scalars["String"]["output"]>;
  raw: Maybe<RawProductPackageInfo>;
  strengthUnits: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  substance: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type Name = {
  __typename?: "Name";
  firstName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  middleName: Maybe<Scalars["String"]["output"]>;
  prefix: Maybe<Scalars["String"]["output"]>;
  suffix: Maybe<Scalars["String"]["output"]>;
};

export type NameComposite = {
  __typename?: "NameComposite";
  formerName: Maybe<Name>;
  name: Maybe<Name>;
};

export type NdcFullInfo = {
  __typename?: "NdcFullInfo";
  dosageForm: Scalars["String"]["output"];
  dosageFormDescription: Maybe<Scalars["String"]["output"]>;
  drugDescriptorId: Scalars["Int"]["output"];
  drugName: Scalars["String"]["output"];
  genericFor: Maybe<Scalars["String"]["output"]>;
  isSpecialty: Maybe<Scalars["Boolean"]["output"]>;
  labeler: Maybe<NdcLabeler>;
  legendIndicatorCode: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  packages: Maybe<Array<Maybe<NdcFullInfoPackage>>>;
  patientInformation: Maybe<NdcFullInfoPatientInformation>;
  route: Maybe<Scalars["String"]["output"]>;
  source: Maybe<Scalars["String"]["output"]>;
  strength: Maybe<Scalars["String"]["output"]>;
  strengthUnitOfMeasure: Maybe<Scalars["String"]["output"]>;
};

export type NdcFullInfoPackage = {
  __typename?: "NdcFullInfoPackage";
  clinicPackCode: Maybe<Scalars["String"]["output"]>;
  deaCode: Maybe<Scalars["String"]["output"]>;
  desiCode: Maybe<Scalars["String"]["output"]>;
  dispensingUnitCode: Maybe<Scalars["String"]["output"]>;
  dollarRankCode: Maybe<Scalars["String"]["output"]>;
  drugDescriptorId: Scalars["Int"]["output"];
  drugImageImprints: Maybe<DrugImageImprint>;
  drugName: Maybe<Scalars["String"]["output"]>;
  format: Maybe<Scalars["String"]["output"]>;
  gpi: Scalars["String"]["output"];
  gppc: Scalars["String"]["output"];
  hasInnerPackSibling: Maybe<Scalars["Boolean"]["output"]>;
  hfpgIndicatorCode: Maybe<Scalars["String"]["output"]>;
  innerpackCode: Maybe<Scalars["String"]["output"]>;
  inventories: Maybe<Array<Maybe<NdcInventory>>>;
  isUnbreakable: Maybe<Scalars["Boolean"]["output"]>;
  itemStatusFlag: Maybe<Scalars["String"]["output"]>;
  kbDrugCode: Maybe<Scalars["Float"]["output"]>;
  kdcFlag: Maybe<Scalars["String"]["output"]>;
  limitedDistributionCode: Maybe<Scalars["String"]["output"]>;
  mediSpanLabelerId: Maybe<Scalars["Int"]["output"]>;
  multiSourceCode: Maybe<Scalars["String"]["output"]>;
  nameTypeCode: Maybe<Scalars["String"]["output"]>;
  ndc: Scalars["String"]["output"];
  newEffectiveDate: Maybe<Scalars["String"]["output"]>;
  nextLargerNdcSuffixNumber: Maybe<Scalars["String"]["output"]>;
  nextSmallerNdcSuffixNumber: Maybe<Scalars["String"]["output"]>;
  oldEffectiveDate: Maybe<Scalars["String"]["output"]>;
  oldNdc: Maybe<Scalars["String"]["output"]>;
  packageDescription: Scalars["String"]["output"];
  packageDescriptionCode: Scalars["String"]["output"];
  packageQuantity: Scalars["Int"]["output"];
  packageSize: Scalars["Float"]["output"];
  packageSizeUnitOfMeasure: Scalars["String"]["output"];
  ppgIndicatorCode: Maybe<Scalars["String"]["output"]>;
  repackageCode: Maybe<Scalars["String"]["output"]>;
  rxOtcIndicatorCode: Maybe<Scalars["String"]["output"]>;
  rxRankCode: Maybe<Scalars["String"]["output"]>;
  storageConditionCode: Maybe<Scalars["String"]["output"]>;
  teCode: Scalars["String"]["output"];
  transactionCode: Maybe<Scalars["String"]["output"]>;
  unitDose: Maybe<Scalars["String"]["output"]>;
};

export type NdcFullInfoPatientInformation = {
  __typename?: "NdcFullInfoPatientInformation";
  eng: Maybe<Scalars["String"]["output"]>;
  spa: Maybe<Scalars["String"]["output"]>;
};

export type NdcInventory = {
  __typename?: "NdcInventory";
  availableBalance: Maybe<Scalars["Float"]["output"]>;
  balanceInFlight: Maybe<Scalars["Float"]["output"]>;
  balanceOnHand: Scalars["Float"]["output"];
  inventoryGroupId: Maybe<Scalars["ID"]["output"]>;
  locationId: Scalars["ID"]["output"];
  pricing: Maybe<InventoryPricing>;
  upc: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["String"]["output"]>;
};

export type NdcLabeler = {
  __typename?: "NdcLabeler";
  abbreviatedName: Maybe<Scalars["String"]["output"]>;
  labelerId: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type NdcResultPartial = {
  __typename?: "NdcResultPartial";
  consigned_inventory: Scalars["Boolean"]["output"];
  dispense_as_requested: Scalars["Boolean"]["output"];
  gpi: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  preferred_ndc: Scalars["Boolean"]["output"];
  sku: Maybe<Scalars["String"]["output"]>;
  upc: Maybe<Scalars["String"]["output"]>;
};

export type NdcSubstitution = {
  __typename?: "NdcSubstitution";
  gpi: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  ndcs: Maybe<Array<Maybe<NdcSubstitutionPackage>>>;
};

export type NdcSubstitutionPackage = {
  __typename?: "NdcSubstitutionPackage";
  dosageForm: Scalars["String"]["output"];
  drugDescriptorId: Scalars["Int"]["output"];
  drugName: Scalars["String"]["output"];
  gpi: Scalars["String"]["output"];
  gppc: Scalars["String"]["output"];
  labeler: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  packageDescriptionCode: Scalars["String"]["output"];
  packageQuantity: Scalars["Int"]["output"];
  packageSize: Scalars["Float"]["output"];
  packageSizeUnitOfMeasure: Scalars["String"]["output"];
  strength: Maybe<Scalars["String"]["output"]>;
  strengthUnitOfMeasure: Maybe<Scalars["String"]["output"]>;
  teCode: Scalars["String"]["output"];
  transactionCode: Maybe<Scalars["String"]["output"]>;
  unitDose: Maybe<Scalars["String"]["output"]>;
};

export type NdcSubstitutionPackagePreferred = {
  __typename?: "NdcSubstitutionPackagePreferred";
  acquisitionCost: Maybe<Scalars["Float"]["output"]>;
  availableBalance: Scalars["Float"]["output"];
  balanceInFlight: Scalars["Float"]["output"];
  balanceOnHand: Scalars["Float"]["output"];
  consigned: Maybe<Scalars["Boolean"]["output"]>;
  dosageForm: Scalars["String"]["output"];
  drugDescriptorId: Scalars["Int"]["output"];
  drugName: Maybe<Scalars["String"]["output"]>;
  ffsInventoryInfo: Maybe<FfsInventoryInfo>;
  gpi: Scalars["String"]["output"];
  gppc: Scalars["String"]["output"];
  inventoryGroup: InventoryGroup;
  labeler: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  packageDescriptionCode: Scalars["String"]["output"];
  packageQuantity: Scalars["Int"]["output"];
  packageSize: Scalars["Float"]["output"];
  packageSizeUnitOfMeasure: Scalars["String"]["output"];
  preferred: Maybe<Scalars["Boolean"]["output"]>;
  strength: Maybe<Scalars["String"]["output"]>;
  strengthUnitOfMeasure: Maybe<Scalars["String"]["output"]>;
  teCode: Scalars["String"]["output"];
  transactionCode: Maybe<Scalars["String"]["output"]>;
  unitDose: Maybe<Scalars["String"]["output"]>;
};

export type NdcSubstitutionPartial = {
  __typename?: "NdcSubstitutionPartial";
  availableBalance: Scalars["Float"]["output"];
  balanceInFlight: Scalars["Float"]["output"];
  balanceOnHand: Scalars["Float"]["output"];
  consigned: Maybe<Scalars["Boolean"]["output"]>;
  dispenseAsRequested: Maybe<Scalars["Boolean"]["output"]>;
  drugName: Scalars["String"]["output"];
  gpi: Scalars["String"]["output"];
  inventoryGroupId: Scalars["String"]["output"];
  itemStatusFlag: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  preferred: Maybe<Scalars["Boolean"]["output"]>;
};

export type NdcSubstitutionsPreferred = {
  __typename?: "NdcSubstitutionsPreferred";
  error: Maybe<Scalars["String"]["output"]>;
  formularyStatus: Maybe<Scalars["Boolean"]["output"]>;
  gpi: Maybe<Scalars["String"]["output"]>;
  meta: NdcSubstitutionsPreferredMeta;
  name: Maybe<Scalars["String"]["output"]>;
  ndcs: Maybe<Array<Maybe<NdcSubstitutionPackagePreferred>>>;
};

export type NdcSubstitutionsPreferredMeta = {
  __typename?: "NdcSubstitutionsPreferredMeta";
  nonTherapeuticEquivalentNdcs: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
};

export type NeedNoLaterThan = {
  __typename?: "NeedNoLaterThan";
  neededNoLaterThanDate: Maybe<Scalars["Date"]["output"]>;
  neededNoLaterThanReason: Maybe<Scalars["String"]["output"]>;
};

export type NewPrescriber = {
  dea?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  npi?: InputMaybe<Scalars["String"]["input"]>;
  stateLicense?: InputMaybe<Scalars["String"]["input"]>;
  suffix?: InputMaybe<Scalars["String"]["input"]>;
};

export type NewRxPrescribedMedication = {
  __typename?: "NewRxPrescribedMedication";
  compoundInformation: Maybe<Scalars["String"]["output"]>;
  currentTreatmentCycle: Maybe<Scalars["Int"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  deliveryLocation: Maybe<Scalars["String"]["output"]>;
  deliveryRequest: Maybe<Scalars["String"]["output"]>;
  diabeticSupply: Maybe<DiabeticSupply>;
  diagnosis: Maybe<Diagnosis>;
  doNotFill: Maybe<Scalars["String"]["output"]>;
  drugCoded: Maybe<DrugCoded>;
  drugCoverageStatusCode: Maybe<Scalars["String"]["output"]>;
  drugDescription: Maybe<Scalars["String"]["output"]>;
  drugUseEvaluation: Maybe<Array<Maybe<DrugUseEvaluation>>>;
  facilitySpecificHoursOfAdministrationTiming: Maybe<FacilitySpecificHoursOfAdministrationTiming>;
  flavoringRequested: Maybe<Scalars["Boolean"]["output"]>;
  historyPrescriberOrderNumber: Maybe<Scalars["String"]["output"]>;
  historySource: Maybe<HistorySourceWithoutPayment>;
  injuryRelated: Maybe<Scalars["String"]["output"]>;
  ivAdministration: Maybe<IvAdministrationType>;
  lastFillDate: Maybe<Scalars["Date"]["output"]>;
  lotNumber: Maybe<Scalars["String"]["output"]>;
  manufacturerName: Maybe<Scalars["String"]["output"]>;
  needNoLaterThan: Maybe<NeedNoLaterThan>;
  note: Maybe<Scalars["String"]["output"]>;
  numberOfCyclesPlanned: Maybe<Scalars["Int"]["output"]>;
  numberOfPackagesToBeDispensed: Maybe<Scalars["Int"]["output"]>;
  numberOfRefills: Maybe<Scalars["Int"]["output"]>;
  officeOfPharmacyAffairsID: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderCaptureMethod: Maybe<Scalars["String"]["output"]>;
  otherMedicationDate: Maybe<OtherMedicationDate>;
  patientCodifiedNote: Maybe<PatientCodifiedNote>;
  pharmacy: Maybe<MandatoryPharmacy>;
  pharmacyRequestedRefills: Maybe<Scalars["Int"]["output"]>;
  placeOfServiceNonSelfAdministeredProduct: Maybe<Scalars["Int"]["output"]>;
  prescriber: Maybe<PrescriberGeneral>;
  prescriberCheckedREMS: Maybe<Scalars["String"]["output"]>;
  priorAuthorization: Maybe<Scalars["String"]["output"]>;
  priorAuthorizationStatus: Maybe<Scalars["String"]["output"]>;
  prophylacticOrEpisodic: Maybe<Scalars["String"]["output"]>;
  providerExplicitAuthorizationToAdminister: Maybe<
    Scalars["Boolean"]["output"]
  >;
  quantity: Maybe<Quantity>;
  reasonForSubstitutionCodeUsed: Maybe<Scalars["String"]["output"]>;
  refillsRemaining: Maybe<Scalars["Int"]["output"]>;
  remsAuthorizationNumber: Maybe<Scalars["String"]["output"]>;
  remsPatientRiskCategory: Maybe<Scalars["String"]["output"]>;
  rxFillIndicator: Maybe<Scalars["String"]["output"]>;
  service: Maybe<ServiceStructure>;
  sig: Maybe<Scalars["String"]["output"]>;
  splitScript: Maybe<Scalars["String"]["output"]>;
  substitutions: Maybe<Scalars["String"]["output"]>;
  timeZone: Maybe<TimeZone>;
  titration: Maybe<Titration>;
  treatmentIndicator: Maybe<Scalars["String"]["output"]>;
  wound: Maybe<WoundStructure>;
  writtenDate: Maybe<Scalars["Date"]["output"]>;
};

export type NextFillInfo = {
  __typename?: "NextFillInfo";
  daysLeft: Maybe<Scalars["Int"]["output"]>;
  dueDate: Maybe<Scalars["Date"]["output"]>;
  isOkToFill: Maybe<Scalars["Boolean"]["output"]>;
};

export type Note = {
  __typename?: "Note";
  author: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  orderId: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
  userId: Maybe<Scalars["String"]["output"]>;
};

export type NoteType = {
  __typename?: "NoteType";
  Note: Maybe<Scalars["String"]["output"]>;
  ReferenceNumber: Maybe<Scalars["String"]["output"]>;
};

export type NoteTypeWithReasonCode = {
  __typename?: "NoteTypeWithReasonCode";
  note: Maybe<Scalars["String"]["output"]>;
  reasonCode: Maybe<Scalars["String"]["output"]>;
  referenceNumber: Maybe<Scalars["String"]["output"]>;
};

export type NoteTypeWithReasonCode18 = {
  __typename?: "NoteTypeWithReasonCode18";
  note: Maybe<Scalars["String"]["output"]>;
  reasonCode: Maybe<Scalars["String"]["output"]>;
  referenceNumber: Maybe<Scalars["String"]["output"]>;
};

export type NoteTypeWithReasonCode21 = {
  __typename?: "NoteTypeWithReasonCode21";
  note: Maybe<Scalars["String"]["output"]>;
  reasonCode: Maybe<Scalars["String"]["output"]>;
  referenceNumber: Maybe<Scalars["String"]["output"]>;
};

export type NoteUpdate = {
  __typename?: "NoteUpdate";
  log: Maybe<Log>;
  operation: Maybe<Scalars["String"]["output"]>;
};

export type Npi = {
  __typename?: "Npi";
  _id: Maybe<Scalars["ID"]["output"]>;
  alternativeCredential: Maybe<Scalars["String"]["output"]>;
  alternativeFirstName: Maybe<Scalars["String"]["output"]>;
  alternativeLastName: Maybe<Scalars["String"]["output"]>;
  alternativeMiddleName: Maybe<Scalars["String"]["output"]>;
  alternativeNamePrefix: Maybe<Scalars["String"]["output"]>;
  alternativeNameSuffix: Maybe<Scalars["String"]["output"]>;
  alternativeOrganizationName: Maybe<Scalars["String"]["output"]>;
  authorizedCredential: Maybe<Scalars["String"]["output"]>;
  authorizedFirstName: Maybe<Scalars["String"]["output"]>;
  authorizedLastName: Maybe<Scalars["String"]["output"]>;
  authorizedMiddleName: Maybe<Scalars["String"]["output"]>;
  authorizedNamePrefix: Maybe<Scalars["String"]["output"]>;
  authorizedNameSufix: Maybe<Scalars["String"]["output"]>;
  authorizedPhone: Maybe<Scalars["String"]["output"]>;
  authorizedTitle: Maybe<Scalars["String"]["output"]>;
  certificationDate: Maybe<Scalars["String"]["output"]>;
  credential: Maybe<Scalars["String"]["output"]>;
  deactivationDate: Maybe<Scalars["String"]["output"]>;
  deactivationReasonCode: Maybe<Scalars["String"]["output"]>;
  enumerationDate: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  gender: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  lastUpdateDate: Maybe<Scalars["String"]["output"]>;
  middleName: Maybe<Scalars["String"]["output"]>;
  namePrefix: Maybe<Scalars["String"]["output"]>;
  nameSuffix: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["Int"]["output"]>;
  organizationName: Maybe<Scalars["String"]["output"]>;
  reactivationDate: Maybe<Scalars["String"]["output"]>;
  replacementNpi: Maybe<Scalars["Int"]["output"]>;
  taxonomies: Maybe<Array<Maybe<NpiTaxonomy>>>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type NpiDoc = {
  __typename?: "NpiDoc";
  credential: Maybe<Scalars["String"]["output"]>;
};

export type NpiTaxonomy = {
  __typename?: "NpiTaxonomy";
  code: Maybe<Scalars["String"]["output"]>;
  desc: Maybe<Scalars["String"]["output"]>;
  identifier: Maybe<Scalars["String"]["output"]>;
  issuer: Maybe<Scalars["String"]["output"]>;
  license: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
};

export type NumberWithToggle = {
  __typename?: "NumberWithToggle";
  send: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type NumberWithToggleInput = {
  send?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Observation = {
  __typename?: "Observation";
  measurement: Maybe<Array<Maybe<Measurement>>>;
  observationNotes: Maybe<Scalars["String"]["output"]>;
};

export type OptumOrder = {
  __typename?: "OptumOrder";
  address: Maybe<Address>;
  order: Maybe<OptumOrderDetails>;
  user: Maybe<OrderUser>;
};

export type OptumOrderDetails = {
  __typename?: "OptumOrderDetails";
  cardNumber: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  discount: Maybe<Scalars["String"]["output"]>;
  orderId: Maybe<Scalars["String"]["output"]>;
  products: Maybe<Array<Maybe<OptumProduct>>>;
  shippingMethod: Maybe<Scalars["String"]["output"]>;
  shippingMethodName: Maybe<Scalars["String"]["output"]>;
  shippingPrice: Maybe<Scalars["Float"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  taxesPrice: Maybe<Scalars["Float"]["output"]>;
  totalPrice: Maybe<Scalars["Float"]["output"]>;
  trackingUrl: Maybe<Scalars["String"]["output"]>;
};

export type OptumOrderFull = {
  __typename?: "OptumOrderFull";
  address: Maybe<Address>;
  order: Maybe<OptumOrderFullDetails>;
  user: Maybe<OrderUser>;
};

export type OptumOrderFullDetails = {
  __typename?: "OptumOrderFullDetails";
  cardNumber: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  discount: Maybe<Scalars["String"]["output"]>;
  orderId: Maybe<Scalars["String"]["output"]>;
  products: Maybe<Array<Maybe<OptumProduct>>>;
  shippingMethod: Maybe<Scalars["String"]["output"]>;
  shippingMethodName: Maybe<Scalars["String"]["output"]>;
  shippingPrice: Maybe<Scalars["Float"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  taxesPrice: Maybe<Scalars["Float"]["output"]>;
  totalPrice: Maybe<Scalars["Float"]["output"]>;
  trackingUrl: Maybe<Scalars["String"]["output"]>;
};

export type OptumOrderSearch = {
  __typename?: "OptumOrderSearch";
  data: Maybe<Array<Maybe<OptumOrderFull>>>;
  pagination: Maybe<OptumPagination>;
};

export type OptumPagination = {
  __typename?: "OptumPagination";
  pageCount: Maybe<Scalars["Int"]["output"]>;
  pageNumber: Maybe<Scalars["Int"]["output"]>;
  total: Maybe<Scalars["Int"]["output"]>;
};

export type OptumProduct = {
  __typename?: "OptumProduct";
  brand: Maybe<Scalars["String"]["output"]>;
  image: Maybe<Scalars["String"]["output"]>;
  metaDescription: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  price: Maybe<Scalars["Int"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  sku: Maybe<Scalars["String"]["output"]>;
  slug: Maybe<Scalars["String"]["output"]>;
  thumbnail: Maybe<Scalars["String"]["output"]>;
};

export type OptumSubcription = {
  __typename?: "OptumSubcription";
  healthProfileId: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  plan: Maybe<OptumSubscriptionPlan>;
  planId: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  subscriptionType: Maybe<Scalars["String"]["output"]>;
  userId: Maybe<Scalars["String"]["output"]>;
};

export type OptumSubscriptionCancelationResponse = {
  __typename?: "OptumSubscriptionCancelationResponse";
  statusCode: Maybe<Scalars["String"]["output"]>;
};

export type OptumSubscriptionPlan = {
  __typename?: "OptumSubscriptionPlan";
  description: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  initialInterval: Maybe<Scalars["String"]["output"]>;
  interval: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  price: Maybe<Scalars["String"]["output"]>;
  serviceType: Maybe<Scalars["String"]["output"]>;
};

export type Order = {
  __typename?: "Order";
  _id: Scalars["ID"]["output"];
  apiRequest: Maybe<Scalars["String"]["output"]>;
  cancellationDetails: Maybe<CancellationDetails>;
  coreOrderId: Maybe<Scalars["Int"]["output"]>;
  coreOrderToken: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customer: Maybe<Customer>;
  customerId: Maybe<Scalars["ID"]["output"]>;
  customerRef: Maybe<Scalars["String"]["output"]>;
  customerReturns: Maybe<Array<Maybe<CustomerReturn>>>;
  durInputs: Maybe<DurSubjectInputs>;
  guestPatient: Maybe<GuestPatient>;
  hasShippingLabel: Maybe<Scalars["Boolean"]["output"]>;
  inTriage: Maybe<OrderTriage>;
  insuranceIds: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  insuranceOptions: Maybe<Array<Maybe<OrderInsuranceOption>>>;
  insurances: Maybe<Array<Maybe<OrderInsurance>>>;
  isReplacement: Maybe<Scalars["Boolean"]["output"]>;
  isReservedForInvestigation: Maybe<Scalars["Boolean"]["output"]>;
  isSnapOrder: Maybe<Scalars["Boolean"]["output"]>;
  location: Location;
  locationId: Maybe<Scalars["ID"]["output"]>;
  lock: Maybe<Lock>;
  logs: Maybe<Array<Maybe<Log>>>;
  noShip: Maybe<Scalars["Boolean"]["output"]>;
  notes: Maybe<Scalars["String"]["output"]>;
  otcProducts: Maybe<Array<Maybe<OtcProduct>>>;
  packingMaterials: Maybe<Array<Maybe<PackingMaterialItem>>>;
  patient: Maybe<Patient>;
  patientId: Maybe<Scalars["ID"]["output"]>;
  paymentType: Maybe<Scalars["String"]["output"]>;
  pricing: Maybe<OrderPricing>;
  printPickSlipsHistory: Maybe<Array<Maybe<PrintPickSlipHistory>>>;
  priority: Maybe<Scalars["Int"]["output"]>;
  rxFillRequests: Maybe<Array<Maybe<RxFillRequest>>>;
  shipments: Maybe<Array<Maybe<Shipment>>>;
  shippingAddress: Maybe<Address>;
  shippingMethod: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  survey: Maybe<PatientSurvey>;
  triages: Maybe<Array<Maybe<OrderTriage>>>;
  updatedAt: Maybe<Scalars["String"]["output"]>;
};

export type OrderCountByAutomationStatus = {
  __typename?: "OrderCountByAutomationStatus";
  deferred: Maybe<Scalars["Int"]["output"]>;
  max: Maybe<Scalars["Int"]["output"]>;
  mini: Maybe<Scalars["Int"]["output"]>;
  unassigned: Maybe<Scalars["Int"]["output"]>;
};

export type OrderInput = {
  createdAt?: InputMaybe<Scalars["Date"]["input"]>;
  customer?: InputMaybe<CustomerInput>;
  fills?: InputMaybe<Array<InputMaybe<FillInput>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  noShip?: InputMaybe<Scalars["Boolean"]["input"]>;
  patient?: InputMaybe<PatientInput>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  survey?: InputMaybe<PatientSurveyInput>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrderInsurance = {
  __typename?: "OrderInsurance";
  _id: Maybe<Scalars["String"]["output"]>;
  bin: Maybe<Scalars["String"]["output"]>;
  cardType: Maybe<Scalars["String"]["output"]>;
  cardholderId: Maybe<Scalars["String"]["output"]>;
  coreInsuranceToken: Maybe<Scalars["String"]["output"]>;
  group: Maybe<Scalars["String"]["output"]>;
  helpdeskNumber: Maybe<Scalars["String"]["output"]>;
  images: Maybe<InsuranceImages>;
  name: Maybe<Scalars["String"]["output"]>;
  orderInsuranceOptionId: Maybe<Scalars["String"]["output"]>;
  pcn: Maybe<Scalars["String"]["output"]>;
  personCode: Maybe<Scalars["String"]["output"]>;
  relationship: Maybe<Scalars["String"]["output"]>;
};

export type OrderInsuranceDurInput = {
  coAgentId?: InputMaybe<Scalars["String"]["input"]>;
  coAgentQualifier?: InputMaybe<Scalars["String"]["input"]>;
  levelOfEffort?: InputMaybe<Scalars["String"]["input"]>;
  professionalService?: InputMaybe<Scalars["String"]["input"]>;
  reasonForService?: InputMaybe<Scalars["String"]["input"]>;
  resultOfService?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrderInsuranceImages = {
  __typename?: "OrderInsuranceImages";
  insuranceOptionId: Scalars["String"]["output"];
  url: Maybe<Scalars["String"]["output"]>;
};

export type OrderInsuranceInput = {
  _id?: InputMaybe<Scalars["ID"]["input"]>;
  bin?: InputMaybe<Scalars["String"]["input"]>;
  cardType?: InputMaybe<Scalars["String"]["input"]>;
  cardholderId?: InputMaybe<Scalars["String"]["input"]>;
  cobOverrides?: InputMaybe<OrderInsuranceOverrideCobInput>;
  coreInsuranceToken?: InputMaybe<Scalars["String"]["input"]>;
  governmentSponsoredPlan?: InputMaybe<Scalars["Boolean"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  helpdeskNumber?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<InsuranceImagesInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  orderInsuranceOptionId?: InputMaybe<Scalars["ID"]["input"]>;
  pcn?: InputMaybe<Scalars["String"]["input"]>;
  personCode?: InputMaybe<Scalars["String"]["input"]>;
  relationship?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrderInsuranceOption = {
  __typename?: "OrderInsuranceOption";
  _id: Maybe<Scalars["String"]["output"]>;
  bin: Maybe<Scalars["String"]["output"]>;
  cardType: Maybe<Scalars["String"]["output"]>;
  cardholderId: Maybe<Scalars["String"]["output"]>;
  coreInsuranceToken: Maybe<Scalars["String"]["output"]>;
  group: Maybe<Scalars["String"]["output"]>;
  helpdeskNumber: Maybe<Scalars["String"]["output"]>;
  images: Maybe<InsuranceImages>;
  name: Maybe<Scalars["String"]["output"]>;
  pcn: Maybe<Scalars["String"]["output"]>;
  personCode: Maybe<Scalars["String"]["output"]>;
  relationship: Maybe<Scalars["String"]["output"]>;
};

export type OrderInsuranceOverrideCobInput = {
  benefitStageAmount?: InputMaybe<AmountArrayOverrideCobInput>;
  orderInsuranceOptionId?: InputMaybe<Scalars["String"]["input"]>;
  otherPayerAmountPaid?: InputMaybe<AmountArrayOverrideCobInput>;
  otherPayerDate?: InputMaybe<DateOverrideInput>;
  otherPayerId?: InputMaybe<StringOverrideCobInput>;
  otherPayerPatientResponsibilityAmount?: InputMaybe<AmountArrayOverrideCobInput>;
  otherPayerRejectCode?: InputMaybe<StringArrayOverrideCobInput>;
};

export type OrderInsuranceOverrideCodesInput = {
  levelOfService?: InputMaybe<IntegerOverrideInput>;
  orderInsuranceOptionId?: InputMaybe<Scalars["String"]["input"]>;
  otherCoverageCode?: InputMaybe<IntegerOverrideInput>;
  priorAuthorizationNumberSubmitted?: InputMaybe<Scalars["String"]["input"]>;
  priorAuthorizationTypeCode?: InputMaybe<IntegerOverrideInput>;
  productIdQualifier?: InputMaybe<StringOverrideInput>;
  submissionClarification?: InputMaybe<IntegerArrayOverrideInput>;
};

export type OrderInsuranceResponse = {
  __typename?: "OrderInsuranceResponse";
  insurance: Maybe<Insurance>;
  order: Maybe<Order>;
};

export type OrderMedicationSearchResult = {
  __typename?: "OrderMedicationSearchResult";
  _id: Scalars["String"]["output"];
  count: Maybe<Scalars["Int"]["output"]>;
};

export type OrderPricing = {
  __typename?: "OrderPricing";
  tax: Maybe<Scalars["Float"]["output"]>;
  total: Maybe<Scalars["Float"]["output"]>;
};

export type OrderShipment = {
  __typename?: "OrderShipment";
  shipment: Maybe<OrderShippingLabel>;
};

export type OrderShippingLabel = {
  __typename?: "OrderShippingLabel";
  service_level: Maybe<Scalars["String"]["output"]>;
  tracking_number: Maybe<Scalars["String"]["output"]>;
};

export type OrderTriage = {
  __typename?: "OrderTriage";
  _id: Scalars["ID"]["output"];
  details: Maybe<TriageDetails>;
  duplicateOrderId: Maybe<Scalars["ID"]["output"]>;
  endDate: Maybe<Scalars["String"]["output"]>;
  fillId: Maybe<Scalars["ID"]["output"]>;
  inTriageUserMessage: Maybe<Scalars["String"]["output"]>;
  prescriptionId: Maybe<Scalars["ID"]["output"]>;
  reason: Scalars["String"]["output"];
  removeFromTriageUserMessage: Maybe<Scalars["String"]["output"]>;
  rxFillRequestId: Maybe<Scalars["ID"]["output"]>;
  startDate: Scalars["String"]["output"];
  userPlaced: Maybe<User>;
  userPlacedInTriage: Maybe<Scalars["ID"]["output"]>;
  userRemoved: Maybe<User>;
  userRemovedFromTriage: Maybe<Scalars["ID"]["output"]>;
};

export type OrderTriageReasonSearchResult = {
  __typename?: "OrderTriageReasonSearchResult";
  _id: Scalars["String"]["output"];
};

export type OrderUpdate = {
  __typename?: "OrderUpdate";
  operation: Maybe<Scalars["String"]["output"]>;
  order: Maybe<Order>;
};

export type OrderUpdatePatch = {
  isReplacement?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservedForInvestigation?: InputMaybe<Scalars["Boolean"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  paymentType?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  shippingMethod?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrderUser = {
  __typename?: "OrderUser";
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  userId: Maybe<Scalars["String"]["output"]>;
};

export type OrderWithPaging = {
  __typename?: "OrderWithPaging";
  currentPage: Maybe<Scalars["Int"]["output"]>;
  orders: Maybe<Array<Maybe<Order>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type Otc = {
  __typename?: "Otc";
  _id: Maybe<Scalars["ID"]["output"]>;
  customerId: Maybe<Scalars["ID"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  sku: Maybe<Scalars["String"]["output"]>;
  upc: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type OtcPricing = {
  __typename?: "OtcPricing";
  cost: Maybe<Scalars["Float"]["output"]>;
  discount: Maybe<Scalars["Float"]["output"]>;
  shippingCost: Maybe<Scalars["Float"]["output"]>;
  tax: Maybe<Scalars["Float"]["output"]>;
  total: Maybe<Scalars["Float"]["output"]>;
};

export type OtcProduct = {
  __typename?: "OtcProduct";
  _id: Scalars["ID"]["output"];
  barcode: Maybe<Scalars["String"]["output"]>;
  bundleId: Maybe<Scalars["String"]["output"]>;
  bundleItemPartialReturnAllowed: Maybe<Scalars["Boolean"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  image: Maybe<Document>;
  name: Maybe<Scalars["String"]["output"]>;
  otcStatus: Maybe<Scalars["String"]["output"]>;
  overrideScan: Maybe<Scalars["Boolean"]["output"]>;
  pricing: Maybe<OtcPricing>;
  quantity: Scalars["Int"]["output"];
  quantityShipped: Maybe<Scalars["Int"]["output"]>;
  rxFillRequestId: Maybe<Scalars["String"]["output"]>;
  sku: Maybe<Scalars["String"]["output"]>;
  trackingNumber: Maybe<Scalars["String"]["output"]>;
  trackingUrl: Maybe<Scalars["String"]["output"]>;
};

export type OtherAmountClaimsInput = {
  otherAmountClaimSubmitted?: InputMaybe<Scalars["Int"]["input"]>;
  otherAmountClaimSubmittedQualifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type OtherMedicationDate = {
  __typename?: "OtherMedicationDate";
  otherMedicationDate: Maybe<Scalars["Date"]["output"]>;
  otherMedicationDateQualifier: Maybe<Scalars["String"]["output"]>;
};

export type OutboundScan = {
  __typename?: "OutboundScan";
  coreOrderId: Scalars["Int"]["output"];
  order: Maybe<Order>;
  scanAt: Scalars["String"]["output"];
  trackingNumber: Scalars["String"]["output"];
};

export type OutboundScanType = "fulfillment" | "tool";

export type OutboundTransfer = {
  __typename?: "OutboundTransfer";
  _id: Maybe<Scalars["ID"]["output"]>;
  completedByUser: Maybe<User>;
  completedByUserId: Maybe<Scalars["String"]["output"]>;
  coreTransferOutId: Maybe<Scalars["Int"]["output"]>;
  coreTransferOutToken: Maybe<Scalars["String"]["output"]>;
  customer: Maybe<Customer>;
  customerId: Scalars["ID"]["output"];
  location: Maybe<Location>;
  locationId: Scalars["ID"]["output"];
  prescription: Maybe<Prescription>;
  prescriptionId: Scalars["ID"]["output"];
  reason: Scalars["String"]["output"];
  rejectionDetails: Maybe<RejectionDetails>;
  source: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  transferTo: Maybe<OutboundTransferTransferTo>;
  triages: Maybe<Array<Maybe<OutboundTransferTriage>>>;
};

export type OutboundTransferTransferTo = {
  __typename?: "OutboundTransferTransferTo";
  address: Address;
  fax: Maybe<Scalars["String"]["output"]>;
  pharmacistName: Maybe<Scalars["String"]["output"]>;
  pharmacy: Scalars["String"]["output"];
  pharmacyNPI: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type OutboundTransferTriage = {
  __typename?: "OutboundTransferTriage";
  _id: Maybe<Scalars["ID"]["output"]>;
  code: Scalars["String"]["output"];
  endDate: Maybe<Scalars["Date"]["output"]>;
  message: Maybe<Scalars["String"]["output"]>;
  outboundTransferId: Maybe<Scalars["ID"]["output"]>;
  reason: Scalars["String"]["output"];
  startDate: Scalars["Date"]["output"];
  userPlaced: Maybe<User>;
  userPlacedInTriage: Scalars["ID"]["output"];
  userRemoved: Maybe<User>;
  userRemovedFromTriage: Maybe<Scalars["ID"]["output"]>;
};

export type Pv1Verification = {
  __typename?: "PV1Verification";
  patient: Maybe<VerificationPatient>;
  pharmacistId: Maybe<Scalars["String"]["output"]>;
  prescriber: Maybe<VerificationPrescriber>;
  skipped: Maybe<Scalars["Boolean"]["output"]>;
  verifiedAt: Maybe<Scalars["Date"]["output"]>;
};

export type Pv2Verification = {
  __typename?: "PV2Verification";
  pharmacistId: Maybe<Scalars["String"]["output"]>;
  verifiedAt: Maybe<Scalars["Date"]["output"]>;
};

export type PackingMaterial = {
  __typename?: "PackingMaterial";
  _id: Maybe<Scalars["ID"]["output"]>;
  itemName: Maybe<Scalars["String"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  sku: Maybe<Scalars["String"]["output"]>;
  upc: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type PackingMaterialItem = {
  __typename?: "PackingMaterialItem";
  _id: Maybe<Scalars["ID"]["output"]>;
  overrideScan: Maybe<Scalars["Boolean"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  sku: Maybe<Scalars["String"]["output"]>;
};

export type Padding = {
  __typename?: "Padding";
  bottom: Maybe<Scalars["Float"]["output"]>;
  left: Maybe<Scalars["Float"]["output"]>;
  right: Maybe<Scalars["Float"]["output"]>;
  top: Maybe<Scalars["Float"]["output"]>;
};

export type PaddingInput = {
  bottom?: InputMaybe<Scalars["Float"]["input"]>;
  left?: InputMaybe<Scalars["Float"]["input"]>;
  right?: InputMaybe<Scalars["Float"]["input"]>;
  top?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageViewAttributes = {
  copayRequestId?: InputMaybe<Scalars["ID"]["input"]>;
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PaginatedLogs = {
  __typename?: "PaginatedLogs";
  limit: Maybe<Scalars["Int"]["output"]>;
  logs: Array<Maybe<Log>>;
  offset: Maybe<Scalars["Int"]["output"]>;
  total: Maybe<Scalars["Int"]["output"]>;
};

export type PaginatedSessions = {
  __typename?: "PaginatedSessions";
  limit: Maybe<Scalars["Int"]["output"]>;
  offset: Maybe<Scalars["Int"]["output"]>;
  sessions: Array<Maybe<Session>>;
  total: Maybe<Scalars["Int"]["output"]>;
};

export type PaginatedUsers = {
  __typename?: "PaginatedUsers";
  limit: Maybe<Scalars["Int"]["output"]>;
  offset: Maybe<Scalars["Int"]["output"]>;
  total: Maybe<Scalars["Int"]["output"]>;
  users: Array<Maybe<User>>;
};

export type ParataNdc = {
  __typename?: "ParataNdc";
  _id: Scalars["ID"]["output"];
  autobotId: Maybe<Scalars["ID"]["output"]>;
  customerId: Scalars["ID"]["output"];
  isActive: Maybe<Scalars["Boolean"]["output"]>;
  locationId: Scalars["ID"]["output"];
  logs: Maybe<Array<Maybe<Log>>>;
  ndc: Scalars["String"]["output"];
  orderType: Maybe<Scalars["String"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
};

export type ParataNdcOptions = {
  autobotId?: InputMaybe<Scalars["ID"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  orderType?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Patient = {
  __typename?: "Patient";
  _id: Maybe<Scalars["ID"]["output"]>;
  address: Maybe<Addresses>;
  contacts: Maybe<Contact>;
  dob: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  gender: Scalars["String"]["output"];
  guardian: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Array<Maybe<Document>>>;
  insurances: Maybe<Array<Maybe<Insurance>>>;
  lastName: Scalars["String"]["output"];
  logs: Maybe<Array<Maybe<Log>>>;
  medicalHistory: Maybe<DurSubjectInputs>;
  preferences: Maybe<Preferences>;
  prescriptions: Maybe<Array<Maybe<Prescription>>>;
  primaryInsurance: Maybe<Scalars["Int"]["output"]>;
  secondaryInfo: Maybe<SecondaryInfo>;
  species: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  truepillPatientToken: Maybe<Scalars["String"]["output"]>;
  weight: Maybe<Weight>;
};

export type PatientCodifiedNote = {
  __typename?: "PatientCodifiedNote";
  qualifier: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Int"]["output"]>;
};

export type PatientConsent = {
  __typename?: "PatientConsent";
  consentDate: Maybe<Scalars["Date"]["output"]>;
  consentDateStr: Maybe<Scalars["String"]["output"]>;
  consentMethod: Maybe<Scalars["String"]["output"]>;
  consentProvidedBy: Maybe<Scalars["String"]["output"]>;
  consentStatement: Maybe<Scalars["String"]["output"]>;
  consentType: Maybe<Scalars["String"]["output"]>;
  item: Maybe<Scalars["String"]["output"]>;
  patientName: Maybe<Scalars["String"]["output"]>;
  requestType: Maybe<Scalars["String"]["output"]>;
};

export type PatientInput = {
  address?: InputMaybe<AddressInput>;
  contacts?: InputMaybe<ContactInput>;
  dob: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
};

export type PatientInputUpdate = {
  address?: InputMaybe<AddressesInputUpdate>;
  contacts?: InputMaybe<ContactInput>;
  dob?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  guardian?: InputMaybe<Scalars["String"]["input"]>;
  insurances?: InputMaybe<Array<InputMaybe<InsuranceInput>>>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  medicalHistory?: InputMaybe<MedicalHistoryInput>;
  preferences?: InputMaybe<PreferencesInput>;
  species?: InputMaybe<Scalars["String"]["input"]>;
};

export type PatientInsuranceInput = {
  _id?: InputMaybe<Scalars["ID"]["input"]>;
  bin: Scalars["String"]["input"];
  cardholderId: Scalars["String"]["input"];
  designation?: InputMaybe<Scalars["String"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<InsuranceImagesInput>;
  pcn?: InputMaybe<Scalars["String"]["input"]>;
  personCode?: InputMaybe<Scalars["String"]["input"]>;
  relationship?: InputMaybe<Scalars["String"]["input"]>;
};

export type PatientLocation = {
  __typename?: "PatientLocation";
  bed: Maybe<Scalars["String"]["output"]>;
  facilityUnit: Maybe<Scalars["String"]["output"]>;
  room: Maybe<Scalars["String"]["output"]>;
};

export type PatientMandatoryAddress = {
  __typename?: "PatientMandatoryAddress";
  address: Maybe<Address>;
  alternateContact: Maybe<AlternateContact>;
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  dob: Maybe<Scalars["Date"]["output"]>;
  ethnicity: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  formerName: Maybe<Name>;
  gender: Maybe<Scalars["String"]["output"]>;
  gestationalAge: Maybe<Scalars["Int"]["output"]>;
  hin: Maybe<Scalars["String"]["output"]>;
  hospiceIndicator: Maybe<Scalars["Boolean"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  languageNameCode: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  middleName: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  patientLocation: Maybe<PatientLocation>;
  payerID: Maybe<Scalars["String"]["output"]>;
  prefix: Maybe<Scalars["String"]["output"]>;
  pregnancyIndicator: Maybe<Scalars["String"]["output"]>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  race: Maybe<Scalars["String"]["output"]>;
  remsPatientId: Maybe<Scalars["String"]["output"]>;
  residenceCode: Maybe<Scalars["Int"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  substanceUse: Maybe<Array<Maybe<Substances>>>;
  suffix: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type PatientMatch = {
  __typename?: "PatientMatch";
  _id: Maybe<Scalars["ID"]["output"]>;
  address: Maybe<Addresses>;
  candidatePatients: Maybe<Array<Maybe<MatchCandidate>>>;
  contacts: Maybe<Contact>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customer: Maybe<Customer>;
  escriptId: Maybe<Scalars["ID"]["output"]>;
  lock: Maybe<Lock>;
  prePatient: Maybe<PrePatient>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type PatientMatchUpdate = {
  __typename?: "PatientMatchUpdate";
  operation: Maybe<Scalars["String"]["output"]>;
  patientMatch: Maybe<PatientMatch>;
};

export type PatientMatchWithPaging = {
  __typename?: "PatientMatchWithPaging";
  currentPage: Maybe<Scalars["Int"]["output"]>;
  patientMatches: Maybe<Array<Maybe<PatientMatch>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type PatientName = {
  __typename?: "PatientName";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type PatientStatusChangeReason = "OTHER";

export type PatientSurvey = {
  __typename?: "PatientSurvey";
  allergies: Maybe<Scalars["String"]["output"]>;
  conditions: Maybe<Scalars["String"]["output"]>;
  drinkingFrequency: Maybe<Scalars["String"]["output"]>;
  events: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  medications: Maybe<Scalars["String"]["output"]>;
  smokingFrequency: Maybe<Scalars["String"]["output"]>;
};

export type PatientSurveyInput = {
  allergies?: InputMaybe<Scalars["String"]["input"]>;
  conditions?: InputMaybe<Scalars["String"]["input"]>;
  medications?: InputMaybe<Scalars["String"]["input"]>;
};

export type PatientUpdate = {
  __typename?: "PatientUpdate";
  operation: Maybe<Scalars["String"]["output"]>;
  patient: Maybe<Patient>;
};

export type PatientX = {
  __typename?: "PatientX";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type Pharmacist = {
  __typename?: "Pharmacist";
  address: Maybe<Address>;
  businessName: Maybe<Scalars["String"]["output"]>;
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  formerName: Maybe<Name>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  middleName: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  prefix: Maybe<Scalars["String"]["output"]>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  suffix: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type Pharmacy = {
  __typename?: "Pharmacy";
  _id: Scalars["ID"]["output"];
  addresses: Array<Maybe<PharmacyContact>>;
  alternativeOrganizationName: Scalars["String"]["output"];
  authorizedCredential: Maybe<Scalars["String"]["output"]>;
  authorizedFirstName: Maybe<Scalars["String"]["output"]>;
  authorizedLastName: Maybe<Scalars["String"]["output"]>;
  authorizedMiddleName: Maybe<Scalars["String"]["output"]>;
  authorizedNamePrefix: Maybe<Scalars["String"]["output"]>;
  authorizedNameSuffix: Maybe<Scalars["String"]["output"]>;
  npi: Scalars["String"]["output"];
  organizationName: Scalars["String"]["output"];
  practiceAddresses: Array<Maybe<PharmacyContact>>;
  taxonomies: Array<Maybe<Taxonomy>>;
};

export type PharmacyContact = {
  __typename?: "PharmacyContact";
  city: Scalars["String"]["output"];
  country: Maybe<Scalars["String"]["output"]>;
  fax: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  purpose: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  street1: Scalars["String"]["output"];
  street2: Maybe<Scalars["String"]["output"]>;
  zip: Scalars["String"]["output"];
};

export type PhoneType = {
  __typename?: "PhoneType";
  ext: Maybe<Scalars["Int"]["output"]>;
  number: Maybe<Scalars["String"]["output"]>;
  sms: Maybe<Scalars["Boolean"]["output"]>;
};

export type PlanPay = {
  __typename?: "PlanPay";
  billingOrder: Maybe<Scalars["Float"]["output"]>;
  paidAmount: Maybe<Scalars["Float"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type Pod = {
  __typename?: "Pod";
  drugs: Maybe<Array<Maybe<PodDrug>>>;
  id: Scalars["String"]["output"];
  locationLegacyId: Scalars["Int"]["output"];
  podName: Scalars["String"]["output"];
};

export type PodDrug = {
  __typename?: "PodDrug";
  id: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
  podId: Scalars["String"]["output"];
  warehouseLocation: Scalars["String"]["output"];
};

export type PracticeLocationType = {
  __typename?: "PracticeLocationType";
  businessName: Maybe<Scalars["String"]["output"]>;
  identification: Maybe<FacilityIdType>;
};

export type PrePatient = {
  __typename?: "PrePatient";
  address: Maybe<Address>;
  allergies: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  conditions: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  dob: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  gender: Maybe<Scalars["String"]["output"]>;
  lastName: Scalars["String"]["output"];
  medications: Maybe<Scalars["String"]["output"]>;
  payers: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type Preferences = {
  __typename?: "Preferences";
  language: Scalars["String"]["output"];
};

export type PreferencesInput = {
  language: Scalars["String"]["input"];
};

export type PrescribedMedication = {
  __typename?: "PrescribedMedication";
  compoundInformation: Maybe<Scalars["String"]["output"]>;
  currentTreatmentCycle: Maybe<Scalars["Int"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  diabeticSupply: Maybe<DiabeticSupply>;
  diagnosis: Maybe<Diagnosis>;
  doNotFill: Maybe<Scalars["String"]["output"]>;
  drugCoded: Maybe<DrugCoded>;
  drugCoverageStatusCode: Maybe<Scalars["String"]["output"]>;
  drugDescription: Maybe<Scalars["String"]["output"]>;
  drugUseEvaluation: Maybe<Array<Maybe<DrugUseEvaluation>>>;
  facilitySpecificHoursOfAdministrationTiming: Maybe<FacilitySpecificHoursOfAdministrationTiming>;
  flavoringRequested: Maybe<Scalars["Boolean"]["output"]>;
  historyPrescriberOrderNumber: Maybe<Scalars["String"]["output"]>;
  historySource: Maybe<HistorySourceWithoutPayment>;
  injuryRelated: Maybe<Scalars["String"]["output"]>;
  ivAdministration: Maybe<IvAdministrationType>;
  lastFillDate: Maybe<Scalars["Date"]["output"]>;
  lotNumber: Maybe<Scalars["String"]["output"]>;
  manufacturerName: Maybe<Scalars["String"]["output"]>;
  needNoLaterThan: Maybe<NeedNoLaterThan>;
  note: Maybe<Scalars["String"]["output"]>;
  numberOfCyclesPlanned: Maybe<Scalars["Int"]["output"]>;
  numberOfPackagesToBeDispensed: Maybe<Scalars["Int"]["output"]>;
  numberOfRefills: Maybe<Scalars["Int"]["output"]>;
  officeOfPharmacyAffairsID: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderCaptureMethod: Maybe<Scalars["String"]["output"]>;
  otherMedicationDate: Maybe<OtherMedicationDate>;
  patientCodifiedNote: Maybe<PatientCodifiedNote>;
  pharmacy: Maybe<MandatoryPharmacy>;
  pharmacyRequestedRefills: Maybe<Scalars["Int"]["output"]>;
  placeOfServiceNonSelfAdministeredProduct: Maybe<Scalars["Int"]["output"]>;
  prescriber: Maybe<PrescriberGeneral>;
  priorAuthorization: Maybe<Scalars["String"]["output"]>;
  priorAuthorizationStatus: Maybe<Scalars["String"]["output"]>;
  prophylacticOrEpisodic: Maybe<Scalars["String"]["output"]>;
  providerExplicitAuthorizationToAdminister: Maybe<
    Scalars["Boolean"]["output"]
  >;
  quantity: Maybe<Quantity>;
  reasonForSubstitutionCodeUsed: Maybe<Scalars["String"]["output"]>;
  refillsRemaining: Maybe<Scalars["Int"]["output"]>;
  rxFillIndicator: Maybe<Scalars["String"]["output"]>;
  service: Maybe<ServiceStructure>;
  sig: Maybe<Scalars["String"]["output"]>;
  splitScript: Maybe<Scalars["String"]["output"]>;
  substitutions: Maybe<Scalars["String"]["output"]>;
  timeZone: Maybe<TimeZone>;
  titration: Maybe<Titration>;
  treatmentIndicator: Maybe<Scalars["String"]["output"]>;
  wound: Maybe<WoundStructure>;
  writtenDate: Maybe<Scalars["Date"]["output"]>;
};

export type PrescribedMedicationInput = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  daw?: InputMaybe<Scalars["Int"]["input"]>;
  daysSupply?: InputMaybe<Scalars["Int"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  expirationDate?: InputMaybe<Scalars["String"]["input"]>;
  icd10?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
  newAnnotations?: InputMaybe<Array<InputMaybe<LogInput>>>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  numberOfRefills?: InputMaybe<Scalars["Int"]["input"]>;
  prescriberAddressId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  prn?: InputMaybe<Scalars["Boolean"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityUnitOfMeasure?: InputMaybe<Scalars["String"]["input"]>;
  refillsRemaining?: InputMaybe<Scalars["Int"]["input"]>;
  totalQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  writtenDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Prescriber = {
  __typename?: "Prescriber";
  _id: Maybe<Scalars["ID"]["output"]>;
  addresses: Maybe<Array<Maybe<PrescriberAddress>>>;
  changedOnUTCDateTime: Maybe<Scalars["String"]["output"]>;
  classificationID: Maybe<Scalars["String"]["output"]>;
  classificationText: Maybe<Scalars["String"]["output"]>;
  comments: Maybe<Scalars["String"]["output"]>;
  contacts: Maybe<Contacts>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  dea: Maybe<Scalars["String"]["output"]>;
  dps: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  isSupervisor: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  marketerEmployeePioneerRxID: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  npiDoc: Maybe<Npi>;
  prescriberTypeID: Maybe<Scalars["String"]["output"]>;
  prescriberTypeText: Maybe<Scalars["String"]["output"]>;
  primaryPhoneSequenceNumber: Maybe<Scalars["String"]["output"]>;
  primarySpecializationID: Maybe<Scalars["String"]["output"]>;
  primarySpecializationText: Maybe<Scalars["String"]["output"]>;
  serialNumberPerson: Maybe<Scalars["String"]["output"]>;
  stateLicense: Maybe<Scalars["String"]["output"]>;
  suffix: Maybe<Scalars["String"]["output"]>;
};

export type PrescriberAddress = {
  __typename?: "PrescriberAddress";
  _id: Maybe<Scalars["ID"]["output"]>;
  businessName: Maybe<Scalars["String"]["output"]>;
  city: Maybe<Scalars["String"]["output"]>;
  country: Maybe<Scalars["String"]["output"]>;
  fax: Maybe<Scalars["String"]["output"]>;
  isActive: Maybe<Scalars["Boolean"]["output"]>;
  isArchived: Maybe<Scalars["Boolean"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  serviceLevel: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  spi: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
  street1: Maybe<Scalars["String"]["output"]>;
  street2: Maybe<Scalars["String"]["output"]>;
  surescriptsKey: Maybe<Scalars["String"]["output"]>;
  zip: Maybe<Scalars["String"]["output"]>;
};

export type PrescriberGeneral = {
  __typename?: "PrescriberGeneral";
  Address: Maybe<Address>;
};

export type PrescriberIdentification = {
  __typename?: "PrescriberIdentification";
  dea: Maybe<Scalars["String"]["output"]>;
  dps: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  prescriberPioneerRxID: Maybe<Scalars["String"]["output"]>;
  serialNumberPerson: Maybe<Scalars["String"]["output"]>;
  stateLicense: Maybe<Scalars["String"]["output"]>;
};

export type PrescriberInput = {
  address?: InputMaybe<AddressInput>;
  changedOnUTCDateTime?: InputMaybe<Scalars["String"]["input"]>;
  classificationID?: InputMaybe<Scalars["String"]["input"]>;
  classificationText?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  contacts?: InputMaybe<ContactsInput>;
  dea?: InputMaybe<Scalars["String"]["input"]>;
  dps?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isSupervisor?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  marketerEmployeePioneerRxID?: InputMaybe<Scalars["String"]["input"]>;
  npi?: InputMaybe<Scalars["String"]["input"]>;
  prescriberTypeID?: InputMaybe<Scalars["String"]["input"]>;
  prescriberTypeText?: InputMaybe<Scalars["String"]["input"]>;
  primaryPhoneSequenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  primarySpecializationID?: InputMaybe<Scalars["String"]["input"]>;
  primarySpecializationText?: InputMaybe<Scalars["String"]["input"]>;
  serialNumberPerson?: InputMaybe<Scalars["String"]["input"]>;
  stateLicense?: InputMaybe<Scalars["String"]["input"]>;
};

export type PrescriberPractice = {
  _id?: InputMaybe<Scalars["ID"]["input"]>;
  address: AddressInput;
  fax?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
  serviceLevel?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  spi?: InputMaybe<Scalars["String"]["input"]>;
  surescriptsKey?: InputMaybe<Scalars["String"]["input"]>;
};

export type Prescription = {
  __typename?: "Prescription";
  _id: Scalars["ID"]["output"];
  annotations: Maybe<Array<Maybe<Log>>>;
  author: Maybe<User>;
  authorId: Maybe<Scalars["ID"]["output"]>;
  calledInBy: Maybe<Scalars["String"]["output"]>;
  corePrescriptionToken: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customer: Maybe<Customer>;
  customerId: Scalars["ID"]["output"];
  daw: Scalars["Int"]["output"];
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  deaSchedule: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  directTransfer: Maybe<DirectTransfer>;
  directTransferId: Maybe<Scalars["String"]["output"]>;
  directions: Scalars["String"]["output"];
  discontinuedDetails: Maybe<Array<Maybe<PrescriptionTransactionDetails>>>;
  dosageForm: Scalars["String"]["output"];
  effectiveDate: Maybe<Scalars["String"]["output"]>;
  escript: Maybe<Escript>;
  escriptId: Scalars["ID"]["output"];
  expirationDate: Scalars["String"]["output"];
  externalId: Maybe<Scalars["String"]["output"]>;
  fills: Maybe<Array<Maybe<Fill>>>;
  followUpPrescriber: Maybe<Prescriber>;
  followUpPrescriberId: Maybe<Scalars["ID"]["output"]>;
  gcn: Maybe<Scalars["String"]["output"]>;
  handlingTags: Maybe<HandlingTags>;
  icd10: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  icd10s: Maybe<Array<Maybe<Icd10>>>;
  images: Maybe<Array<Maybe<Document>>>;
  isControlledSubstance: Maybe<Scalars["Boolean"]["output"]>;
  isExpired: Maybe<Scalars["Boolean"]["output"]>;
  isPioneerOwned: Scalars["Boolean"]["output"];
  lastEquivalentDrugDispensedInfo: Maybe<LastEquivalentDrugDispensedInfo>;
  locationId: Scalars["ID"]["output"];
  logs: Maybe<Array<Maybe<Log>>>;
  manualRxImage: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  ndc: Scalars["String"]["output"];
  nextFillNumber: Maybe<Scalars["Int"]["output"]>;
  note: Maybe<Scalars["String"]["output"]>;
  numberOfRefills: Scalars["Int"]["output"];
  origin: Scalars["String"]["output"];
  patient: Maybe<Patient>;
  patientId: Scalars["ID"]["output"];
  prescriber: Maybe<Prescriber>;
  prescriberAddressId: Maybe<Scalars["ID"]["output"]>;
  prescriberId: Scalars["ID"]["output"];
  prn: Maybe<Scalars["Boolean"]["output"]>;
  prohibitRenewalRequest: Maybe<Scalars["Boolean"]["output"]>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  quantityRemaining: Scalars["Float"]["output"];
  refillRequests: Maybe<Array<Maybe<RefillRequest>>>;
  refillsRemaining: Maybe<Scalars["Int"]["output"]>;
  reinstatedDetails: Maybe<Array<Maybe<PrescriptionTransactionDetails>>>;
  rxImage: Maybe<Document>;
  rxImageId: Maybe<Scalars["ID"]["output"]>;
  rxNumber: Scalars["Int"]["output"];
  status: Scalars["String"]["output"];
  strength: Maybe<Strength>;
  supervisingPrescriber: Maybe<Prescriber>;
  supervisingPrescriberId: Maybe<Scalars["ID"]["output"]>;
  totalQuantity: Maybe<Scalars["Float"]["output"]>;
  transferredOut: Maybe<Scalars["Boolean"]["output"]>;
  transfers: Maybe<Array<Maybe<Transfer>>>;
  triplicate: Maybe<Scalars["String"]["output"]>;
  upc: Maybe<Scalars["String"]["output"]>;
  verifications: Maybe<Verifications>;
  writtenDate: Scalars["Date"]["output"];
};

export type PrescriptionInput = {
  customer?: InputMaybe<CustomerInput>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  daw: Scalars["Int"]["input"];
  daysSupply?: InputMaybe<Scalars["Int"]["input"]>;
  deaSchedule?: InputMaybe<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  directions?: InputMaybe<Scalars["String"]["input"]>;
  dosageForm: Scalars["String"]["input"];
  fills?: InputMaybe<Array<InputMaybe<FillInput>>>;
  gcn?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
  numberOfRefills?: InputMaybe<Scalars["Int"]["input"]>;
  patient?: InputMaybe<PatientInput>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriber?: InputMaybe<PrescriberInput>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  rxNumber: Scalars["String"]["input"];
  strength?: InputMaybe<StrengthInput>;
  upc?: InputMaybe<Scalars["String"]["input"]>;
  writtenDate: Scalars["String"]["input"];
};

export type PrescriptionMedicationSearchResult = {
  __typename?: "PrescriptionMedicationSearchResult";
  name: Scalars["String"]["output"];
  ndc: Scalars["String"]["output"];
};

export type PrescriptionPartial = {
  __typename?: "PrescriptionPartial";
  _id: Scalars["ID"]["output"];
  author: Maybe<User>;
  authorId: Maybe<Scalars["ID"]["output"]>;
  calledInBy: Maybe<Scalars["String"]["output"]>;
  corePrescriptionToken: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customer: Maybe<Customer>;
  customerId: Scalars["ID"]["output"];
  daw: Scalars["Int"]["output"];
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  deaSchedule: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  directTransfer: Maybe<DirectTransfer>;
  directTransferId: Maybe<Scalars["String"]["output"]>;
  directions: Scalars["String"]["output"];
  discontinuedDetails: Maybe<Array<Maybe<PrescriptionTransactionDetails>>>;
  dosageForm: Scalars["String"]["output"];
  effectiveDate: Maybe<Scalars["String"]["output"]>;
  escript: Maybe<Escript>;
  escriptId: Scalars["ID"]["output"];
  expirationDate: Scalars["String"]["output"];
  externalId: Maybe<Scalars["String"]["output"]>;
  fills: Maybe<Array<Maybe<Fill>>>;
  followUpPrescriberId: Maybe<Scalars["ID"]["output"]>;
  gcn: Maybe<Scalars["String"]["output"]>;
  gpi: Maybe<Scalars["String"]["output"]>;
  handlingTags: Maybe<HandlingTags>;
  icd10: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  icd10s: Maybe<Array<Maybe<Icd10>>>;
  images: Maybe<Array<Maybe<Document>>>;
  isControlledSubstance: Maybe<Scalars["Boolean"]["output"]>;
  isExpired: Maybe<Scalars["Boolean"]["output"]>;
  isPioneerOwned: Scalars["Boolean"]["output"];
  locationId: Scalars["ID"]["output"];
  manualRxImage: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  ndc: Scalars["String"]["output"];
  nextFillNumber: Maybe<Scalars["Int"]["output"]>;
  note: Maybe<Scalars["String"]["output"]>;
  numberOfRefills: Scalars["Int"]["output"];
  origin: Scalars["String"]["output"];
  patient: Maybe<Patient>;
  patientId: Scalars["ID"]["output"];
  prescriber: Maybe<Prescriber>;
  prescriberAddressId: Maybe<Scalars["ID"]["output"]>;
  prescriberId: Scalars["ID"]["output"];
  prn: Maybe<Scalars["Boolean"]["output"]>;
  prohibitRenewalRequest: Maybe<Scalars["Boolean"]["output"]>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  quantityRemaining: Scalars["Float"]["output"];
  refillRequests: Maybe<Array<Maybe<RefillRequest>>>;
  refillsRemaining: Maybe<Scalars["Int"]["output"]>;
  reinstatedDetails: Maybe<Array<Maybe<PrescriptionTransactionDetails>>>;
  rxImage: Maybe<Document>;
  rxImageId: Maybe<Scalars["ID"]["output"]>;
  rxNumber: Scalars["Int"]["output"];
  status: Scalars["String"]["output"];
  strength: Maybe<Strength>;
  supervisingPrescriberId: Maybe<Scalars["ID"]["output"]>;
  totalQuantity: Maybe<Scalars["Float"]["output"]>;
  transferredOut: Maybe<Scalars["Boolean"]["output"]>;
  transfers: Maybe<Array<Maybe<Transfer>>>;
  triplicate: Maybe<Scalars["String"]["output"]>;
  upc: Maybe<Scalars["String"]["output"]>;
  verifications: Maybe<Verifications>;
  writtenDate: Scalars["Date"]["output"];
};

export type PrescriptionTransactionDetails = {
  __typename?: "PrescriptionTransactionDetails";
  note: Maybe<Scalars["String"]["output"]>;
  reason: Scalars["String"]["output"];
  userId: Scalars["ID"]["output"];
};

export type PrescriptionUpdate = {
  __typename?: "PrescriptionUpdate";
  operation: Maybe<Scalars["String"]["output"]>;
  prescription: Maybe<Prescription>;
};

export type PrescriptionUpdateInput = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  icd10?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  prescriberAddressId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  supervisingPrescriberId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PrescriptionX = {
  __typename?: "PrescriptionX";
  corePrescriptionId: Scalars["Int"]["output"];
  corePrescriptionToken: Scalars["String"]["output"];
  medicationName: Maybe<Scalars["String"]["output"]>;
  rxNumber: Scalars["String"]["output"];
  verified: Maybe<Scalars["Boolean"]["output"]>;
};

export type PrescriptionsWithPaging = {
  __typename?: "PrescriptionsWithPaging";
  currentPage: Maybe<Scalars["Int"]["output"]>;
  prescriptions: Maybe<Array<Maybe<PrescriptionPartial>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type PreviewLabel = {
  __typename?: "PreviewLabel";
  s3SignedUrl: Scalars["String"]["output"];
};

export type PricingSpecInput = {
  basisOfCostDetermination?: InputMaybe<Scalars["String"]["input"]>;
  dispensingFeeSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
  flatSalesTaxSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
  grossAmountDue?: InputMaybe<Scalars["Float"]["input"]>;
  incentiveAmountSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
  ingredientCostSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
  otherAmountClaims?: InputMaybe<Array<InputMaybe<OtherAmountClaimsInput>>>;
  patientPayAmountSubmitted?: InputMaybe<Scalars["Float"]["input"]>;
  usualAndCustomaryCharge?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PrintHistory = {
  __typename?: "PrintHistory";
  printedAt: Maybe<Scalars["Date"]["output"]>;
  printedBy: Maybe<Scalars["String"]["output"]>;
  reason: Maybe<Scalars["String"]["output"]>;
  sentTo: Maybe<Scalars["String"]["output"]>;
};

export type PrintMonographResponse = {
  __typename?: "PrintMonographResponse";
  ndc: Maybe<Scalars["String"]["output"]>;
  previewResponse: Maybe<MonographPreview>;
};

export type PrintPickSlipHistory = {
  __typename?: "PrintPickSlipHistory";
  createdAt: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["String"]["output"]>;
  userId: Maybe<Scalars["String"]["output"]>;
  userName: Maybe<Scalars["String"]["output"]>;
};

export type PrintPickSlipResponse = {
  __typename?: "PrintPickSlipResponse";
  error: Maybe<Scalars["String"]["output"]>;
  status: Scalars["Boolean"]["output"];
};

export type Printer = {
  __typename?: "Printer";
  GCPAddress: Maybe<Scalars["String"]["output"]>;
  _id: Maybe<Scalars["ID"]["output"]>;
  calibration: Maybe<Calibration>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  location: Maybe<Location>;
  locationId: Maybe<Scalars["ID"]["output"]>;
  make: Maybe<Scalars["String"]["output"]>;
  printerIP: Maybe<Scalars["String"]["output"]>;
  printerName: Maybe<Scalars["String"]["output"]>;
  printerPurpose: Maybe<Scalars["String"]["output"]>;
  printerType: Maybe<Scalars["String"]["output"]>;
};

export type PrinterStatus = {
  __typename?: "PrinterStatus";
  make: Maybe<Scalars["String"]["output"]>;
  model: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  queueLength: Maybe<Scalars["Int"]["output"]>;
  status: Scalars["String"]["output"];
  toner: Maybe<Supply>;
};

export type PrinterType = "pdf" | "zpl";

export type PrinterUpdate = {
  __typename?: "PrinterUpdate";
  operation: Maybe<Scalars["String"]["output"]>;
  printer: Maybe<Printer>;
};

export type PriorAuthUpdateInput = {
  cmmKey?: InputMaybe<Scalars["String"]["input"]>;
  isManual?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PriorAuthorization = {
  __typename?: "PriorAuthorization";
  _id: Scalars["ID"]["output"];
  actions: Array<Maybe<PriorAuthorizationActionButton>>;
  cmmKey: Maybe<Scalars["String"]["output"]>;
  cmmStatus: Maybe<Scalars["String"]["output"]>;
  copayRequest: Maybe<CopayRequest>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  customerId: Scalars["ID"]["output"];
  expiration_date: Maybe<Scalars["String"]["output"]>;
  fill: Maybe<Fill>;
  initiatedBy: Maybe<PriorAuthorizationInitiatedBy>;
  isManual: Maybe<Scalars["Boolean"]["output"]>;
  key: Maybe<Scalars["String"]["output"]>;
  location: Maybe<Location>;
  locationId: Scalars["ID"]["output"];
  medicationName: Scalars["String"]["output"];
  message: Maybe<Scalars["String"]["output"]>;
  ndc: Scalars["String"]["output"];
  npi: Scalars["String"]["output"];
  order: Maybe<Order>;
  patient: Maybe<Patient>;
  patientId: Scalars["ID"]["output"];
  prescriberId: Scalars["ID"]["output"];
  prescription: Maybe<Prescription>;
  prescriptionId: Scalars["ID"]["output"];
  rxNumber: Scalars["Int"]["output"];
  start_date: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  submission_date: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type PriorAuthorizationActionButton = {
  __typename?: "PriorAuthorizationActionButton";
  buttonLabel: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  isPrimaryAction: Scalars["Boolean"]["output"];
  isRequiredAction: Scalars["Boolean"]["output"];
  url: Scalars["String"]["output"];
};

export type PriorAuthorizationInitiatedBy = {
  __typename?: "PriorAuthorizationInitiatedBy";
  claimId: Scalars["ID"]["output"];
  copayRequestId: Maybe<Scalars["ID"]["output"]>;
  fillId: Scalars["ID"]["output"];
  orderId: Maybe<Scalars["ID"]["output"]>;
  userId: Scalars["ID"]["output"];
};

export type PriorAuthorizationWithPaging = {
  __typename?: "PriorAuthorizationWithPaging";
  currentPage: Maybe<Scalars["Int"]["output"]>;
  priorAuthorizations: Maybe<Array<Maybe<PriorAuthorization>>>;
  totalRecords: Maybe<Scalars["Int"]["output"]>;
};

export type ProblemNameCode = {
  __typename?: "ProblemNameCode";
  code: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
};

export type QualifiedCodeWithText = {
  __typename?: "QualifiedCodeWithText";
  code: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
};

export type Quantity = {
  __typename?: "Quantity";
  codeListQualifier: Maybe<Scalars["String"]["output"]>;
  quantityUnitOfMeasure: Maybe<NciCode>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type QuantityWithoutSecondQuantity = {
  __typename?: "QuantityWithoutSecondQuantity";
  codeListQualifier: Maybe<Scalars["String"]["output"]>;
  quantityUnitOfMeasure: Maybe<NciCode>;
  value: Maybe<Scalars["Int"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  appData: App;
  escriptSearchMedications: Maybe<Array<Maybe<EscriptMedicationSearchResult>>>;
  getAdjudicationRejectCodes: Maybe<AdjudicationRejectCodes>;
  getAllLogs: Array<Maybe<Log>>;
  getAllOrderLogs: Array<Maybe<Log>>;
  getAllPods: Maybe<Array<Maybe<Pod>>>;
  getAllergies: Maybe<Array<Maybe<Allergy>>>;
  getAllowedSubstitutions: Maybe<NdcSubstitutionsPreferred>;
  getApiRequestParams: Maybe<ApiRequestParams>;
  getAutobots: Maybe<Array<Maybe<Autobot>>>;
  getAutofills: Maybe<Array<Maybe<Autofill>>>;
  getConditions: Maybe<Array<Maybe<Condition>>>;
  getCopay: Maybe<CopayRequest>;
  getCopayByCoreCopayToken: Maybe<CopayRequest>;
  getCopayCount: Maybe<Scalars["Int"]["output"]>;
  getCopayCountByStatus: Maybe<CopayCountByStatus>;
  getCopayRequestsWithShortFillCode: Array<Maybe<CopayRequest>>;
  getCopays: Maybe<CopayRequestWithPaging>;
  getCustomer: Maybe<Customer>;
  getCustomers: Maybe<Array<Maybe<Customer>>>;
  getDefaultInventoryGroup: Maybe<InventoryGroup>;
  getDispensingDecisions: Maybe<Array<Maybe<DispensingDecision>>>;
  getDocumentUrl: Maybe<GetDocumentUrlResponse>;
  getDocuments: Array<Document>;
  getDrugImageImprint: Maybe<DrugImageImprint>;
  getEscript: Maybe<Escript>;
  getEscriptIntakeIssuesCount: Maybe<Scalars["Int"]["output"]>;
  getEscriptTriageCount: Maybe<Scalars["Int"]["output"]>;
  getEscriptUnassignedCount: Maybe<Scalars["Int"]["output"]>;
  getEscriptValidationIssueTypes: Maybe<
    Array<Maybe<EscriptValidationIssueTypes>>
  >;
  getEscripts: Maybe<EscriptWithPaging>;
  getExpectedLots: Maybe<ExpectedLots>;
  getExternalTransfer: Maybe<ExternalTransferResponse>;
  getExternalTransfers: Maybe<ExternalTransfersWithPaging>;
  getFillsByStatus: Maybe<StatBankFillStatuses>;
  getFulfillmentCopays: Maybe<CopayRequestWithPaging>;
  getFulfillmentOrders: Maybe<OrderWithPaging>;
  getFulfillmentPriorAuthorizations: Maybe<PriorAuthorizationWithPaging>;
  getInsuranceOptionImages: Maybe<Array<Maybe<OrderInsuranceImages>>>;
  getInsuranceProvider: Maybe<InsuranceProvider>;
  getInventoryAdjustmentsByNdc: Maybe<Array<Maybe<InventoryAdjustment>>>;
  getInventoryFromNdcSubstitution: Maybe<Array<Maybe<NdcInventory>>>;
  getInventoryGroup: Maybe<InventoryGroup>;
  getInventoryGroupByCustomerId: Maybe<InventoryGroup>;
  getInventoryGroupByPioneerAlias: Maybe<InventoryGroup>;
  getJwtToken: Maybe<JwtToken>;
  getLocation: Maybe<Location>;
  getLocations: Maybe<Array<Maybe<Location>>>;
  getLogs: Array<Maybe<Log>>;
  getMedications: Maybe<Array<Maybe<DurSubject>>>;
  getMonograph: Maybe<NdcFullInfoPatientInformation>;
  getNdcAlternateBarCodes: Maybe<Array<Maybe<AlternateBarCodes>>>;
  getNdcFullInfo: Maybe<Array<Maybe<NdcFullInfo>>>;
  getNdcPackage: Maybe<Array<Maybe<NdcFullInfoPackage>>>;
  getNextAdjudication: Maybe<Order>;
  getNextOtcPacking: Maybe<Order>;
  getNextPV1: Maybe<Order>;
  getNextPatientMatch: Maybe<PatientMatch>;
  getNextTriage: Maybe<Order>;
  getNoteByNdc: Maybe<CriticalMedicalNotes>;
  getNotes: Array<Maybe<Log>>;
  getOptumAccount: Maybe<User>;
  getOptumAddresses: Maybe<Array<Maybe<Address>>>;
  getOptumHealthProfiles: Maybe<Array<Maybe<HealthProfile>>>;
  getOptumNotes: Maybe<Array<Maybe<Note>>>;
  getOptumOrder: Maybe<OptumOrderFull>;
  getOptumOrders: Maybe<Array<Maybe<OptumOrder>>>;
  getOptumUserSubscriptions: Maybe<Array<Maybe<OptumSubcription>>>;
  getOrder: Maybe<Order>;
  getOrderByCoreFillRequestToken: Maybe<Order>;
  getOrderByCoreOrderId: Maybe<Order>;
  getOrderByCoreOrderToken: Maybe<Order>;
  getOrderLogs: Maybe<Array<Maybe<Log>>>;
  getOrderPriorAuthorizationCount: Maybe<Scalars["Int"]["output"]>;
  getOrders: Maybe<OrderWithPaging>;
  getOrdersByStatus: Maybe<StatBankOrders>;
  getOrdersCountByAutomationStatus: Maybe<OrderCountByAutomationStatus>;
  getOrdersWithShortFillCode: Array<Maybe<Order>>;
  getOtcBySKUS: Array<Maybe<Otc>>;
  getOtcOnlyOrders: Maybe<StatBankOtcOrders>;
  getOutboundTransfer: Maybe<OutboundTransfer>;
  getPackingMaterialsBySKUS: Array<Maybe<PackingMaterial>>;
  getParataNdcs: Maybe<Array<Maybe<ParataNdc>>>;
  getPatient: Maybe<Patient>;
  getPatientCustomers: Maybe<Array<Maybe<CustomerId>>>;
  getPatientMatch: Maybe<PatientMatch>;
  getPatientMatchCount: Maybe<Scalars["Int"]["output"]>;
  getPatientMatches: Maybe<PatientMatchWithPaging>;
  getPatientPrescriptions: Maybe<Array<Maybe<Tp_Prescription>>>;
  getPharmacy: Maybe<Pharmacy>;
  getPreferredSubstitution: Maybe<GetPreferredSubstitutionResponse>;
  getPrescriber: Maybe<Prescriber>;
  getPrescriberPracticeChanges: Array<Maybe<Log>>;
  getPrescription: Maybe<Prescription>;
  getPrescriptionByFillCode: Maybe<Prescription>;
  getPrescriptionTransferHistoryImage: Maybe<RxImageTransferHistory>;
  getPrescriptionWarnings: Array<Maybe<GetPrescriptionWarnings>>;
  getPrescriptions: PrescriptionsWithPaging;
  getPrescriptionsByIds: Maybe<Array<Prescription>>;
  getPrinter: Maybe<Printer>;
  getPrinterStatus: Maybe<PrinterStatus>;
  getPrinters: Array<Maybe<Printer>>;
  getPriorAuthorization: Maybe<PriorAuthorization>;
  getSigCode: Maybe<SigCode>;
  getSingleNdc: Maybe<DrugInfo>;
  getSyncedPrescriber: Maybe<Prescriber>;
  getTriages: Maybe<StatBankInTriage>;
  getUser: Maybe<User>;
  getUserLogs: Maybe<PaginatedLogs>;
  getUserSessions: PaginatedSessions;
  getUsers: Array<Maybe<User>>;
  getUsersByLocation: Maybe<Array<Maybe<User>>>;
  listInventoryGroups: Maybe<ListInventoryGroupResponse>;
  listPatients: Array<Maybe<Patient>>;
  listSigCodes: Maybe<ListSigCodeResponse>;
  lookupPatient: Maybe<Patient>;
  me: Maybe<User>;
  ping: Scalars["Boolean"]["output"];
  prescriptionSearchMedications: Maybe<
    Array<Maybe<PrescriptionMedicationSearchResult>>
  >;
  searchCopayTriageReasons: Maybe<Array<Maybe<TriageReasonSearchResult>>>;
  searchGroupedNdcInfo: Maybe<Array<Maybe<GroupedNdcInfo>>>;
  searchICD10: Array<Maybe<Icd10>>;
  searchInsuranceProvider: Array<Maybe<InsuranceProvider>>;
  searchInsuranceProviderNew: Array<Maybe<InsuranceProvider>>;
  searchInsuranceProviderPaginated: Maybe<InsurenceProviderWithPagination>;
  searchMedications: Maybe<Array<Maybe<OrderMedicationSearchResult>>>;
  searchNdcFullInfo: Maybe<Array<Maybe<NdcFullInfo>>>;
  searchOptumAccount: Maybe<Array<Maybe<User>>>;
  searchOptumOrders: Maybe<OptumOrderSearch>;
  searchOrderTriageReasons: Maybe<Array<Maybe<OrderTriageReasonSearchResult>>>;
  searchPatients: Maybe<Array<Maybe<Patient>>>;
  searchPharmacies: Maybe<Array<Maybe<Pharmacy>>>;
  searchPharmacists: Array<Maybe<User>>;
  searchPrescribers: Maybe<Array<Maybe<Prescriber>>>;
  searchSigCode: Maybe<SigCodeSearchResult>;
  searchUsers: Maybe<PaginatedUsers>;
  snapfulfilGetAllCustomerReturns: Maybe<
    Array<Maybe<SnapFulfilCustomerReturn>>
  >;
  unifiedSearchPrescribers: Maybe<Array<Maybe<Prescriber>>>;
};

export type QueryEscriptSearchMedicationsArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAdjudicationRejectCodesArgs = {
  batchOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  includeTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  rejectCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetAllLogsArgs = {
  copayRequestId?: InputMaybe<Scalars["ID"]["input"]>;
  escriptId?: InputMaybe<Scalars["ID"]["input"]>;
  event?: InputMaybe<Scalars["String"]["input"]>;
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  isAnnotation?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  payerId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  priorAuthorizationId?: InputMaybe<Scalars["ID"]["input"]>;
  shouldIncludePatientRelatedLogs?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedUserId?: InputMaybe<Scalars["ID"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetAllOrderLogsArgs = {
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetAllPodsArgs = {
  locationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAllergiesArgs = {
  knownAllergies: Scalars["String"]["input"];
};

export type QueryGetAllowedSubstitutionsArgs = {
  locationId: Scalars["ID"]["input"];
  rxId: Scalars["ID"]["input"];
};

export type QueryGetApiRequestParamsArgs = {
  orderId: Scalars["String"]["input"];
};

export type QueryGetAutobotsArgs = {
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetAutofillsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  patientToken?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionToken?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetConditionsArgs = {
  conditions: Scalars["String"]["input"];
};

export type QueryGetCopayArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetCopayByCoreCopayTokenArgs = {
  coreCopayToken: Scalars["String"]["input"];
};

export type QueryGetCopayCountArgs = {
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetCopayCountByStatusArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type QueryGetCopayRequestsWithShortFillCodeArgs = {
  shortFillCode: Scalars["String"]["input"];
};

export type QueryGetCopaysArgs = {
  coreCopayRequestId?: InputMaybe<Scalars["String"]["input"]>;
  coreCopayRequestToken?: InputMaybe<Scalars["String"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  customerRef?: InputMaybe<Scalars["String"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  patientName?: InputMaybe<Scalars["String"]["input"]>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  triageReasons?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetCustomerArgs = {
  customer: Scalars["ID"]["input"];
};

export type QueryGetDispensingDecisionsArgs = {
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetDocumentUrlArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetDocumentsArgs = {
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetDrugImageImprintArgs = {
  ndcPackageCode: Scalars["String"]["input"];
};

export type QueryGetEscriptArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetEscriptValidationIssueTypesArgs = {
  identifiers?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetEscriptsArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filterExternalRxPending?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterTriagedByPatientMatch?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterUnassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  inTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  intakeIssueStatuses?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetExpectedLotsArgs = {
  inventoryId: Scalars["String"]["input"];
};

export type QueryGetExternalTransferArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetExternalTransfersArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filterRequiresPV1?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetFillsByStatusArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetFulfillmentCopaysArgs = {
  coreCopayRequestFillToken?: InputMaybe<Scalars["String"]["input"]>;
  coreCopayRequestToken?: InputMaybe<Scalars["String"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  customerRef?: InputMaybe<Scalars["String"]["input"]>;
  drugName?: InputMaybe<Scalars["String"]["input"]>;
  isBatch?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  triageReasons?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetFulfillmentOrdersArgs = {
  autobotType?: InputMaybe<Scalars["String"]["input"]>;
  completeAutomation?: InputMaybe<Scalars["Boolean"]["input"]>;
  coreFillRequestToken?: InputMaybe<Scalars["String"]["input"]>;
  coreOrderToken?: InputMaybe<Scalars["String"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  customerRef?: InputMaybe<Scalars["String"]["input"]>;
  drugName?: InputMaybe<Scalars["String"]["input"]>;
  fillStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  hasNoFills?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasNoPrints?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasOverdueFills?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasRobotFills?: InputMaybe<Scalars["Boolean"]["input"]>;
  inTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBatch?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOtcOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservedForInvestigation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRxOtcOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSnapLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ndcs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderNumber?: InputMaybe<Scalars["Int"]["input"]>;
  orderStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  patientDob?: InputMaybe<Scalars["String"]["input"]>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  patientName?: InputMaybe<Scalars["String"]["input"]>;
  paymentType?: InputMaybe<Scalars["String"]["input"]>;
  rejectCodeCategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  rejectCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  rxFillCode?: InputMaybe<Scalars["String"]["input"]>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  shippingMethod?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  specialHandlingTags?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  triageReasons?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetFulfillmentPriorAuthorizationsArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  isCopay?: InputMaybe<Scalars["Boolean"]["input"]>;
  isManual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stringSearch?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetInsuranceOptionImagesArgs = {
  copayRequestId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetInsuranceProviderArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetInventoryAdjustmentsByNdcArgs = {
  ndc: Scalars["String"]["input"];
};

export type QueryGetInventoryFromNdcSubstitutionArgs = {
  ndc: Scalars["String"]["input"];
};

export type QueryGetInventoryGroupArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetInventoryGroupByCustomerIdArgs = {
  customerId: Scalars["ID"]["input"];
};

export type QueryGetInventoryGroupByPioneerAliasArgs = {
  pioneerAlias: Scalars["String"]["input"];
};

export type QueryGetLocationArgs = {
  locationId: Scalars["ID"]["input"];
};

export type QueryGetLogsArgs = {
  copayRequestId?: InputMaybe<Scalars["ID"]["input"]>;
  escriptId?: InputMaybe<Scalars["ID"]["input"]>;
  event?: InputMaybe<Scalars["String"]["input"]>;
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  isAnnotation?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetMedicationsArgs = {
  medications: Scalars["String"]["input"];
};

export type QueryGetMonographArgs = {
  ndc: Scalars["String"]["input"];
};

export type QueryGetNdcAlternateBarCodesArgs = {
  ndcs: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetNdcFullInfoArgs = {
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  ndcs: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetNdcPackageArgs = {
  ndcs: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetNextAdjudicationArgs = {
  batchOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  includeTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ndcs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  rejectCodeCategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  rejectCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  specialHandlingTags?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetNextOtcPackingArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type QueryGetNextPv1Args = {
  batchOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ndcs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  paymentType?: InputMaybe<Scalars["String"]["input"]>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  specialHandlingTags?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetNextTriageArgs = {
  batchOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  fillStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  isOtcOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  locationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  medications?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  paymentType?: InputMaybe<Scalars["String"]["input"]>;
  reasons?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  specialHandlingTags?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetNoteByNdcArgs = {
  ndc: Scalars["String"]["input"];
};

export type QueryGetNotesArgs = {
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  payerId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetOptumAccountArgs = {
  email: Scalars["String"]["input"];
};

export type QueryGetOptumAddressesArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetOptumHealthProfilesArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetOptumNotesArgs = {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type QueryGetOptumOrderArgs = {
  orderId: Scalars["String"]["input"];
};

export type QueryGetOptumOrdersArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetOptumUserSubscriptionsArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetOrderArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetOrderByCoreFillRequestTokenArgs = {
  coreFillRequestToken: Scalars["String"]["input"];
};

export type QueryGetOrderByCoreOrderIdArgs = {
  orderNumber: Scalars["Int"]["input"];
};

export type QueryGetOrderByCoreOrderTokenArgs = {
  coreOrderToken: Scalars["String"]["input"];
};

export type QueryGetOrderLogsArgs = {
  orderId: Scalars["ID"]["input"];
};

export type QueryGetOrderPriorAuthorizationCountArgs = {
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetOrdersArgs = {
  coreFillRequestToken?: InputMaybe<Scalars["String"]["input"]>;
  coreOrderToken?: InputMaybe<Scalars["String"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  customerRef?: InputMaybe<Scalars["String"]["input"]>;
  drugName?: InputMaybe<Scalars["String"]["input"]>;
  hasNoFills?: InputMaybe<Scalars["Boolean"]["input"]>;
  inTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastFourDigits?: InputMaybe<Scalars["String"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ndcs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderNumber?: InputMaybe<Scalars["Int"]["input"]>;
  orderStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  patientDob?: InputMaybe<Scalars["String"]["input"]>;
  patientName?: InputMaybe<Scalars["String"]["input"]>;
  paymentType?: InputMaybe<Scalars["String"]["input"]>;
  rxFillCode?: InputMaybe<Scalars["String"]["input"]>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  triageReasons?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetOrdersByStatusArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetOrdersCountByAutomationStatusArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  selectedLocationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type QueryGetOrdersWithShortFillCodeArgs = {
  shortFillCode: Scalars["String"]["input"];
};

export type QueryGetOtcBySkusArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  skus?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetOtcOnlyOrdersArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetOutboundTransferArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetPackingMaterialsBySkusArgs = {
  skus?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetParataNdcsArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetPatientArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetPatientCustomersArgs = {
  patientId: Scalars["ID"]["input"];
};

export type QueryGetPatientMatchArgs = {
  patientMatchId: Scalars["ID"]["input"];
};

export type QueryGetPatientMatchesArgs = {
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetPatientPrescriptionsArgs = {
  patientToken: Scalars["String"]["input"];
};

export type QueryGetPharmacyArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetPreferredSubstitutionArgs = {
  locationId: Scalars["ID"]["input"];
  ndc: Scalars["String"]["input"];
  prescriptionId: Scalars["ID"]["input"];
};

export type QueryGetPrescriberArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  npi?: InputMaybe<Scalars["String"]["input"]>;
  stateLicense?: InputMaybe<Scalars["String"]["input"]>;
  stateLicenseState?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetPrescriberPracticeChangesArgs = {
  prescriberId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriberPracticeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetPrescriptionArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetPrescriptionByFillCodeArgs = {
  rxFillCode: Scalars["String"]["input"];
};

export type QueryGetPrescriptionTransferHistoryImageArgs = {
  prescriptionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetPrescriptionWarningsArgs = {
  prescriptionId: Scalars["String"]["input"];
};

export type QueryGetPrescriptionsArgs = {
  corePrescriptionToken?: InputMaybe<Scalars["String"]["input"]>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  fillable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRobotFill?: InputMaybe<Scalars["Boolean"]["input"]>;
  medicationNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ndcs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  prescriberIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  rxFillCode?: InputMaybe<Scalars["String"]["input"]>;
  rxNumber?: InputMaybe<Scalars["Int"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetPrescriptionsByIdsArgs = {
  filterStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetPrinterArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetPrinterStatusArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetPrintersArgs = {
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  printerIP?: InputMaybe<Scalars["String"]["input"]>;
  printerName?: InputMaybe<Scalars["String"]["input"]>;
  printerPurpose?: InputMaybe<Scalars["String"]["input"]>;
  printerType?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetPriorAuthorizationArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetSigCodeArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSingleNdcArgs = {
  ndc: Scalars["String"]["input"];
};

export type QueryGetSyncedPrescriberArgs = {
  npi?: InputMaybe<Scalars["String"]["input"]>;
  prescriberId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetTriagesArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetUserArgs = {
  _id: Scalars["ID"]["input"];
};

export type QueryGetUserLogsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserSessionsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  userId: Scalars["ID"]["input"];
};

export type QueryGetUsersByLocationArgs = {
  locationId: Scalars["ID"]["input"];
};

export type QueryListInventoryGroupsArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryListSigCodesArgs = {
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLookupPatientArgs = {
  dob: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
};

export type QueryPrescriptionSearchMedicationsArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchCopayTriageReasonsArgs = {
  fillStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  locationId: Scalars["ID"]["input"];
  orderStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QuerySearchGroupedNdcInfoArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchIcd10Args = {
  term: Scalars["String"]["input"];
};

export type QuerySearchInsuranceProviderArgs = {
  bin: Scalars["String"]["input"];
};

export type QuerySearchInsuranceProviderNewArgs = {
  input?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchInsuranceProviderPaginatedArgs = {
  input?: InputMaybe<Scalars["String"]["input"]>;
  pageNumber: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
};

export type QuerySearchMedicationsArgs = {
  fillStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  inTriage?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  orderStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QuerySearchNdcFullInfoArgs = {
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchOptumAccountArgs = {
  dob: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
};

export type QuerySearchOptumOrdersArgs = {
  descending?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  guestCheckoutOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageCount?: InputMaybe<Scalars["Int"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  total?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchOrderTriageReasonsArgs = {
  fillStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  locationId: Scalars["ID"]["input"];
  orderStages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QuerySearchPatientsArgs = {
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  species?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchPharmaciesArgs = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  npi?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchPharmacistsArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchPrescribersArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchSigCodeArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchUsersArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryUnifiedSearchPrescribersArgs = {
  search: Scalars["String"]["input"];
};

export type RawProductPackageInfo = {
  __typename?: "RawProductPackageInfo";
  NONPROPRIETARYNAME: Maybe<Scalars["String"]["output"]>;
  PROPRIETARYNAME: Maybe<Scalars["String"]["output"]>;
  ROUTENAME: Maybe<Scalars["String"]["output"]>;
};

export type ReceivingPharmacy = {
  address: AddressInput;
  pharmacy: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type RefillRequest = {
  __typename?: "RefillRequest";
  createdAt: Scalars["Date"]["output"];
  delivered: Maybe<Scalars["Boolean"]["output"]>;
  faxRenewalRequestId: Maybe<Scalars["String"]["output"]>;
  prescriptionId: Maybe<Scalars["String"]["output"]>;
  responses: Maybe<Array<Maybe<RxRenewalResponseMessage>>>;
  rxRenewalRequestId: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
};

export type RejectCode = {
  __typename?: "RejectCode";
  rejectCode: Scalars["String"]["output"];
  rejectMessage: Scalars["String"]["output"];
};

export type RejectCopayRequestFillResponse = {
  __typename?: "RejectCopayRequestFillResponse";
  copayRequestFill: Maybe<CopayRequestFill>;
  reversalsFailed: Maybe<Scalars["Boolean"]["output"]>;
};

export type RejectCopayRequestResponse = {
  __typename?: "RejectCopayRequestResponse";
  copayRequest: Maybe<CopayRequest>;
  reversalsFailed: Maybe<Scalars["Boolean"]["output"]>;
};

export type RejectRxFillRequestResponse = {
  __typename?: "RejectRxFillRequestResponse";
  reversalsFailed: Maybe<Scalars["Boolean"]["output"]>;
  rxFillRequest: Maybe<RxFillRequestRejectionDetails>;
};

export type RejectionDetails = {
  __typename?: "RejectionDetails";
  message: Maybe<Scalars["String"]["output"]>;
  nextFillableDate: Maybe<Scalars["Date"]["output"]>;
  reason: Scalars["String"]["output"];
  reasonCode: Scalars["String"]["output"];
};

export type RemoteAddress = {
  __typename?: "RemoteAddress";
  country: Maybe<Scalars["String"]["output"]>;
  geoLocation: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  ip: Maybe<Scalars["String"]["output"]>;
  region: Maybe<Scalars["String"]["output"]>;
};

export type RemoveTriage = {
  removeFromTriageUserMessage?: InputMaybe<Scalars["String"]["input"]>;
};

export type RequestedDates = {
  __typename?: "RequestedDates";
  endDate: Maybe<Scalars["Date"]["output"]>;
  startDate: Maybe<Scalars["Date"]["output"]>;
};

export type RequestedMedication = {
  __typename?: "RequestedMedication";
  dawCode: Maybe<Scalars["Int"]["output"]>;
  daysSupply: Maybe<Scalars["Int"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  quantityDispensed: Maybe<Scalars["Float"]["output"]>;
  quantityRequested: Maybe<Scalars["Float"]["output"]>;
  requestedDaw: Maybe<Scalars["Int"]["output"]>;
};

export type ResetPasswordResponse = {
  __typename?: "ResetPasswordResponse";
  resetPassword: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User>;
};

export type ResolveDuplicatePrescriptionTriagesResponse = {
  __typename?: "ResolveDuplicatePrescriptionTriagesResponse";
  successful: Array<Maybe<Scalars["ID"]["output"]>>;
  unsuccessful: Array<Maybe<Scalars["ID"]["output"]>>;
};

export type ResumeAdministration = {
  __typename?: "ResumeAdministration";
  resumeDateTime: Maybe<Scalars["Date"]["output"]>;
  timeZone: Maybe<TimeZone>;
};

export type ReturnOrder = {
  __typename?: "ReturnOrder";
  _id: Scalars["ID"]["output"];
  rxFillRequests: Maybe<Array<Maybe<RxFillRequestInReturnOrder>>>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type ReversalDetails = {
  __typename?: "ReversalDetails";
  note: Maybe<Scalars["String"]["output"]>;
  reason: Scalars["String"]["output"];
  reversedAt: Scalars["Date"]["output"];
};

export type Role =
  | "admin"
  | "compliance"
  | "customerSupport"
  | "developer"
  | "leadCustomerSupport"
  | "leadPharmacist"
  | "leadWarehouse"
  | "pharmacist"
  | "technician"
  | "warehouse";

export type RxChangeImageUrl = {
  __typename?: "RxChangeImageUrl";
  s3SignedUrl: Scalars["String"]["output"];
};

export type RxChangeTriage = {
  details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ndc?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  sendToSurescripts?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RxFillRequest = {
  __typename?: "RxFillRequest";
  _id: Scalars["ID"]["output"];
  activePriorAuthorization: Maybe<PriorAuthorization>;
  adjudication: Maybe<RxFillRequestAdjudication>;
  cobOverrides: Maybe<Array<Maybe<RxFillRequestCobOverride>>>;
  copayPayment: Maybe<CopayPayment>;
  coverMyMedsKey: Maybe<Scalars["String"]["output"]>;
  dispensed: Maybe<DispensedMedication>;
  durs: Maybe<Array<Maybe<RxFillRequestDur>>>;
  ediOverrides: Maybe<Array<Maybe<EdiOverrides>>>;
  ediReversalOverrides: Maybe<Array<Maybe<EdiReversalOverrides>>>;
  fill: Maybe<Fill>;
  fillId: Maybe<Scalars["ID"]["output"]>;
  isReAttempt: Maybe<IsReAttemptType>;
  machine: Maybe<MachineOptions>;
  otcProductId: Maybe<Scalars["ID"]["output"]>;
  overrideCodes: Maybe<Array<Maybe<RxFillRequestOverrideCodes>>>;
  paymentType: Maybe<RxFillRequestPaymentType>;
  prescription: Maybe<Prescription>;
  prescriptionId: Scalars["ID"]["output"];
  quantity: Maybe<Scalars["Float"]["output"]>;
  rejectionDetails: Maybe<RejectionDetails>;
  requestedMedication: Maybe<RequestedMedication>;
  requestedMedicationName: Maybe<Scalars["String"]["output"]>;
  specialHandlingTags: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type RxFillRequestAdjudication = {
  __typename?: "RxFillRequestAdjudication";
  claimJumperErrors: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  claimRunDate: Maybe<Scalars["Date"]["output"]>;
  lastClaimRejectCodes: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  runSuccessful: Maybe<Scalars["Boolean"]["output"]>;
};

export type RxFillRequestCobOverride = {
  __typename?: "RxFillRequestCOBOverride";
  benefitStageAmount: Maybe<AmountArrayOverrideCob>;
  orderInsuranceOptionId: Maybe<Scalars["String"]["output"]>;
  otherPayerAmountPaid: Maybe<AmountArrayOverrideCob>;
  otherPayerDate: Maybe<DateOverride>;
  otherPayerId: Maybe<StringOverrideCob>;
  otherPayerPatientResponsibilityAmount: Maybe<AmountArrayOverrideCob>;
  otherPayerRejectCode: Maybe<StringArrayOverrideCob>;
};

export type RxFillRequestDur = {
  __typename?: "RxFillRequestDur";
  coAgentId: Maybe<Scalars["String"]["output"]>;
  coAgentQualifier: Maybe<Scalars["String"]["output"]>;
  levelOfEffort: Maybe<Scalars["String"]["output"]>;
  orderInsuranceOptionId: Scalars["String"]["output"];
  professionalService: Maybe<Scalars["String"]["output"]>;
  reasonForService: Maybe<Scalars["String"]["output"]>;
  resultOfService: Maybe<Scalars["String"]["output"]>;
};

export type RxFillRequestInReturnOrder = {
  __typename?: "RxFillRequestInReturnOrder";
  _id: Scalars["ID"]["output"];
  fillId: Maybe<Scalars["ID"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type RxFillRequestOverrideCodes = {
  __typename?: "RxFillRequestOverrideCodes";
  levelOfService: Maybe<IntegerOverride>;
  orderInsuranceOptionId: Scalars["String"]["output"];
  otherCoverageCode: Maybe<IntegerOverride>;
  priorAuthorizationNumberSubmitted: Maybe<Scalars["String"]["output"]>;
  priorAuthorizationTypeCode: Maybe<IntegerOverride>;
  productIdQualifier: Maybe<StringOverride>;
  submissionClarification: Maybe<IntegerArrayOverride>;
};

export type RxFillRequestPaymentType = "cash" | "insurance";

export type RxFillRequestRejectionDetails = {
  __typename?: "RxFillRequestRejectionDetails";
  rejectionDetails: Maybe<RejectionDetails>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type RxImageTransferHistory = {
  __typename?: "RxImageTransferHistory";
  s3SignedUrl: Scalars["String"]["output"];
};

export type RxNumber = {
  __typename?: "RxNumber";
  rxNumber: Scalars["String"]["output"];
};

export type RxRenewalResponse = {
  __typename?: "RxRenewalResponse";
  status: Scalars["String"]["output"];
};

export type RxRenewalResponseMessage = {
  __typename?: "RxRenewalResponseMessage";
  message: Scalars["String"]["output"];
  receivedAt: Scalars["Date"]["output"];
  rxRenewalResponseId: Maybe<Scalars["String"]["output"]>;
  surescriptsErrorMessageId: Maybe<Scalars["String"]["output"]>;
  surescriptsStatusMessageId: Maybe<Scalars["String"]["output"]>;
  surescriptsVerifyMessageId: Maybe<Scalars["String"]["output"]>;
};

export type S3Document = {
  __typename?: "S3Document";
  bucket: Maybe<Scalars["String"]["output"]>;
  key: Maybe<Scalars["String"]["output"]>;
};

export type SnomedAdverseEventCode = {
  __typename?: "SNOMEDAdverseEventCode";
  code: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
};

export type SnomedCode = {
  __typename?: "SNOMEDCode";
  code: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
};

export type SnomedLateralityCode = {
  __typename?: "SNOMEDLateralityCode";
  code: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
};

export type ScannedTaskInput = {
  sku?: InputMaybe<Scalars["String"]["input"]>;
  upc?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecondaryInfo = {
  __typename?: "SecondaryInfo";
  addresses: Maybe<Array<Maybe<Address>>>;
  emails: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  names: Maybe<Array<Maybe<PatientName>>>;
  phoneNumbers: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type SendOptumPasswordResetEmailResponse = {
  __typename?: "SendOptumPasswordResetEmailResponse";
  _id: Maybe<Scalars["ID"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
};

export type SentToAutomationResponse = {
  __typename?: "SentToAutomationResponse";
  error: Maybe<Scalars["String"]["output"]>;
};

export type SentToManualResponse = {
  __typename?: "SentToManualResponse";
  error: Maybe<Scalars["String"]["output"]>;
};

export type ServiceStructure = {
  __typename?: "ServiceStructure";
  agencyOfService: Maybe<AgencyOfServiceType>;
  serviceType: Maybe<ServiceType>;
};

export type ServiceType = {
  __typename?: "ServiceType";
  effectiveDate: Maybe<Scalars["Date"]["output"]>;
  expirationDate: Maybe<Scalars["Date"]["output"]>;
  reasonForMTMService: Maybe<QualifiedCodeWithText>;
  reasonForMTMServiceFreeText: Maybe<Scalars["String"]["output"]>;
  targetedTypeOfService: Maybe<QualifiedCodeWithText>;
  targetedTypeOfServiceFreeText: Maybe<Scalars["String"]["output"]>;
  typeOfService: Maybe<QualifiedCodeWithText>;
  typeOfServiceFreeText: Maybe<Scalars["String"]["output"]>;
  typeOfServiceGroupSetting: Maybe<Scalars["String"]["output"]>;
};

export type Session = {
  __typename?: "Session";
  _id: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  expiresAt: Maybe<Scalars["String"]["output"]>;
  isRevoked: Maybe<Scalars["Boolean"]["output"]>;
  remoteAddresses: Maybe<Array<Maybe<RemoteAddress>>>;
  userId: Maybe<Scalars["ID"]["output"]>;
};

export type Shipment = {
  __typename?: "Shipment";
  trackingNumber: Maybe<Scalars["String"]["output"]>;
};

export type ShippingData = {
  city: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  shippingCharge?: InputMaybe<Scalars["String"]["input"]>;
  shippingSpeed: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  street1: Scalars["String"]["input"];
  street2?: InputMaybe<Scalars["String"]["input"]>;
  zip: Scalars["String"]["input"];
};

export type ShippingLabelReprintInformation = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  reason: Scalars["String"]["input"];
};

export type SigCode = {
  __typename?: "SigCode";
  _id: Maybe<Scalars["ID"]["output"]>;
  active: Maybe<Scalars["Boolean"]["output"]>;
  code: Scalars["String"]["output"];
  exceptionNDC: Maybe<Array<Maybe<ExceptionNdc>>>;
  multiplier: Maybe<Scalars["Float"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
  translations: SigCodeTranslation;
};

export type SigCodeGetResult = {
  __typename?: "SigCodeGetResult";
  code: Scalars["String"]["output"];
  exceptionNDC: Maybe<Array<Maybe<ExceptionNdc>>>;
  multiplier: Maybe<Scalars["Float"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
  translations: Maybe<SigCodeTranslation>;
};

export type SigCodeSearchResult = {
  __typename?: "SigCodeSearchResult";
  accurateTranslation: Scalars["Boolean"]["output"];
  code: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SigCodeTranslation = {
  __typename?: "SigCodeTranslation";
  en: Maybe<Scalars["String"]["output"]>;
  es: Maybe<Scalars["String"]["output"]>;
};

export type SkuEntry = {
  _id: Scalars["ID"]["input"];
  sku: Scalars["String"]["input"];
};

export type SnapFulfilCustomerReturn = {
  __typename?: "SnapFulfilCustomerReturn";
  ASNInd: Maybe<Scalars["String"]["output"]>;
  ASNumber: Maybe<Scalars["String"]["output"]>;
  AccepanceCode: Maybe<Scalars["String"]["output"]>;
  ActualWeight: Maybe<Scalars["Float"]["output"]>;
  BOL: Maybe<Scalars["String"]["output"]>;
  BizId: Maybe<Scalars["String"]["output"]>;
  BizPurchaseOrder: Maybe<Scalars["String"]["output"]>;
  BizSupplierId: Maybe<Scalars["String"]["output"]>;
  CarrierId: Maybe<Scalars["String"]["output"]>;
  CarrierScac: Maybe<Scalars["String"]["output"]>;
  CarrierTrackingNumber: Maybe<Scalars["String"]["output"]>;
  ConsignmentId: Maybe<Scalars["String"]["output"]>;
  DC: Maybe<Scalars["String"]["output"]>;
  DateArrival: Maybe<Scalars["String"]["output"]>;
  DateClosed: Maybe<Scalars["String"]["output"]>;
  DateCollected: Maybe<Scalars["String"]["output"]>;
  DateCreated: Maybe<Scalars["String"]["output"]>;
  DateDueIn: Maybe<Scalars["String"]["output"]>;
  DateReceipt: Maybe<Scalars["String"]["output"]>;
  DateSuspended: Maybe<Scalars["String"]["output"]>;
  GRNNumber: Maybe<Scalars["String"]["output"]>;
  InvoiceAddressId: Maybe<Scalars["String"]["output"]>;
  LineQty: Maybe<Scalars["Float"]["output"]>;
  Lines: Maybe<Scalars["Float"]["output"]>;
  LoadId: Maybe<Scalars["String"]["output"]>;
  LoadSequence: Maybe<Scalars["Float"]["output"]>;
  MaintInd: Maybe<Scalars["String"]["output"]>;
  ManifestNumber: Maybe<Scalars["String"]["output"]>;
  MasterBOL: Maybe<Scalars["String"]["output"]>;
  OrderClass: Maybe<Scalars["String"]["output"]>;
  OrderType: Maybe<Scalars["String"]["output"]>;
  OverdueInd: Maybe<Scalars["String"]["output"]>;
  OwnerId: Maybe<Scalars["String"]["output"]>;
  POC: Maybe<Scalars["String"]["output"]>;
  PaperworkInd: Maybe<Scalars["String"]["output"]>;
  Prime: Maybe<Scalars["String"]["output"]>;
  Priority: Maybe<Scalars["String"]["output"]>;
  PurchaseOrder: Maybe<Scalars["String"]["output"]>;
  QA: Maybe<Scalars["String"]["output"]>;
  Reason: Maybe<Scalars["String"]["output"]>;
  ReceiptAddresses: Maybe<Array<Maybe<SnapFulfilReceiptAddress>>>;
  ReceiptId: Scalars["String"]["output"];
  ReceiptLane: Maybe<Scalars["String"]["output"]>;
  ReceiptLines: Maybe<Array<Maybe<SnapFulfilReceiptLine>>>;
  Region: Maybe<Scalars["String"]["output"]>;
  ReturnReason: Maybe<Scalars["String"]["output"]>;
  Route: Maybe<Scalars["String"]["output"]>;
  ShippingAddressId: Maybe<Scalars["String"]["output"]>;
  ShippingMethod: Maybe<Scalars["String"]["output"]>;
  Site: Maybe<Scalars["String"]["output"]>;
  StUQty: Maybe<Scalars["Float"]["output"]>;
  Stage: Maybe<Scalars["String"]["output"]>;
  Status: Maybe<Scalars["String"]["output"]>;
  StockStatus: Maybe<Scalars["String"]["output"]>;
  Store: Maybe<Scalars["String"]["output"]>;
  Supervisor: Maybe<Scalars["String"]["output"]>;
  SupplierGroup: Maybe<Scalars["String"]["output"]>;
  SupplierId: Scalars["String"]["output"];
  SupplierName: Maybe<Scalars["String"]["output"]>;
  SupplierOrder: Maybe<Scalars["String"]["output"]>;
  SupplierRef: Maybe<Scalars["String"]["output"]>;
  TaskCountActioned: Maybe<Scalars["Float"]["output"]>;
  TaskCountCurrent: Maybe<Scalars["Float"]["output"]>;
  TaskCountNew: Maybe<Scalars["Float"]["output"]>;
  TimeOther: Maybe<Scalars["Float"]["output"]>;
  TimeToCollect: Maybe<Scalars["Float"]["output"]>;
  TimeToInspect: Maybe<Scalars["Float"]["output"]>;
  TimeToPutaway: Maybe<Scalars["Float"]["output"]>;
  TimeToUnload: Maybe<Scalars["Float"]["output"]>;
  Variance: Maybe<Scalars["String"]["output"]>;
  Volume: Maybe<Scalars["Float"]["output"]>;
  Warehouse: Maybe<Scalars["String"]["output"]>;
  Weight: Maybe<Scalars["Float"]["output"]>;
};

export type SnapFulfilCustomerReturnInput = {
  ASNInd?: InputMaybe<Scalars["String"]["input"]>;
  ASNumber?: InputMaybe<Scalars["String"]["input"]>;
  AccepanceCode?: InputMaybe<Scalars["String"]["input"]>;
  ActualWeight?: InputMaybe<Scalars["Float"]["input"]>;
  BOL?: InputMaybe<Scalars["String"]["input"]>;
  BizId?: InputMaybe<Scalars["String"]["input"]>;
  BizPurchaseOrder?: InputMaybe<Scalars["String"]["input"]>;
  BizSupplierId?: InputMaybe<Scalars["String"]["input"]>;
  CarrierId?: InputMaybe<Scalars["String"]["input"]>;
  CarrierScac?: InputMaybe<Scalars["String"]["input"]>;
  CarrierTrackingNumber?: InputMaybe<Scalars["String"]["input"]>;
  ConsignmentId?: InputMaybe<Scalars["String"]["input"]>;
  DC?: InputMaybe<Scalars["String"]["input"]>;
  DateArrival?: InputMaybe<Scalars["String"]["input"]>;
  DateClosed?: InputMaybe<Scalars["String"]["input"]>;
  DateCollected?: InputMaybe<Scalars["String"]["input"]>;
  DateCreated?: InputMaybe<Scalars["String"]["input"]>;
  DateDueIn?: InputMaybe<Scalars["String"]["input"]>;
  DateReceipt: Scalars["String"]["input"];
  DateSuspended?: InputMaybe<Scalars["String"]["input"]>;
  GRNNumber?: InputMaybe<Scalars["String"]["input"]>;
  InvoiceAddressId?: InputMaybe<Scalars["String"]["input"]>;
  LineQty?: InputMaybe<Scalars["Float"]["input"]>;
  Lines?: InputMaybe<Scalars["Float"]["input"]>;
  LoadId?: InputMaybe<Scalars["String"]["input"]>;
  LoadSequence?: InputMaybe<Scalars["Float"]["input"]>;
  MaintInd?: InputMaybe<Scalars["String"]["input"]>;
  ManifestNumber?: InputMaybe<Scalars["String"]["input"]>;
  MasterBOL?: InputMaybe<Scalars["String"]["input"]>;
  OrderClass?: InputMaybe<Scalars["String"]["input"]>;
  OrderType?: InputMaybe<Scalars["String"]["input"]>;
  OverdueInd?: InputMaybe<Scalars["String"]["input"]>;
  OwnerId?: InputMaybe<Scalars["String"]["input"]>;
  POC?: InputMaybe<Scalars["String"]["input"]>;
  PaperworkInd?: InputMaybe<Scalars["String"]["input"]>;
  Prime?: InputMaybe<Scalars["String"]["input"]>;
  Priority?: InputMaybe<Scalars["String"]["input"]>;
  PurchaseOrder?: InputMaybe<Scalars["String"]["input"]>;
  QA?: InputMaybe<Scalars["String"]["input"]>;
  Reason?: InputMaybe<Scalars["String"]["input"]>;
  ReceiptId?: InputMaybe<Scalars["String"]["input"]>;
  ReceiptLane?: InputMaybe<Scalars["String"]["input"]>;
  Region?: InputMaybe<Scalars["String"]["input"]>;
  ReturnReason?: InputMaybe<Scalars["String"]["input"]>;
  Route?: InputMaybe<Scalars["String"]["input"]>;
  ShippingAddressId?: InputMaybe<Scalars["String"]["input"]>;
  ShippingMethod?: InputMaybe<Scalars["String"]["input"]>;
  Site?: InputMaybe<Scalars["String"]["input"]>;
  StUQty?: InputMaybe<Scalars["Float"]["input"]>;
  Stage?: InputMaybe<Scalars["String"]["input"]>;
  Status?: InputMaybe<Scalars["String"]["input"]>;
  StockStatus?: InputMaybe<Scalars["String"]["input"]>;
  Store?: InputMaybe<Scalars["String"]["input"]>;
  Supervisor?: InputMaybe<Scalars["String"]["input"]>;
  SupplierGroup?: InputMaybe<Scalars["String"]["input"]>;
  SupplierId: Scalars["String"]["input"];
  SupplierName?: InputMaybe<Scalars["String"]["input"]>;
  SupplierOrder?: InputMaybe<Scalars["String"]["input"]>;
  SupplierRef?: InputMaybe<Scalars["String"]["input"]>;
  TaskCountActioned?: InputMaybe<Scalars["Float"]["input"]>;
  TaskCountCurrent?: InputMaybe<Scalars["Float"]["input"]>;
  TaskCountNew?: InputMaybe<Scalars["Float"]["input"]>;
  TimeOther?: InputMaybe<Scalars["Float"]["input"]>;
  TimeToCollect?: InputMaybe<Scalars["Float"]["input"]>;
  TimeToInspect?: InputMaybe<Scalars["Float"]["input"]>;
  TimeToPutaway?: InputMaybe<Scalars["Float"]["input"]>;
  TimeToUnload?: InputMaybe<Scalars["Float"]["input"]>;
  Variance?: InputMaybe<Scalars["String"]["input"]>;
  Volume?: InputMaybe<Scalars["Float"]["input"]>;
  Warehouse?: InputMaybe<Scalars["String"]["input"]>;
  Weight?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SnapFulfilReceiptAddress = {
  __typename?: "SnapFulfilReceiptAddress";
  AddressId: Maybe<Scalars["String"]["output"]>;
  City: Maybe<Scalars["String"]["output"]>;
  Country: Maybe<Scalars["String"]["output"]>;
  Latitude: Maybe<Scalars["Float"]["output"]>;
  Line1: Maybe<Scalars["String"]["output"]>;
  Line2: Maybe<Scalars["String"]["output"]>;
  Line3: Maybe<Scalars["String"]["output"]>;
  Longitude: Maybe<Scalars["Float"]["output"]>;
  Name: Maybe<Scalars["String"]["output"]>;
  Postcode: Maybe<Scalars["String"]["output"]>;
  ReceiptId: Maybe<Scalars["String"]["output"]>;
  Region: Maybe<Scalars["String"]["output"]>;
  State: Maybe<Scalars["String"]["output"]>;
  Table: Scalars["String"]["output"];
};

export type SnapFulfilReceiptLine = {
  __typename?: "SnapFulfilReceiptLine";
  Acceptance: Maybe<Scalars["String"]["output"]>;
  AttachmentInd: Maybe<Scalars["String"]["output"]>;
  BOInd: Maybe<Scalars["String"]["output"]>;
  BizId: Maybe<Scalars["String"]["output"]>;
  BizSKUId: Maybe<Scalars["String"]["output"]>;
  Category: Maybe<Scalars["String"]["output"]>;
  ConsignmentId: Maybe<Scalars["String"]["output"]>;
  DateReceipt: Maybe<Scalars["String"]["output"]>;
  Level: Maybe<Scalars["String"]["output"]>;
  Line: Scalars["String"]["output"];
  LineOwner: Maybe<Scalars["String"]["output"]>;
  LineStockStatus: Maybe<Scalars["String"]["output"]>;
  OwnerId: Maybe<Scalars["String"]["output"]>;
  POLineId: Maybe<Scalars["Float"]["output"]>;
  QA: Maybe<Scalars["String"]["output"]>;
  QtyAdvised: Maybe<Scalars["Float"]["output"]>;
  QtyDueIn: Maybe<Scalars["Float"]["output"]>;
  QtyOrdered: Maybe<Scalars["Float"]["output"]>;
  QtyReceived: Maybe<Scalars["Float"]["output"]>;
  QtyRejected: Maybe<Scalars["Float"]["output"]>;
  ReceiptId: Scalars["String"]["output"];
  ReturnReason: Maybe<Scalars["String"]["output"]>;
  SKUId: Scalars["String"]["output"];
  SiteId: Maybe<Scalars["String"]["output"]>;
  SpecialConditionInd: Maybe<Scalars["String"]["output"]>;
  Stage: Maybe<Scalars["String"]["output"]>;
  StockStatus: Maybe<Scalars["String"]["output"]>;
  SupplierSKUId: Maybe<Scalars["String"]["output"]>;
  UPC: Maybe<Scalars["String"]["output"]>;
  UPCDescription: Maybe<Scalars["String"]["output"]>;
  UnitOfMeasure: Maybe<Scalars["String"]["output"]>;
  Variance: Maybe<Scalars["String"]["output"]>;
  Warehouse: Maybe<Scalars["String"]["output"]>;
};

export type SourceQualifier = {
  __typename?: "SourceQualifier";
  reference: Maybe<HistorySourceId>;
  sourceDescription: Maybe<Scalars["String"]["output"]>;
  sourceQualifier: Maybe<Scalars["String"]["output"]>;
};

export type StatBankFillStatuses = {
  __typename?: "StatBankFillStatuses";
  Adjudication: Maybe<StatWithAging>;
  Automation: Maybe<StatWithAging>;
  Fill: Maybe<StatWithAging>;
  PV1: Maybe<StatWithAging>;
  PV2: Maybe<StatWithAging>;
  PreAdjudication: Maybe<StatWithAging>;
  PriorAuthorization: Maybe<StatWithAging>;
};

export type StatBankInTriage = {
  __typename?: "StatBankInTriage";
  inTriage: Maybe<Scalars["Int"]["output"]>;
};

export type StatBankOrders = {
  __typename?: "StatBankOrders";
  completed: Maybe<Scalars["Int"]["output"]>;
  packing: Maybe<Scalars["Int"]["output"]>;
  received: Maybe<Scalars["Int"]["output"]>;
  rejected: Maybe<Scalars["Int"]["output"]>;
};

export type StatBankOtcOrders = {
  __typename?: "StatBankOtcOrders";
  Packing: Maybe<Scalars["Int"]["output"]>;
};

export type StatWithAging = {
  __typename?: "StatWithAging";
  between24And48Hours: Maybe<Scalars["Int"]["output"]>;
  greaterThan48Hours: Maybe<Scalars["Int"]["output"]>;
  total: Maybe<Scalars["Int"]["output"]>;
};

export type StockInfo = {
  __typename?: "StockInfo";
  expirationDate: Maybe<Scalars["String"]["output"]>;
  lot: Maybe<Scalars["String"]["output"]>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  serialNumber: Maybe<Scalars["String"]["output"]>;
};

export type StockInfoInput = {
  expirationDate: Scalars["String"]["input"];
  lot: Scalars["String"]["input"];
  quantity: Scalars["Float"]["input"];
  serialNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Strength = {
  __typename?: "Strength";
  form: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type StrengthInput = {
  form?: InputMaybe<Scalars["String"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringArrayOverrideCob = {
  __typename?: "StringArrayOverrideCob";
  applyTo: Maybe<Scalars["String"]["output"]>;
  send: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type StringArrayOverrideCobInput = {
  applyTo?: InputMaybe<Scalars["String"]["input"]>;
  send?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type StringOverride = {
  __typename?: "StringOverride";
  applyTo: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type StringOverrideCob = {
  __typename?: "StringOverrideCob";
  applyTo: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
  send: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type StringOverrideCobInput = {
  applyTo?: InputMaybe<Scalars["String"]["input"]>;
  qualifier?: InputMaybe<Scalars["String"]["input"]>;
  send?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringOverrideInput = {
  applyTo?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringWithToggle = {
  __typename?: "StringWithToggle";
  send: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type StringWithToggleInput = {
  send?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type StructuredSig = {
  __typename?: "StructuredSig";
  structuredSig: Maybe<Scalars["String"]["output"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  alert: Scalars["String"]["output"];
  customerUpdate: Maybe<CustomerUpdate>;
  locationUpdate: Maybe<LocationUpdate>;
  logUpdate: Maybe<NoteUpdate>;
  orderUpdate: Maybe<OrderUpdate>;
  patientMatchUpdate: Maybe<PatientMatchUpdate>;
  patientUpdate: Maybe<PatientUpdate>;
  prescriptionUpdate: Maybe<PrescriptionUpdate>;
  printerUpdate: Maybe<PrinterUpdate>;
  time: Scalars["String"]["output"];
  userUpdate: Maybe<UserUpdate>;
};

export type SubscriptionLogUpdateArgs = {
  fillId?: InputMaybe<Scalars["ID"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  payerId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SubscriptionOrderUpdateArgs = {
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type SubscriptionPatientMatchUpdateArgs = {
  patientMatchId?: InputMaybe<Scalars["ID"]["input"]>;
  patientMatchIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type SubscriptionPatientUpdateArgs = {
  patientId?: InputMaybe<Scalars["ID"]["input"]>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type SubscriptionPrescriptionUpdateArgs = {
  prescriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  prescriptionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type Substances = {
  __typename?: "Substances";
  level: Maybe<SigCode>;
  routeOfAdministration: Maybe<SigCode>;
  type: Maybe<SigCode>;
};

export type SubstitutionInput = {
  dosage?: InputMaybe<Scalars["String"]["input"]>;
  labeler?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  ndcCode?: InputMaybe<Scalars["String"]["input"]>;
  routeName?: InputMaybe<Scalars["String"]["input"]>;
  strength?: InputMaybe<Scalars["String"]["input"]>;
  substance?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupervisorMandatoryAddress = {
  __typename?: "SupervisorMandatoryAddress";
  address: Maybe<Address>;
  certificateToPrescribe: Maybe<Scalars["String"]["output"]>;
  communicationNumbers: Maybe<CommunicationNumbers>;
  data2000WaiverID: Maybe<Scalars["String"]["output"]>;
  deaNumber: Maybe<Scalars["String"]["output"]>;
  facilityID: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  formerName: Maybe<Name>;
  hin: Maybe<Scalars["String"]["output"]>;
  iinNumber: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  medicaidNumber: Maybe<Scalars["String"]["output"]>;
  medicalRecordIdentificationNumberEHR: Maybe<Scalars["String"]["output"]>;
  medicareNumber: Maybe<Scalars["String"]["output"]>;
  mutuallyDefined: Maybe<Scalars["String"]["output"]>;
  naicCode: Maybe<Scalars["String"]["output"]>;
  ncpdpi: Maybe<Scalars["String"]["output"]>;
  npi: Maybe<Scalars["String"]["output"]>;
  patientAccountNumber: Maybe<Scalars["String"]["output"]>;
  payerID: Maybe<Scalars["String"]["output"]>;
  practiceLocation: Maybe<PracticeLocationType>;
  processorIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  socialSecurity: Maybe<Scalars["String"]["output"]>;
  specialty: Maybe<Scalars["String"]["output"]>;
  stateLicenseNumber: Maybe<Scalars["String"]["output"]>;
  upin: Maybe<Scalars["String"]["output"]>;
};

export type Supply = {
  __typename?: "Supply";
  colors: Scalars["String"]["output"];
  levels: Maybe<Scalars["Int"]["output"]>;
};

export type Tp_Prescription = {
  __typename?: "TP_Prescription";
  autofilledId: Maybe<Scalars["String"]["output"]>;
  autofilledStatus: Maybe<Scalars["String"]["output"]>;
  date_filled_utc: Maybe<Scalars["String"]["output"]>;
  date_written: Maybe<Scalars["String"]["output"]>;
  days_supply: Maybe<Scalars["String"]["output"]>;
  is_refill: Maybe<Scalars["Int"]["output"]>;
  last_filled_date: Maybe<Scalars["String"]["output"]>;
  medication_name: Maybe<Scalars["String"]["output"]>;
  medication_sig: Maybe<Scalars["String"]["output"]>;
  number_of_refills_allowed: Maybe<Scalars["Int"]["output"]>;
  prescribed_quantity: Maybe<Scalars["String"]["output"]>;
  prescriber: Maybe<Scalars["String"]["output"]>;
  prescription_token: Maybe<Scalars["String"]["output"]>;
  quantity_remaining: Maybe<Scalars["String"]["output"]>;
  refills_remaining: Maybe<Scalars["String"]["output"]>;
  rx_number: Maybe<Scalars["String"]["output"]>;
};

export type Taxonomy = {
  __typename?: "Taxonomy";
  code: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  license: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
};

export type TimeZone = {
  __typename?: "TimeZone";
  timeZoneDifferenceQuantity: Maybe<Scalars["String"]["output"]>;
  timeZoneIdentifier: Maybe<Scalars["String"]["output"]>;
};

export type Titration = {
  __typename?: "Titration";
  pharmacyToTitrateDose: Maybe<Scalars["Boolean"]["output"]>;
  titrationDose: Maybe<TitrationDose>;
};

export type TitrationDose = {
  __typename?: "TitrationDose";
  measurement: Maybe<TitrationDoseMeasurementType>;
  measurementTimingAndDuration: Maybe<TitrationTimingAndDuration>;
};

export type TitrationDoseMeasurementType = {
  __typename?: "TitrationDoseMeasurementType";
  loincVersion: Maybe<Scalars["String"]["output"]>;
  maximumMeasurementValue: Maybe<Scalars["Int"]["output"]>;
  measurementNotes: Maybe<Scalars["String"]["output"]>;
  minimumMeasurementValue: Maybe<Scalars["Int"]["output"]>;
  ucumVersion: Maybe<Scalars["String"]["output"]>;
  unitOfMeasure: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Int"]["output"]>;
  vitalSign: Maybe<Scalars["String"]["output"]>;
};

export type TitrationSigCode = {
  __typename?: "TitrationSigCode";
  code: Maybe<Scalars["String"]["output"]>;
  qualifier: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
};

export type TitrationTimingAndDuration = {
  __typename?: "TitrationTimingAndDuration";
  measurementAdministrationTiming: Maybe<MeasurementTiming>;
  measurementDuration: Maybe<MeasurementDuration>;
  measurementDurationTrigger: Maybe<MeasurementDurationTriggerTiming>;
  measurementFrequency: Maybe<MeasurementFrequencyTiming>;
  measurementInterval: Maybe<MeasurementIntervalTiming>;
};

export type Transfer = {
  __typename?: "Transfer";
  address: Address;
  date: Scalars["String"]["output"];
  dea: Maybe<Scalars["String"]["output"]>;
  fax: Maybe<Scalars["String"]["output"]>;
  faxInfo: Maybe<TransferFaxInfo>;
  fillId: Maybe<Scalars["ID"]["output"]>;
  firstFillDate: Maybe<Scalars["String"]["output"]>;
  lastFillDate: Maybe<Scalars["String"]["output"]>;
  originalRefills: Maybe<Scalars["Int"]["output"]>;
  originalRxNumber: Maybe<Scalars["Int"]["output"]>;
  pharmacistName: Scalars["String"]["output"];
  pharmacy: Scalars["String"]["output"];
  pharmacyNpi: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
  priorAuthorization: Maybe<PriorAuthorization>;
  refillsRemaining: Maybe<Scalars["Int"]["output"]>;
  transferImage: Maybe<Document>;
  transferImageId: Maybe<Scalars["ID"]["output"]>;
  transferType: Maybe<Scalars["String"]["output"]>;
  transferredByUser: User;
  transferredByUserId: Scalars["ID"]["output"];
};

export type TransferEngineInput = {
  comments?: InputMaybe<Scalars["String"]["input"]>;
  fax?: InputMaybe<Scalars["String"]["input"]>;
  pharmacistName?: InputMaybe<Scalars["String"]["input"]>;
  pharmacyNpi: Scalars["String"]["input"];
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  receivingPharmacy?: InputMaybe<ReceivingPharmacy>;
  transferringPharmacistName?: InputMaybe<Scalars["String"]["input"]>;
};

export type TransferFaxInfo = {
  __typename?: "TransferFaxInfo";
  error: Maybe<Scalars["String"]["output"]>;
  errorCode: Maybe<Scalars["String"]["output"]>;
  sid: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export type TransferFillToPioneerInput = {
  comments?: InputMaybe<Scalars["String"]["input"]>;
  pharmacyNpi: Scalars["String"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type TransferInput = {
  address?: InputMaybe<AddressInput>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  dea?: InputMaybe<Scalars["String"]["input"]>;
  fax?: InputMaybe<Scalars["String"]["input"]>;
  firstFillDate?: InputMaybe<Scalars["String"]["input"]>;
  lastFillDate?: InputMaybe<Scalars["String"]["input"]>;
  originalRefills?: InputMaybe<Scalars["Int"]["input"]>;
  originalRxNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pharmacistLastName?: InputMaybe<Scalars["String"]["input"]>;
  pharmacistName: Scalars["String"]["input"];
  pharmacy?: InputMaybe<Scalars["String"]["input"]>;
  pharmacyNpi: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  quantityRemaining?: InputMaybe<Scalars["Float"]["input"]>;
  refillsRemaining?: InputMaybe<Scalars["Int"]["input"]>;
  transferType?: InputMaybe<Scalars["String"]["input"]>;
  transferredByUserId?: InputMaybe<Scalars["ID"]["input"]>;
  transferringPharmacistName?: InputMaybe<Scalars["String"]["input"]>;
};

export type TransferOutVerification = {
  __typename?: "TransferOutVerification";
  pharmacistId: Maybe<Scalars["String"]["output"]>;
  verifiedAt: Maybe<Scalars["Date"]["output"]>;
};

export type Triage = {
  __typename?: "Triage";
  details: Maybe<TriageDetails>;
  endDate: Maybe<Scalars["String"]["output"]>;
  inTriageUserMessage: Maybe<Scalars["String"]["output"]>;
  reason: Scalars["String"]["output"];
  removeFromTriageUserMessage: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["String"]["output"];
  userPlacedInTriage: User;
  userRemovedFromTriage: Maybe<User>;
};

export type TriageDetails = {
  __typename?: "TriageDetails";
  dateOkToFill: Maybe<Scalars["Date"]["output"]>;
};

export type TriageReasonSearchResult = {
  __typename?: "TriageReasonSearchResult";
  _id: Scalars["String"]["output"];
};

export type UpdateInventoryGroupInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  pioneerAlias?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePrinterInput = {
  GCPAddress: Scalars["String"]["input"];
  locationId: Scalars["String"]["input"];
  make: Scalars["String"]["input"];
  printerIP: Scalars["String"]["input"];
  printerName: Scalars["String"]["input"];
  printerPurpose: Scalars["String"]["input"];
  printerType: PrinterType;
};

export type UpdateSigCodeInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  enTranslation?: InputMaybe<Scalars["String"]["input"]>;
  esTranslation?: InputMaybe<Scalars["String"]["input"]>;
  exceptionNDC?: InputMaybe<Array<InputMaybe<ExceptionNdcInput>>>;
  multiplier?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UpdateUserInput = {
  disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  licenses?: InputMaybe<Array<InputMaybe<LicenseInput>>>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  preferredName?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type UploadedBy = {
  __typename?: "UploadedBy";
  service: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User>;
};

export type User = {
  __typename?: "User";
  _id: Maybe<Scalars["ID"]["output"]>;
  addresses: Maybe<Array<Maybe<Address>>>;
  countryCode: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  disabled: Maybe<Scalars["Boolean"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  forcePasswordReset: Maybe<Scalars["Boolean"]["output"]>;
  healthProfile: Maybe<HealthProfile>;
  lastCheckedReleaseNotes: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  licenses: Maybe<Array<Maybe<License>>>;
  location: Maybe<Location>;
  locationId: Maybe<Scalars["ID"]["output"]>;
  mobileNumber: Maybe<Scalars["String"]["output"]>;
  oneTimePassword: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
  preferredName: Maybe<Scalars["String"]["output"]>;
  roles: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  updatedAt: Maybe<Scalars["Date"]["output"]>;
  username: Scalars["String"]["output"];
};

export type UserAnnotationInput = {
  _id?: InputMaybe<Scalars["ID"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserEvent = {
  __typename?: "UserEvent";
  _id: Scalars["ID"]["output"];
  elapsedInSeconds: Scalars["Int"]["output"];
  endAt: Scalars["Date"]["output"];
  event: Scalars["String"]["output"];
  startAt: Scalars["Date"]["output"];
  userId: Scalars["ID"]["output"];
};

export type UserInput = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  licenses?: InputMaybe<Array<InputMaybe<LicenseInput>>>;
  oneTimePassword?: InputMaybe<Scalars["String"]["input"]>;
  preferredName?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  username: Scalars["String"]["input"];
};

export type UserOrder = {
  __typename?: "UserOrder";
  address: Maybe<Address>;
  order: Maybe<UserOrderData>;
  user: Maybe<OrderUser>;
};

export type UserOrderData = {
  __typename?: "UserOrderData";
  cardNumber: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Date"]["output"]>;
  discount: Maybe<Scalars["String"]["output"]>;
  orderId: Maybe<Scalars["String"]["output"]>;
  products: Maybe<Array<Maybe<OtcProduct>>>;
  shippingMethod: Maybe<Scalars["String"]["output"]>;
  shippingMethodName: Maybe<Scalars["String"]["output"]>;
  shippingPrice: Maybe<Scalars["Float"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  taxesPrice: Maybe<Scalars["Float"]["output"]>;
  totalPrice: Maybe<Scalars["Float"]["output"]>;
  trackingUrl: Maybe<Scalars["String"]["output"]>;
};

export type UserUpdate = {
  __typename?: "UserUpdate";
  operation: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User>;
};

export type ValidationIssue = {
  __typename?: "ValidationIssue";
  identifier: Maybe<Scalars["String"]["output"]>;
  resolutionLogId: Maybe<Scalars["String"]["output"]>;
};

export type ValidationIssueType = {
  __typename?: "ValidationIssueType";
  description: Maybe<Scalars["String"]["output"]>;
  identifier: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  priority: Maybe<Scalars["Int"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type VerificationPatient = {
  __typename?: "VerificationPatient";
  address: Maybe<Scalars["String"]["output"]>;
  dob: Scalars["String"]["output"];
  gender: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  phone: Maybe<Scalars["String"]["output"]>;
};

export type VerificationPrescriber = {
  __typename?: "VerificationPrescriber";
  address: Scalars["String"]["output"];
  customer: Scalars["String"]["output"];
  dea: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  npi: Scalars["String"]["output"];
  npiDoc: Maybe<NpiDoc>;
  phone: Scalars["String"]["output"];
  supervisingPrescriber: Scalars["String"]["output"];
};

export type Verifications = {
  __typename?: "Verifications";
  pv1: Maybe<Pv1Verification>;
  pv2: Maybe<Pv2Verification>;
  transferOut: Maybe<TransferOutVerification>;
};

export type Vials = {
  __typename?: "Vials";
  image: Maybe<Scalars["String"]["output"]>;
  number: Maybe<Scalars["Int"]["output"]>;
};

export type Weight = {
  __typename?: "Weight";
  unit: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type WoundStructure = {
  __typename?: "WoundStructure";
  depth: Maybe<Scalars["String"]["output"]>;
  laterality: Maybe<SnomedLateralityCode>;
  length: Maybe<Scalars["String"]["output"]>;
  location: Maybe<SnomedCode>;
  width: Maybe<Scalars["String"]["output"]>;
};

export type AddPracticeResponse = {
  __typename?: "addPracticeResponse";
  prescriberAddressId: Maybe<Scalars["String"]["output"]>;
};

export type ConfirmCopayRequestFill = {
  __typename?: "confirmCopayRequestFill";
  claims: Maybe<Array<Maybe<Claims>>>;
  copayRequestFill: Maybe<CopayRequestFill>;
};

export type ConfirmFillAdjudication = {
  __typename?: "confirmFillAdjudication";
  claims: Maybe<Array<Maybe<Claims>>>;
  rxFillRequest: Maybe<RxFillRequest>;
};

export type ConvertSecretResponse = {
  __typename?: "convertSecretResponse";
  created: Maybe<Scalars["Boolean"]["output"]>;
};

export type GenerateOrderOptionsInput = {
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  hasOtcs?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasRxs?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBatchOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReplacementOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  newPatient?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderType?: InputMaybe<Scalars["String"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
  prescriptionNdc?: InputMaybe<Scalars["String"]["input"]>;
  rxNumbers?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  shipToState?: InputMaybe<Scalars["String"]["input"]>;
  totalFills?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GetDocumentUrlResponse = {
  __typename?: "getDocumentUrlResponse";
  s3SignedUrl: Maybe<Scalars["String"]["output"]>;
};

export type RejectOutboundTransferResponse = {
  __typename?: "rejectOutboundTransferResponse";
  status: Maybe<Scalars["String"]["output"]>;
};

export type AddressEntry_AddressFragmentFragment = {
  __typename?: "Address";
  street1: string | null;
  street2: string | null;
  state: string | null;
  city: string | null;
  zip: string | null;
} & { " $fragmentName"?: "AddressEntry_AddressFragmentFragment" };

export type PatientCoverageInfo_InsuranceFragmentFragment = {
  __typename?: "Insurance";
  _id: string | null;
  group: string | null;
  bin: string | null;
  cardholderId: string | null;
} & { " $fragmentName"?: "PatientCoverageInfo_InsuranceFragmentFragment" };

export type PatientCoverageInfo_PatientFragmentFragment = {
  __typename?: "Patient";
  _id: string | null;
  insurances: Array<
    | ({ __typename?: "Insurance" } & {
        " $fragmentRefs"?: {
          PatientCoverageInfo_InsuranceFragmentFragment: PatientCoverageInfo_InsuranceFragmentFragment;
        };
      })
    | null
  > | null;
} & { " $fragmentName"?: "PatientCoverageInfo_PatientFragmentFragment" };

export type GetPatientCoverageInfoQueryVariables = Exact<{
  patientId: Scalars["ID"]["input"];
}>;

export type GetPatientCoverageInfoQuery = {
  __typename?: "Query";
  getPatient:
    | ({ __typename?: "Patient" } & {
        " $fragmentRefs"?: {
          PatientCoverageInfo_PatientFragmentFragment: PatientCoverageInfo_PatientFragmentFragment;
        };
      })
    | null;
};

export type DemographicInfo_PatientFragmentFragment = {
  __typename?: "Patient";
  _id: string | null;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  contacts: {
    __typename?: "Contact";
    email: string | null;
    phone: string | null;
  } | null;
  address: {
    __typename?: "Addresses";
    home:
      | ({ __typename?: "Address" } & {
          " $fragmentRefs"?: {
            AddressEntry_AddressFragmentFragment: AddressEntry_AddressFragmentFragment;
          };
        })
      | null;
  } | null;
  medicalHistory: {
    __typename?: "DURSubjectInputs";
    allergies: Array<{
      __typename?: "Allergy";
      idField: string;
      value: string;
      type: string;
      name: string;
    }> | null;
    medications: Array<{
      __typename?: "Medication";
      idField: string;
      value: string;
      type: string;
      name: string;
    }> | null;
    conditions: Array<{
      __typename?: "Condition";
      idField: string;
      value: string;
      type: string;
      name: string;
    }> | null;
  } | null;
} & { " $fragmentName"?: "DemographicInfo_PatientFragmentFragment" };

export type GetPatientDemographicInfoQueryVariables = Exact<{
  patientId: Scalars["ID"]["input"];
}>;

export type GetPatientDemographicInfoQuery = {
  __typename?: "Query";
  getPatient:
    | ({ __typename?: "Patient" } & {
        " $fragmentRefs"?: {
          DemographicInfo_PatientFragmentFragment: DemographicInfo_PatientFragmentFragment;
        };
      })
    | null;
};

export type DuplicatePrescription_PrescriptionFragmentFragment = {
  __typename?: "Prescription";
  _id: string;
  name: string | null;
  dosageForm: string;
  ndc: string;
  quantity: number | null;
  daysSupply: number | null;
  directions: string;
  rxNumber: number;
  daw: number;
  refillsRemaining: number | null;
  quantityRemaining: number;
  writtenDate: any;
  expirationDate: string;
  status: string;
  strength: {
    __typename?: "Strength";
    value: string | null;
    form: string | null;
    unit: string | null;
  } | null;
  fills: Array<{
    __typename?: "Fill";
    status: string | null;
    dispensed: {
      __typename?: "DispensedMedication";
      dispensedAt: string | null;
    } | null;
  } | null> | null;
  customer: { __typename?: "Customer"; name: string } | null;
} & { " $fragmentName"?: "DuplicatePrescription_PrescriptionFragmentFragment" };

export type GetDuplicatePrescriptionsQueryVariables = Exact<{
  ids:
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
  filterStatus?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetDuplicatePrescriptionsQuery = {
  __typename?: "Query";
  getPrescriptionsByIds: Array<
    { __typename?: "Prescription" } & {
      " $fragmentRefs"?: {
        DuplicatePrescription_PrescriptionFragmentFragment: DuplicatePrescription_PrescriptionFragmentFragment;
      };
    }
  > | null;
};

export type DiscontinuePrescriptionsMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  prescriptions:
    | Array<InputMaybe<DiscontinuePrescription>>
    | InputMaybe<DiscontinuePrescription>;
}>;

export type DiscontinuePrescriptionsMutation = {
  __typename?: "Mutation";
  discontinuePrescriptions: {
    __typename?: "DiscontinuePrescriptionsResponse";
    successful: Array<{
      __typename?: "DiscontinuePrescriptionsSuccessful";
      rxNumber: number;
    } | null>;
    unsuccessful: Array<{
      __typename?: "DiscontinuePrescriptionsUnsuccessful";
      rxNumber: number | null;
      error: string;
    } | null>;
  } | null;
};

export type ResolveDuplicatePrescriptionTriagesMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  prescriptionIds:
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ResolveDuplicatePrescriptionTriagesMutation = {
  __typename?: "Mutation";
  resolveDuplicatePrescriptionTriages: {
    __typename?: "ResolveDuplicatePrescriptionTriagesResponse";
    successful: Array<string | null>;
    unsuccessful: Array<string | null>;
  } | null;
};

export type NdcPackage_NdcPackageFragmentFragment = {
  __typename?: "NdcFullInfoPackage";
  ndc: string;
  packageSize: number;
  packageSizeUnitOfMeasure: string;
  packageQuantity: number;
  isUnbreakable: boolean | null;
  hasInnerPackSibling: boolean | null;
} & { " $fragmentName"?: "NdcPackage_NdcPackageFragmentFragment" };

export type GetNdcPackageQueryVariables = Exact<{
  ndcs:
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetNdcPackageQuery = {
  __typename?: "Query";
  getNdcPackage: Array<
    | ({ __typename?: "NdcFullInfoPackage" } & {
        " $fragmentRefs"?: {
          NdcPackage_NdcPackageFragmentFragment: NdcPackage_NdcPackageFragmentFragment;
        };
      })
    | null
  > | null;
};

export type GetPatientPrescriptionsQueryVariables = Exact<{
  patientIds?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  statuses?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  customerIds?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetPatientPrescriptionsQuery = {
  __typename?: "Query";
  getPrescriptions: {
    __typename?: "PrescriptionsWithPaging";
    currentPage: number | null;
    totalRecords: number | null;
    prescriptions: Array<{
      __typename?: "PrescriptionPartial";
      _id: string;
      rxNumber: number;
      name: string | null;
      quantity: number | null;
      daysSupply: number | null;
      directions: string;
      quantityRemaining: number;
      refillsRemaining: number | null;
      writtenDate: any;
      status: string;
      customer: {
        __typename?: "Customer";
        _id: string | null;
        name: string;
        legacyId: number;
      } | null;
      fills: Array<{
        __typename?: "Fill";
        _id: string | null;
        fillNumber: number | null;
        dispensed: {
          __typename?: "DispensedMedication";
          dispensedAt: string | null;
        } | null;
      } | null> | null;
      patient: {
        __typename?: "Patient";
        firstName: string;
        lastName: string;
      } | null;
      prescriber: {
        __typename?: "Prescriber";
        firstName: string | null;
        lastName: string | null;
        suffix: string | null;
      } | null;
      strength: {
        __typename?: "Strength";
        value: string | null;
        form: string | null;
        unit: string | null;
      } | null;
    } | null> | null;
  };
};

export type PatientPrescriptionsQueryVariables = Exact<{
  patientIds?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  statuses?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  customerIds?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PatientPrescriptionsQuery = {
  __typename?: "Query";
  getPrescriptions: {
    __typename?: "PrescriptionsWithPaging";
    currentPage: number | null;
    totalRecords: number | null;
    prescriptions: Array<{
      __typename?: "PrescriptionPartial";
      _id: string;
      rxNumber: number;
      name: string | null;
      quantity: number | null;
      daysSupply: number | null;
      directions: string;
      quantityRemaining: number;
      refillsRemaining: number | null;
      writtenDate: any;
      status: string;
      customer: {
        __typename?: "Customer";
        _id: string | null;
        name: string;
        legacyId: number;
      } | null;
      fills: Array<{
        __typename?: "Fill";
        _id: string | null;
        fillNumber: number | null;
        dispensed: {
          __typename?: "DispensedMedication";
          dispensedAt: string | null;
        } | null;
      } | null> | null;
      patient: {
        __typename?: "Patient";
        firstName: string;
        lastName: string;
      } | null;
      prescriber: {
        __typename?: "Prescriber";
        firstName: string | null;
        lastName: string | null;
        suffix: string | null;
      } | null;
      strength: {
        __typename?: "Strength";
        value: string | null;
        form: string | null;
        unit: string | null;
      } | null;
    } | null> | null;
  };
};

export type SendOutboundScanMutationVariables = Exact<{
  trackingNumber: Scalars["String"]["input"];
  outboundScanType: OutboundScanType;
}>;

export type SendOutboundScanMutation = {
  __typename?: "Mutation";
  sendOutboundScan: {
    __typename?: "OutboundScan";
    trackingNumber: string;
    scanAt: string;
    order: {
      __typename?: "Order";
      _id: string;
      patient: {
        __typename?: "Patient";
        _id: string | null;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
  } | null;
};

export type ConfirmFillScanWithStockInfoMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  fillId: Scalars["ID"]["input"];
  ndc: Scalars["String"]["input"];
  stockInfo: Array<StockInfoInput> | StockInfoInput;
  manualEntryFilled: Scalars["Boolean"]["input"];
}>;

export type ConfirmFillScanWithStockInfoMutation = {
  __typename?: "Mutation";
  confirmFillScanWithStockInfo: {
    __typename?: "RxFillRequest";
    fill: {
      __typename?: "Fill";
      _id: string | null;
      shortFillCode: string | null;
      dispensed: {
        __typename?: "DispensedMedication";
        ndc: string | null;
        packagesRequired: number | null;
        manualEntryFilled: boolean | null;
        stockInfo: {
          __typename?: "StockInfo";
          lot: string | null;
          expirationDate: string | null;
          serialNumber: string | null;
          quantity: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetOtcOnlyOrdersQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetOtcOnlyOrdersQuery = {
  __typename?: "Query";
  getOtcOnlyOrders: {
    __typename?: "StatBankOtcOrders";
    Packing: number | null;
  } | null;
};

export type GetFillsByStatusQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetFillsByStatusQuery = {
  __typename?: "Query";
  getFillsByStatus: {
    __typename?: "StatBankFillStatuses";
    PV1: {
      __typename?: "StatWithAging";
      total: number | null;
      between24And48Hours: number | null;
      greaterThan48Hours: number | null;
    } | null;
    Fill: {
      __typename?: "StatWithAging";
      total: number | null;
      between24And48Hours: number | null;
      greaterThan48Hours: number | null;
    } | null;
    Automation: {
      __typename?: "StatWithAging";
      total: number | null;
      between24And48Hours: number | null;
      greaterThan48Hours: number | null;
    } | null;
    PV2: {
      __typename?: "StatWithAging";
      total: number | null;
      between24And48Hours: number | null;
      greaterThan48Hours: number | null;
    } | null;
    Adjudication: {
      __typename?: "StatWithAging";
      total: number | null;
      between24And48Hours: number | null;
      greaterThan48Hours: number | null;
    } | null;
    PriorAuthorization: {
      __typename?: "StatWithAging";
      total: number | null;
      between24And48Hours: number | null;
      greaterThan48Hours: number | null;
    } | null;
  } | null;
};

export type GetOrdersByStatusQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetOrdersByStatusQuery = {
  __typename?: "Query";
  getOrdersByStatus: {
    __typename?: "StatBankOrders";
    packing: number | null;
  } | null;
};

export type GetTriagesQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  states?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetTriagesQuery = {
  __typename?: "Query";
  getTriages: {
    __typename?: "StatBankInTriage";
    inTriage: number | null;
  } | null;
};

export const PatientCoverageInfo_InsuranceFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PatientCoverageInfo_InsuranceFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Insurance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "group" } },
          { kind: "Field", name: { kind: "Name", value: "bin" } },
          { kind: "Field", name: { kind: "Name", value: "cardholderId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PatientCoverageInfo_InsuranceFragmentFragment,
  unknown
>;
export const PatientCoverageInfo_PatientFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PatientCoverageInfo_PatientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Patient" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "insurances" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "PatientCoverageInfo_InsuranceFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PatientCoverageInfo_InsuranceFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Insurance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "group" } },
          { kind: "Field", name: { kind: "Name", value: "bin" } },
          { kind: "Field", name: { kind: "Name", value: "cardholderId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PatientCoverageInfo_PatientFragmentFragment,
  unknown
>;
export const AddressEntry_AddressFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressEntry_AddressFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "street1" } },
          { kind: "Field", name: { kind: "Name", value: "street2" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "zip" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressEntry_AddressFragmentFragment, unknown>;
export const DemographicInfo_PatientFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DemographicInfo_PatientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Patient" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "dob" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contacts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "home" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "AddressEntry_AddressFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "medicalHistory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allergies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "idField" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "medications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "idField" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "idField" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressEntry_AddressFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "street1" } },
          { kind: "Field", name: { kind: "Name", value: "street2" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "zip" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DemographicInfo_PatientFragmentFragment, unknown>;
export const DuplicatePrescription_PrescriptionFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "DuplicatePrescription_PrescriptionFragment",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Prescription" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "strength" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "value" } },
                { kind: "Field", name: { kind: "Name", value: "form" } },
                { kind: "Field", name: { kind: "Name", value: "unit" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "fills" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dispensed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dispensedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "dosageForm" } },
          { kind: "Field", name: { kind: "Name", value: "ndc" } },
          { kind: "Field", name: { kind: "Name", value: "quantity" } },
          { kind: "Field", name: { kind: "Name", value: "daysSupply" } },
          { kind: "Field", name: { kind: "Name", value: "directions" } },
          { kind: "Field", name: { kind: "Name", value: "rxNumber" } },
          { kind: "Field", name: { kind: "Name", value: "daw" } },
          { kind: "Field", name: { kind: "Name", value: "refillsRemaining" } },
          { kind: "Field", name: { kind: "Name", value: "quantityRemaining" } },
          { kind: "Field", name: { kind: "Name", value: "writtenDate" } },
          { kind: "Field", name: { kind: "Name", value: "expirationDate" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicatePrescription_PrescriptionFragmentFragment,
  unknown
>;
export const NdcPackage_NdcPackageFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NdcPackage_NdcPackageFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NdcFullInfoPackage" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ndc" } },
          { kind: "Field", name: { kind: "Name", value: "packageSize" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "packageSizeUnitOfMeasure" },
          },
          { kind: "Field", name: { kind: "Name", value: "packageQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "isUnbreakable" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "hasInnerPackSibling" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NdcPackage_NdcPackageFragmentFragment, unknown>;
export const GetPatientCoverageInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPatientCoverageInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "patientId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPatient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "patientId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "PatientCoverageInfo_PatientFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PatientCoverageInfo_InsuranceFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Insurance" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "group" } },
          { kind: "Field", name: { kind: "Name", value: "bin" } },
          { kind: "Field", name: { kind: "Name", value: "cardholderId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PatientCoverageInfo_PatientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Patient" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "insurances" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "PatientCoverageInfo_InsuranceFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPatientCoverageInfoQuery,
  GetPatientCoverageInfoQueryVariables
>;
export const GetPatientDemographicInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPatientDemographicInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "patientId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPatient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "patientId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "DemographicInfo_PatientFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressEntry_AddressFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "street1" } },
          { kind: "Field", name: { kind: "Name", value: "street2" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "zip" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DemographicInfo_PatientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Patient" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "dob" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contacts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "home" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "AddressEntry_AddressFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "medicalHistory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allergies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "idField" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "medications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "idField" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "idField" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPatientDemographicInfoQuery,
  GetPatientDemographicInfoQueryVariables
>;
export const GetDuplicatePrescriptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDuplicatePrescriptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filterStatus" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPrescriptionsByIds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ids" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sort" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterStatus" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filterStatus" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sortBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sortBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "DuplicatePrescription_PrescriptionFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "DuplicatePrescription_PrescriptionFragment",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Prescription" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "strength" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "value" } },
                { kind: "Field", name: { kind: "Name", value: "form" } },
                { kind: "Field", name: { kind: "Name", value: "unit" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "fills" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dispensed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dispensedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "dosageForm" } },
          { kind: "Field", name: { kind: "Name", value: "ndc" } },
          { kind: "Field", name: { kind: "Name", value: "quantity" } },
          { kind: "Field", name: { kind: "Name", value: "daysSupply" } },
          { kind: "Field", name: { kind: "Name", value: "directions" } },
          { kind: "Field", name: { kind: "Name", value: "rxNumber" } },
          { kind: "Field", name: { kind: "Name", value: "daw" } },
          { kind: "Field", name: { kind: "Name", value: "refillsRemaining" } },
          { kind: "Field", name: { kind: "Name", value: "quantityRemaining" } },
          { kind: "Field", name: { kind: "Name", value: "writtenDate" } },
          { kind: "Field", name: { kind: "Name", value: "expirationDate" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDuplicatePrescriptionsQuery,
  GetDuplicatePrescriptionsQueryVariables
>;
export const DiscontinuePrescriptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DiscontinuePrescriptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "prescriptions" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DiscontinuePrescription" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "discontinuePrescriptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "prescriptions" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "prescriptions" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "successful" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rxNumber" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unsuccessful" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rxNumber" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscontinuePrescriptionsMutation,
  DiscontinuePrescriptionsMutationVariables
>;
export const ResolveDuplicatePrescriptionTriagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResolveDuplicatePrescriptionTriages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "prescriptionIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "resolveDuplicatePrescriptionTriages",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "prescriptionIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "prescriptionIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "successful" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unsuccessful" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResolveDuplicatePrescriptionTriagesMutation,
  ResolveDuplicatePrescriptionTriagesMutationVariables
>;
export const GetNdcPackageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getNdcPackage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ndcs" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getNdcPackage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ndcs" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ndcs" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "NdcPackage_NdcPackageFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NdcPackage_NdcPackageFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NdcFullInfoPackage" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ndc" } },
          { kind: "Field", name: { kind: "Name", value: "packageSize" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "packageSizeUnitOfMeasure" },
          },
          { kind: "Field", name: { kind: "Name", value: "packageQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "isUnbreakable" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "hasInnerPackSibling" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNdcPackageQuery, GetNdcPackageQueryVariables>;
export const GetPatientPrescriptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPatientPrescriptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "patientIds" },
          },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "statuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerIds" },
          },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPrescriptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "patientIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "patientIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "statuses" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "statuses" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageSize" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "currentPage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRecords" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prescriptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rxNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legacyId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fills" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fillNumber" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dispensed" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "dispensedAt",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "daysSupply" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "directions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantityRemaining" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refillsRemaining" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "writtenDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "patient" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prescriber" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suffix" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "strength" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "form" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPatientPrescriptionsQuery,
  GetPatientPrescriptionsQueryVariables
>;
export const PatientPrescriptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "patientPrescriptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "patientIds" },
          },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "statuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerIds" },
          },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPrescriptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "patientIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "patientIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "statuses" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "statuses" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageSize" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "currentPage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRecords" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prescriptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rxNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legacyId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fills" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fillNumber" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dispensed" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "dispensedAt",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "daysSupply" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "directions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantityRemaining" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refillsRemaining" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "writtenDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "patient" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prescriber" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suffix" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "strength" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "form" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PatientPrescriptionsQuery,
  PatientPrescriptionsQueryVariables
>;
export const SendOutboundScanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendOutboundScan" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "trackingNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "outboundScanType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "OutboundScanType" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendOutboundScan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "trackingNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "trackingNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "outboundScanType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "outboundScanType" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingNumber" },
                },
                { kind: "Field", name: { kind: "Name", value: "scanAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "order" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "patient" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendOutboundScanMutation,
  SendOutboundScanMutationVariables
>;
export const ConfirmFillScanWithStockInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "confirmFillScanWithStockInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fillId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ndc" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stockInfo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "StockInfoInput" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "manualEntryFilled" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmFillScanWithStockInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "fillId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fillId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "ndcPackage" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ndc" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "stockInfo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "stockInfo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "manualEntryFilled" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "manualEntryFilled" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fill" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dispensed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ndc" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "packagesRequired" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "stockInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lot" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "expirationDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "serialNumber",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "manualEntryFilled",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortFillCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmFillScanWithStockInfoMutation,
  ConfirmFillScanWithStockInfoMutationVariables
>;
export const GetOtcOnlyOrdersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getOtcOnlyOrders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "locationId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "states" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOtcOnlyOrders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "states" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "states" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "Packing" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOtcOnlyOrdersQuery,
  GetOtcOnlyOrdersQueryVariables
>;
export const GetFillsByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getFillsByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "locationId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "states" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFillsByStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "states" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "states" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PV1" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "between24And48Hours" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "greaterThan48Hours" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Fill" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "between24And48Hours" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "greaterThan48Hours" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Automation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "between24And48Hours" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "greaterThan48Hours" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PV2" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "between24And48Hours" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "greaterThan48Hours" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Adjudication" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "between24And48Hours" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "greaterThan48Hours" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PriorAuthorization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "between24And48Hours" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "greaterThan48Hours" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFillsByStatusQuery,
  GetFillsByStatusQueryVariables
>;
export const GetOrdersByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getOrdersByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "locationId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "states" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOrdersByStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "states" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "states" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "packing" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrdersByStatusQuery,
  GetOrdersByStatusQueryVariables
>;
export const GetTriagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTriages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "locationId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "states" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTriages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "states" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "states" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "inTriage" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTriagesQuery, GetTriagesQueryVariables>;
