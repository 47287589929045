import { Fragment } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Header, Modal, Spacer } from '@truepill/react-capsule'
import { floatRound } from '@truepill/tpos-data-util'
import type { StockInfo } from '@truepill/tpos-types'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { object, array, number } from 'yup'
import CustomizedTextField from '../components/CustomizedTextField'

type LotQuantityFill = {
  lot: string
  quantity: string
}

type Quantities = {
  quantities: LotQuantityFill[]
}

type LotQuantityFillModalProps = {
  confirmationCallback: (stockInfo: StockInfo[]) => void
  cancelCallback: () => void
  stockInfo: StockInfo[]
  quantity: number
  packageSize: number
}

const LotQuantityFillModal = ({
  confirmationCallback,
  cancelCallback,
  stockInfo,
  quantity,
  packageSize,
}: LotQuantityFillModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()

  const lots = Array.from(new Set(stockInfo.map(({ lot }) => lot)))

  const defaultValues = {
    quantities: lots.map(lot => ({ lot, quantity: '' })),
  }

  const resetScanTasks = () => {
    dismissModal()
    cancelCallback()
  }

  const LotQuantityFillFormSchema = object()
    .shape({
      quantities: array().of(
        object().shape({
          quantity: number()
            .label('Quantity')
            .required()
            .positive()
            .test('is-multiple-of-package-size', `Quantity must be a multiple of ${packageSize}`, value => {
              return value % packageSize === 0
            }),
        }),
      ),
    })
    .test('sum-of-quantity', `Total quantity should be ${quantity}`, value => {
      const totalQuantity = value.quantities?.reduce((sum, field) => floatRound(sum + (field.quantity || 0)), 0)
      if (totalQuantity !== quantity) {
        setError('quantities', {
          type: 'manual',
          message: `Total quantity should be ${quantity}`,
        })
      } else {
        clearErrors('quantities')
      }
      return totalQuantity === quantity
    })

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(LotQuantityFillFormSchema),
    mode: 'onChange',
  })

  const onSubmit = (newLotQuantities: Quantities) => {
    // replace quantities from stockInfo with new manual entries
    const updatedLots = new Set()
    const resetStockInfoQuantity = stockInfo.map(product => ({ ...product, quantity: 0 }))
    const stockInfoWithManualQuantities = resetStockInfoQuantity.map(product => {
      if (updatedLots.has(product.lot)) {
        return product
      }
      const lotFound = newLotQuantities.quantities.find(({ lot }) => lot === product.lot)
      if (lotFound) {
        updatedLots.add(product.lot)
        return { ...product, quantity: floatRound(parseFloat(lotFound.quantity)) }
      }
      return product
    })
    confirmationCallback(stockInfoWithManualQuantities)
    dismissModal()
  }

  return (
    <Modal
      aria-label="LotQuantityFillModal"
      overlayCss={{ zIndex: 999, background: 'rgba(24, 39, 75, 0.08)' }}
      css={{ width: '27rem', padding: '1.5rem' }}
      isOpen
      onDismiss={resetScanTasks}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header variant="2xl" as="h2" bold>
          Quantity entry
        </Header>
        <Spacer size="xl" />
        <StyledBoxInputs>
          {lots.map((lot, index) => (
            <Fragment key={lot}>
              <CustomizedTextField
                type="number"
                step="0.01"
                required
                label={`Lot# - ${lot}`}
                placeholder="Enter Qty"
                {...register(`quantities.${index}.quantity`)}
                state={errors.quantities?.[index]?.quantity ? 'error' : 'default'}
                helperText={errors.quantities?.[index]?.quantity?.message}
              />
              <CustomizedTextField required hidden {...register(`quantities.${index}.lot`)} />
            </Fragment>
          ))}
          <CustomizedTextField
            hidden
            state={errors.quantities ? 'error' : 'default'}
            helperText={errors.quantities?.message}
          />
        </StyledBoxInputs>
        <StyledBoxButtons>
          <Button size="sm" type="button" variant="primary-text" onClick={resetScanTasks}>
            Cancel
          </Button>
          <Button size="sm" type="submit" disabled={!isValid}>
            Submit
          </Button>
        </StyledBoxButtons>
      </form>
    </Modal>
  )
}

const StyledBoxButtons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`

const StyledBoxInputs = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export default LotQuantityFillModal
