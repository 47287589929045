import { memo } from 'react'
import { Box, Button, Text, TextField } from '@truepill/react-capsule'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { capsuleDarkBlue } from 'styles/styleVariables'
import type { DURSubject } from 'types'
import type { FormValues } from '../types'
import FuzzySearchAutoComplete from './AutoComplete/FuzzySearch'

const DurFields = memo(({ type }: { type: 'allergies' | 'medications' | 'conditions' }) => {
  const {
    formState: { dirtyFields },
    control,
    register,
    watch,
    setValue,
  } = useFormContext<FormValues>()
  const { append: appendField, remove: removeField } = useFieldArray({ name: type, control })

  const watched = watch(type)

  return (
    <Container>
      <Header>
        <Title bold>{type}</Title>
        <Button
          type="button"
          size="sm"
          variant="primary-text"
          onClick={() => {
            appendField({ term: '' })
          }}
        >
          + Add {type} token
        </Button>
      </Header>

      {watched.map((field, index) => {
        return (
          <FieldContainer key={index}>
            <Box css={{ width: '290px' }}>
              <TextField {...register(`${type}.${index}.term`)} size="sm" />
            </Box>
            <Box css={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>
              <ArrowIcon />
            </Box>
            <Box css={{ width: '290px', position: 'relative' }}>
              <FuzzySearchAutoComplete
                onResultDismiss={() => {
                  // Clear all when chip is cleared
                  setValue(`${type}.${index}.medispanData`, undefined)
                  setValue(`${type}.${index}.term`, '')
                }}
                type={type}
                // if the term is not dirty, it could be a default value, and we don't want to search in those cases
                searchTerm={dirtyFields?.[type]?.[index]?.term ? watched[index].term : ''}
                value={watched[index].medispanData}
                onError={() => {
                  setValue(`${type}.${index}.medispanData`, undefined)
                }}
                onChange={(val: DURSubject | undefined) => {
                  setValue(`${type}.${index}.medispanData`, val)
                }}
              />
            </Box>
            <CircleButton
              onClick={() => {
                removeField(index)
              }}
            >
              <CloseIcon fill={capsuleDarkBlue} />
            </CircleButton>
          </FieldContainer>
        )
      })}
    </Container>
  )
})

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FieldContainer = styled(Box)`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Container = styled(Box)`
  margin-bottom: 1rem;
`

const CircleButton = styled(Box)`
  cursor: pointer;
  border: 2px solid ${capsuleDarkBlue};
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${capsuleDarkBlue};
    svg {
      fill: white;
    }
  }
`

const Title = styled(Text)`
  line-height: 1.5rem;
  text-transform: capitalize;
`

export default DurFields
