import BreadCrumb from 'components/BreadCrumb'
import { PageContent, StickyPageHeadingContainer, PageHeading } from 'components/PageStructure'
import RxHeading from 'components/RxHeading'
import { RXTable, RXSegment, RXRowTable } from 'components/RXTable'
import RXTopBanner from 'components/RXTopBanner'
import ToolBar, {
  OrderButton,
  LogButton,
  NotesButton,
  MedicalHistoryButton,
  DataButton,
  MonographButton,
  ClaimSummaryButton,
  AttachmentsButton,
} from 'components/ToolBar'
import { uniqueLogEntries } from 'hooks/navigation/useLogs'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import styled from 'styled-components'
import type { Fill, Order, Patient, Prescription, TPOSDocument, Log, RXFillRequest } from 'types'
import { getOrderAttachmentsCount, getParentAttachments } from 'utils'
import ActionButtons from './ActionButtons'
import PrimaryTableSection from './TableSections/Primary'

type PV2ViewProps = {
  patient: Patient
  prescription: Prescription
  order: Order
  fill: Fill
  rxFillRequest: RXFillRequest
  docs?: TPOSDocument[]
  logs: Log[]
  disableRphButtons: boolean
  notesBadgeCount: number
}

const PV2View = ({
  patient,
  prescription,
  order,
  fill,
  rxFillRequest,
  docs,
  logs,
  disableRphButtons,
  notesBadgeCount,
}: PV2ViewProps): JSX.Element => {
  useCriticalNotesModal({ order, prescription, logs, showPatientNotes: true })

  if (!order || !fill || !prescription || !patient) {
    return <></>
  }

  const allowClaimSummaryButton = (fill?.claims?.length ?? 0) > 0
  const orderAttachments = getOrderAttachmentsCount(docs)
  const parentAttachments = getParentAttachments(docs)

  return (
    <>
      <StickyPageHeadingContainer>
        <RXTopBanner order={order} rxFillRequest={rxFillRequest} />
        <StyledBreadCrumb />
        <PageHeading>
          <RxHeading item={order} prescription={prescription} fill={fill} patient={patient} />
          <ActionButtons fill={fill} order={order} disableRphButtons={disableRphButtons} />
        </PageHeading>
      </StickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <PrimaryTableSection
                  order={order}
                  fill={fill}
                  prescription={prescription}
                  rxFillRequest={rxFillRequest}
                />
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar>
          <LogButton logs={logs ? uniqueLogEntries(logs) : []} fillId={fill._id} />
          <NotesButton notesCount={notesBadgeCount} showBadge={notesBadgeCount > 0} />
          <AttachmentsButton
            attachmentsCount={orderAttachments}
            showBadge={parentAttachments || orderAttachments > 0}
          />
          <MedicalHistoryButton fillId={fill._id} patientId={patient._id} />
          <OrderButton order={order} />
          <DataButton prescription={prescription} />
          <MonographButton ndc={fill.dispensed.ndc} />
          {allowClaimSummaryButton && (
            <ClaimSummaryButton
              fill={fill}
              order={order}
              rxFillRequest={rxFillRequest}
              allowDisable={true}
              prescription={prescription}
            />
          )}
        </ToolBar>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`

const StyledBreadCrumb = styled(BreadCrumb)`
  margin-top: 1.25rem;
`

export default PV2View
